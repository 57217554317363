import {
  AdminBonus,
  AdminBooking,
  AdminClient,
  AdminClosure,
  AdminDb,
  AdminOrder,
  AdminPromotion,
  GoogleEvent,
  isAdminBonus,
  isAdminOrder,
  isBonusTreatmentTransaction,
  Promotion,
  PublicDb,
  UnusedBonus,
  User,
} from "@in-and-out-belleza/api/interfaces"
import { dateUtils } from "@in-and-out-belleza/utils"
import { isValidEmail } from "@in-and-out-belleza/validation"
import { getCartTotal, getStdCartTotal } from "./getCartTotal"
import { bonusCardToPromotion } from "./bonusCardToPromotion"
import { BUY_EXPIRATION_VALUE } from "@in-and-out-belleza/settings"

const ONE_DAY = 24 * 60 * 60 * 1000

export const prefixes: Record<string, string> = {
  treatments: "TRT-",
  products: "PRD-",
  bonusCards: "TAR-",
}

const HOUR_TIME_SLOTS = 4
const STARTING_HOUR = 9.5
const ENDING_HOUR = 20
const TIME_SLOTS = (ENDING_HOUR - STARTING_HOUR) * HOUR_TIME_SLOTS
const MINIMUM_TME = 60 / HOUR_TIME_SLOTS

const getSpainOffset = dateUtils.getSpainOffset
const getSpainDate = dateUtils.getSpainDate

type Options = {
  excludeExceptions?: boolean
}

const getCalendar = (db: PublicDb | AdminDb, date: number, options?: Options) => {
  const { excludeExceptions = false } = options ?? {}
  const cal = db.calendars.find(c => c.from < date && c.to > date)
  if (!cal) return [[], [], [], [], [], [], []]
  if (!cal.exceptions) return [[], [], [], [], [], [], []]
  const weeks = cal.weeks ?? [{ days: [[], [], [], [], [], [], []] }]
  const start = dateUtils.startOfTheWeek(cal.from)
  const diff = dateUtils.weekDifference(start, date)
  const week = weeks[diff % cal.weeks.length]
  if (!week) return [[], [], [], [], [], [], []]

  return week.days.map((day, index) => {
    const filtered = day
      .filter(item => {
        if (excludeExceptions) return true
        return !cal.exceptions.find(e => {
          const spainDate = getSpainDate(date)
          const spainExc = getSpainDate(e.timestamp)
          return (
            spainDate.date === spainExc.date &&
            spainDate.month === spainExc.month &&
            spainDate.year === spainExc.year &&
            item.workerIndex === e.workerIndex &&
            spainExc.day === index
          )
        })
      })
      .map(item => [item.centerIndex, item.workerIndex, item.from, item.to])
    const toAdd = cal.exceptions
      .filter(e => {
        const spainDate = getSpainDate(date)
        const spainExc = getSpainDate(e.timestamp)
        return (
          spainDate.date === spainExc.date &&
          spainDate.month === spainExc.month &&
          spainDate.year === spainExc.year &&
          spainExc.day === index
        )
      })
      .map(e => {
        return [e.centerIndex, e.workerIndex, e.from, e.to]
      })

    const results = excludeExceptions ? filtered : filtered.concat(toAdd)
    const closedCenters = db.closures
      .filter(i => i.from <= date && i.to >= date && i.type !== "holiday" && i.type !== "exception")
      .map(i => i.centerIndex)
    const closedWorkers = db.closures
      .filter(
        i =>
          i.from <= date &&
          i.to >= date &&
          ((i.type === "holiday" && i.status === "accepted") || i.type === "exception"),
      )
      .map(i => i.workerIndex)
    return results.map(i => {
      if (closedCenters.indexOf(i[0]) !== -1) {
        return [i[0], i[1], STARTING_HOUR, STARTING_HOUR]
      }
      if (closedWorkers.indexOf(i[1]) !== -1) {
        return [i[0], i[1], STARTING_HOUR, STARTING_HOUR]
      }
      return i
    })
  })
}

const getDayCalendar = (db: PublicDb | AdminDb, date: number, options?: Options) => {
  const calendar = getCalendar(db, date, options)
  return calendar[getSpainDate(date).day] ?? []
}

const getTreatmentsAverageDuration = (db: PublicDb, sel: Array<string>) => {
  return sel.reduce((tot, id) => {
    const treatment = db.treatments.find(t => t.id === id)
    if (!treatment) return tot
    return tot + treatment.averageDuration
  }, 0)
}

const getTreatmentsDuration = (db: PublicDb | AdminDb, sel: Array<string>, workerId: string) => {
  const worker = db.workers.find(w => w.id === workerId)
  if (!worker) return 0
  if (worker.treatments.filter(i => sel.includes(i.id)).length !== sel.length) return 0
  const reduce = sel.reduce((tot, id) => {
    return (worker.treatments.find(i => i.id === id)?.duration ?? 0) + tot
  }, 0)
  return Math.ceil(reduce / MINIMUM_TME) * MINIMUM_TME
}

const isActivePromotion = (promo: Promotion | AdminPromotion | undefined, date = Date.now()) => {
  if (!promo) return false
  if (promo.deleted) return false
  return promo.to >= date && promo.from <= date
}

const activePromotions = function <T extends PublicDb | AdminDb>(
  db: T,
  date = Date.now(),
): T["promotions"] {
  return db.promotions
    .filter(item => isActivePromotion(item, date))
    .sort((first, second) => second.created - first.created)
}

type Stat = { total: number; used: number }

const getBonusCardCredits = (bonuses: Array<AdminBonus | undefined>): number => {
  let tot = 0
  bonuses.forEach(bonus => {
    if (!bonus?.credit) return
    tot +=
      bonus.credit +
      bonus.transactions.reduce((acc, item) => {
        if (isBonusTreatmentTransaction(item)) return acc
        return acc + item.amount
      }, 0)
  })
  return tot
}

const getTreatments = (
  array: Array<string>,
  db: PublicDb,
  start: Record<string, Stat>,
): Record<string, Stat> => {
  return array.reduce((acc, id) => {
    if (id.startsWith("TAR-")) {
      const bonus = db.bonusCards.find(item => item.id === id)
      const ids = bonus?.treatments.map(item => new Array(item.quantity).fill(item.id)).flat()
      return getTreatments(ids ?? [], db, acc)
    }
    if (id.startsWith("TRT-")) {
      return {
        ...acc,
        [id]: { ...acc[id], total: (acc[id]?.total ?? 0) + 1, used: acc[id]?.used ?? 0 },
      }
    }
    return acc
  }, start)
}

const getBonusTreatments = (user: User, db: PublicDb): Record<string, Stat> => {
  const value: Record<string, Stat> = {}
  user.bonusCards?.forEach(bonus => {
    if (!bonus) return
    const { transactions, treatments } = bonus
    treatments.forEach(id => {
      Object.assign(value, {
        ...value,
        [id]: {
          total: (value[id]?.total ?? 0) + 1,
          used: value[id]?.used ?? 0,
        },
      })
    })
    transactions.forEach(item => {
      if (!isBonusTreatmentTransaction(item)) return
      Object.assign(value, {
        ...value,
        [item.treatmentId]: {
          total: value[item.treatmentId]?.total ?? 0,
          used: (value[item.treatmentId]?.used ?? 0) + 1,
        },
      })
    })
  })
  const array =
    user.orders?.map(order => order.cart.filter(cart => !cart.used).map(cart => cart.id)).flat() ??
    []
  return getTreatments(array, db, value)
}

const getOrdersCredits = (user: User, db: PublicDb) => {
  const array = user.orders
    .map(order => order.cart.filter(cart => !cart.used).map(cart => cart.id))
    .flat()
  const credit = array.reduce((acc, id) => {
    if (id.startsWith("TAR-")) {
      const bonus = db.bonusCards.find(item => item.id === id)
      return acc + (bonus?.credit ?? 0)
    }
    return acc
  }, 0)
  return credit
}

const shouldShowPrice = (db: PublicDb, cart: Array<string>, user: User) => {
  const products = db.products.filter(item => cart.includes(item.id))
  const hasBiologique = products.filter(item => item.menuHref === "biologique-recherche").length > 0
  const shouldShowPrice = !(hasBiologique && !user.logged)
  return shouldShowPrice
}

const getUTCDate = (timestamp: number, decimalSpainHour: number) => {
  const spainDate = getSpainDate(timestamp)
  const offset = new Date(timestamp).getTimezoneOffset() / 60
  const uts = new Date(spainDate.year, spainDate.month, spainDate.date, 0, 0, 0, 0)
  uts.setTime(
    uts.getTime() + (decimalSpainHour - getSpainOffset(timestamp) - offset) * 60 * 60 * 1000,
  )
  return uts
}

const formatTime = (d: Date) => {
  return `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`
}

const getWorkingHours = (refDate = Date.now()) => {
  return new Array(TIME_SLOTS).fill(STARTING_HOUR * HOUR_TIME_SLOTS).map((start, index) => {
    const date = getUTCDate(refDate, (index + start) * (1 / HOUR_TIME_SLOTS))
    const decimalHour = (38 + index) * (1 / HOUR_TIME_SLOTS)
    return { date, label: formatTime(date), index, decimalHour }
  })
}

const dateRangeOverlaps = (a_start: Date, a_end: Date, b_start: Date, b_end: Date) => {
  if (a_start <= b_start && b_start < a_end) return true // b starts in a
  if (a_start < b_end && b_end <= a_end) return true // b ends in a
  if (b_start < a_start && a_end < b_end) return true // a in b
  return false
}

function replaceAccentedChars(item: string) {
  return item
    .toLowerCase()
    .trim()
    .replace(/à/g, "a")
    .replace(/ä/g, "a")
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/è/g, "e")
    .replace(/ë/g, "e")
    .replace(/ï/g, "i")
    .replace(/í/g, "i")
    .replace(/ì/g, "i")
    .replace(/ö/g, "o")
    .replace(/ò/g, "o")
    .replace(/ó/g, "o")
    .replace(/ó/g, "o")
    .replace(/ü/g, "u")
    .replace(/ù/g, "u")
    .replace(/ú/g, "u")
    .replace(/ñ/g, "n")
    .trim()
}

const dateToSpainDecimalsTime = (date: Date) => {
  const hour = date.getUTCHours() + getSpainOffset(date.getTime())
  const minute = Math.ceil((date.getMinutes() / 60) * 100) / 100
  return hour + minute
}

const filterValidNewsletter = (user: AdminClient) => {
  if (!isValidEmail(user.email)) return false
  if (user.notifications.promotions !== "email") return false
  if (user.active === false) return false
  if (user.deleted === true) return false
  if (user.isSmartInactive === true) return false
  // if (user.created > Date.now() - ONE_YEAR * 1) return true
  // if ((user.lastVisited ?? 0) > Date.now() - ONE_YEAR * 1) return true
  // if ((user.lastActionOnline ?? 0) > Date.now() - ONE_YEAR * 1) return true
  // if ((user.lastBookOnline ?? 0) > Date.now() - ONE_YEAR * 1) return true
  // if ((user.lastBoughtOnline ?? 0) > Date.now() - ONE_YEAR * 1) return true
  return true
}

const generateBillNumber = (billRef: string, number: number) => {
  return Number(`${billRef}${number.toString().padStart(6, "0")}`)
}

function getChargeAmount(db: PublicDb | AdminDb, cart: Array<string>) {
  const products = cart.filter(i => i.startsWith("PRD-"))
  const { total: productsAmount } = getCartTotal(db, products)
  const freeChargeLimit = db.settings.freeChargeLimit
  const sendingCharge = db.settings.sendingCharge
  return productsAmount >= freeChargeLimit || !products.length ? 0 : sendingCharge
}

function roundToInt(value: number) {
  return Math.abs(Number(value.toFixed(0)))
}

function getDayWorkers(db: AdminDb, date: number): Array<number> {
  const day = getDayCalendar(db, date)
  return day.reduce(function (ret, d) {
    if (ret.indexOf(d[1]) === -1) ret.push(d[1])
    return ret
  }, [] as Array<number>)
}

function getWorkers(db: AdminDb, date: number, center: number): Array<number> {
  const day = getDayCalendar(db, date)
  return day
    .filter(d => d[0] === center)
    .reduce(function (ret, d) {
      if (ret.indexOf(d[1]) === -1) ret.push(d[1])
      return ret
    }, [] as Array<number>)
}

function getCartGrouped(db: PublicDb, cart: Array<string>) {
  const reduced = cart.sort().reduce((acc, id) => {
    return { ...acc, [id]: (acc[id] ?? 0) + 1 }
  }, {} as Record<string, number>)
  const treatments = Object.entries(reduced)
    .filter(([id]) => id.startsWith("TRT-"))
    .map(([id, quantity]) => {
      return { id, quantity, item: db.treatments.find(item => item.id === id) }
    })
  const products = Object.entries(reduced)
    .filter(([id]) => id.startsWith("PRD-"))
    .map(([id, quantity]) => {
      return { id, quantity, item: db.products.find(item => item.id === id) }
    })
  const bonusCards = Object.entries(reduced)
    .filter(([id]) => id.startsWith("TAR-"))
    .map(([id, quantity]) => {
      return { id, quantity, item: db.bonusCards.find(item => item.id === id) }
    })

  return { treatments, products, bonusCards }
}

const BANK_DEPOSIT = "Deposito en Banco"

const getPromotionCart = (promo: AdminPromotion | undefined) => {
  if (!promo) return []
  return promo.discounts
    .map(dis => {
      // if (dis.all) return []
      return new Array(dis.quantity).fill(dis.id)
    })
    .flat()
}

function getBookingCenter(adminDb: AdminDb, startTime: number, workerIndex: number) {
  const day = getDayCalendar(adminDb, startTime)
  const [centerIndex] = day.find(i => i[1] === workerIndex) || []
  const center = adminDb.centers.find(c => c.index === centerIndex)
  return center
}

function getValidWorkersList(adminDb: AdminDb) {
  return adminDb.workers.filter(w => !w.isWaitingList).filter(w => !w.deleted)
}

const calculateHolidayDuration = (
  db: AdminDb | PublicDb,
  from: number,
  to: number,
  workerIndex: number,
) => {
  const days = []
  let pointer = from

  while (dateUtils.isSameDate(pointer, to) || pointer <= to) {
    const c = getDayCalendar(db, pointer)
    const [centerIndex] = c.find(item => item[1] === workerIndex) ?? []
    const remove = db.closures.filter(
      // eslint-disable-next-line no-loop-func
      i =>
        i.type === "bankHoliday" &&
        i.centerIndex === centerIndex &&
        dateRangeOverlaps(
          new Date(dateUtils.startOfTheDay(pointer)),
          new Date(dateUtils.endOfTheDay(pointer)),
          new Date(i.from),
          new Date(i.to),
        ),
    )
    if (remove.length) {
      days.push({ pointer, value: -1 })
    } else {
      days.push({ pointer, value: 1 })
    }
    pointer += ONE_DAY
  }

  let count = 0
  let toRemove = 0
  let hasPassedMonday = false
  let hasPassedFriday = false
  days.forEach(item => {
    const day = new Date(item.pointer).getDay()
    if (day === 0 || day === 6) {
      //
    } else {
      if (day === 1) {
        hasPassedMonday = true
      } else if (day === 5) {
        hasPassedFriday = true
      }
      if (item.value < 0) {
        toRemove += 1
      }
      count += 1
      if (hasPassedFriday && hasPassedMonday) {
        hasPassedFriday = false
        hasPassedMonday = false
        count += 2
        toRemove = 0
      }
    }
  })

  return count - toRemove
}

const getTotalHolidays = (
  db: AdminDb | PublicDb,
  workerIndex: number,
  year: number,
  status: AdminClosure["status"] = "accepted",
) => {
  const worker = db.workers.find(w => w.index === workerIndex)
  if (worker === undefined) return 0
  const holidays = db.closures.filter(
    clo =>
      clo.workerIndex === workerIndex &&
      clo.type === "holiday" &&
      clo.status === status &&
      new Date(clo.from).getFullYear() === year,
  )
  return holidays.reduce((acc, item) => {
    return acc + calculateHolidayDuration(db, item.from, item.to, workerIndex)
  }, 0)
}

function isExpiredBuy(item: AdminBonus | AdminOrder | UnusedBonus, reopenedId = "") {
  if (isAdminBonus(item)) {
    return item.created <= Date.now() - BUY_EXPIRATION_VALUE && item._id !== reopenedId
  } else if (isAdminOrder(item)) {
    return item.timestamp <= Date.now() - BUY_EXPIRATION_VALUE && item._id !== reopenedId
  } else {
    return item.timestamp <= Date.now() - BUY_EXPIRATION_VALUE
  }
}
function getEventDescription(db: PublicDb | AdminDb, event: GoogleEvent | AdminBooking) {
  if (event.treatments.length === 0 && event.summary) return event.summary
  if (event.treatments.length === 0 && event.label) return event.label
  if (event.treatments.length !== 0)
    return event.treatments
      .map(id => {
        return db.treatments.find(x => x.id === id)?.title
      })
      .join(", ")
  const p = db.processes.find(x => x.processId === event.processId)
  if (p?.treatments.length) {
    return p.treatments
      .map(id => {
        return db.treatments.find(x => x.id === id)?.title
      })
      .join(", ")
  }

  return "Sin descripcion"
}

const getEventDuration = (start: number, end: number) => {
  return Math.ceil((end - start) / ((60 / HOUR_TIME_SLOTS) * 60 * 1000)) / HOUR_TIME_SLOTS
}

export {
  getPromotionCart,
  getCartTotal,
  activePromotions,
  getBonusTreatments,
  getTreatmentsDuration,
  getCalendar,
  getSpainOffset,
  getSpainDate,
  getBonusCardCredits,
  shouldShowPrice,
  getOrdersCredits,
  getUTCDate,
  getWorkingHours,
  TIME_SLOTS,
  HOUR_TIME_SLOTS,
  STARTING_HOUR,
  BANK_DEPOSIT,
  ENDING_HOUR,
  dateRangeOverlaps,
  replaceAccentedChars,
  getTreatmentsAverageDuration,
  dateToSpainDecimalsTime,
  isActivePromotion,
  filterValidNewsletter,
  generateBillNumber,
  getChargeAmount,
  getWorkers,
  getDayWorkers,
  getCartGrouped,
  getBookingCenter,
  getDayCalendar,
  bonusCardToPromotion,
  roundToInt,
  getStdCartTotal,
  getValidWorkersList,
  calculateHolidayDuration,
  getTotalHolidays,
  isExpiredBuy,
  getEventDescription,
  getEventDuration,
}
