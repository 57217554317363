import { Treatment } from "@in-and-out-belleza/api/interfaces"
import { Box, Grid, Paper } from "@mui/material"
import { TreatmentsSearchItem, mapToTreatmentResult } from "../../../queries/treatments-results"
import { BookingSuggestion } from "./BookingSuggestion"
import { Dispatch, SetStateAction, memo } from "react"

type Props = {
  treatments: Array<Treatment>
  setTreatments: Dispatch<SetStateAction<Array<TreatmentsSearchItem>>>
}

const BookingSuggestionList = memo(({ treatments, setTreatments }: Props) => {
  return (
    <Box component="div">
      <Box component="div" sx={{ p: 1 }}>
        <Grid container spacing={1} sx={{ display: "flex" }}>
          {treatments
            .filter(item => item.canBookOnline)
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(trt => {
              return (
                <Grid
                  key={trt?.id}
                  item
                  xs={6}
                  sm={3}
                  sx={{ alignItems: "stretch", display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    if (!trt) return
                    setTreatments(prev => [...prev, mapToTreatmentResult(trt)])
                  }}
                >
                  <Paper sx={{ p: 1, width: "100%" }}>
                    <BookingSuggestion treatment={trt} />
                  </Paper>
                </Grid>
              )
            })}
        </Grid>
      </Box>
    </Box>
  )
})

export { BookingSuggestionList }
