import {
  AdminDb,
  AdminPromotion,
  PublicDb,
  isTreatmentId,
  Gift,
} from "@in-and-out-belleza/api/interfaces"
import { intersectArray } from "./intersectArray"
import { activePromotions, bonusCardToPromotion } from "./publicDb"

const getStdCartTotal = (
  db: PublicDb | AdminDb,
  cart: Array<string>,
  timestamp?: number,
  promos: Array<AdminPromotion> = activePromotions(db, timestamp),
) => {
  const cloneCart = cart.slice(0)
  const discounted1: Array<{ id: string; multiplier: number }> = []

  promos.forEach(promo => {
    const andIds = promo.discounts
      .filter(dis => dis.operator === "and")
      .map(item => new Array(item.quantity).fill(item.id))
      .flat()

    let intersect = intersectArray(cloneCart, andIds)
    while (intersect.length && intersect.length <= cloneCart.length) {
      intersect.forEach(id => {
        cloneCart.splice(cloneCart.indexOf(id), 1)
        const dis = promo.discounts.find(i => i.id === id)
        discounted1.push({ id, multiplier: dis?.multiplier ?? 1 })
      })
      intersect = intersectArray(cloneCart, andIds)
    }

    const orDis = promo.discounts.filter(dis => dis.operator === "or")
    let dis = orDis.find(dis => cloneCart.filter(id => id === dis.id).length > 0)
    while (dis) {
      discounted1.push({ id: dis.id, multiplier: dis.multiplier })
      cloneCart.splice(cloneCart.indexOf(dis.id), 1)
      dis = orDis.find(dis => cloneCart.filter(id => id === dis.id).length > 0)
    }
  })

  const tots = cloneCart
    .map(id => ({ id, multiplier: 1 }))
    .concat(discounted1)
    .map(({ id, multiplier }) => {
      const trtItem = db.treatments.find(sub => sub.id === id)
      const prdItem = db.products.find(sub => sub.id === id)
      const bnsItem = db.bonusCards.find(sub => sub.id === id)
      const bpItem = db.beautyParties.find(sub => sub.id === id)
      const item = trtItem || prdItem || bnsItem || bpItem
      return {
        real: item?.price ?? 0,
        discounted: (item?.price ?? 0) * multiplier,
        id,
        item,
        cantSell: !item?.price,
      }
    })
  const totalDecimals = tots.reduce((sum, item) => sum + item.real, 0)
  const total = Number((totalDecimals * 100).toFixed(0)) / 100
  const discountedDecimals = tots.reduce((sum, item) => sum + item.discounted, 0)
  const discounted = Number((discountedDecimals * 100).toFixed(0)) / 100

  return {
    total,
    discount: total - discounted,
    real: discounted,
    till: 0,
    count: tots.length,
    cantSell: tots.filter(item => item.cantSell).length > 0,
  }
}

function getTreatmentsTotal(
  db: AdminDb | PublicDb,
  cart: Array<string>,
  timestamp = Date.now(),
  promos: Array<AdminPromotion> = activePromotions(db, timestamp),
) {
  const promotions = db.bonusCards
    .filter(b => !b.deleted)
    .filter(bc => Date.now() <= bc.to)
    .filter(bc => {
      return intersectArray(
        cart,
        bc.treatments.map(i => i.id),
      ).length
    })
    .map(i => bonusCardToPromotion(db, i))
    .sort((first, second) => second.discounts.length - first.discounts.length)
    .concat(promos)

  const [promo] = promotions
    .map(p => getStdCartTotal(db, cart, timestamp, [p]))
    .sort((f, s) => f.real - s.real)

  return {
    amount: promo ?? getStdCartTotal(db, cart, timestamp),
    promotions,
  }
}

const getCartTotal = (
  db: PublicDb | AdminDb,
  cart: Array<string>,
  timestamp?: number,
  promos: Array<AdminPromotion> = activePromotions(db, timestamp),
) => {
  const trtCart = cart.filter(isTreatmentId)
  const notTtCart = cart.filter(id => !isTreatmentId(id))
  const totNoTrt = getStdCartTotal(db, notTtCart, timestamp, promos)
  const totTrtCart = getTreatmentsTotal(db, trtCart, timestamp).amount

  return {
    total: totNoTrt.total + totTrtCart.total,
    discount: totNoTrt.discount + totTrtCart.discount,
    real: totNoTrt.real + totTrtCart.real,
    till: totNoTrt.till ?? totTrtCart.till,
    count: totNoTrt.count + totTrtCart.count,
    cantSell: totNoTrt.cantSell || totTrtCart.cantSell,
  }
}

const getCartAndGiftsTotal = (
  db: PublicDb | AdminDb,
  cart: Array<string>,
  gifts: Array<Gift>,
  timestamp?: number,
  promos: Array<AdminPromotion> = activePromotions(db, timestamp),
) => {
  const tot = getCartTotal(db, cart.concat(gifts.map(i => i.cart).flat()), timestamp, promos)
  const credit = gifts.reduce((a, g) => a + g.credit, 0)
  return {
    ...tot,
    real: tot.real + credit,
    total: tot.total + credit,
  }
}

export { getCartTotal, getTreatmentsTotal, getStdCartTotal, getCartAndGiftsTotal }
