import { Box, Link, Paper } from "@mui/material"

const Privacy = () => {
  return (
    <Box component="div">
      <h2>Cláusula Informativa (Tratamientos de datos)</h2>
      <Paper sx={{ p: 1 }}>
        Le informamos que sus datos personales serán tratados por{" "}
        <strong>HIDIME BELLEZA S.L</strong>, con el fin de gestionar su solicitud y el mantenimiento
        de las relaciones profesionales y comerciales con usted.
        <br />
        Sus datos no serán cedidos a terceros. Esta tratamiento de datos es necesario para atender
        su solicitud.{" "}
        <em>
          Sus datos serán conservados hasta que Usted se dé de baja del servicio y/o pasado un
          tiempo prudencial desde que atendamos su solicitud
        </em>
        .
        <br />
        Ud. puede ejercer sus derechos de acceso, rectificación, cancelación, oposición,
        portabilidad y limitación del tratamiento de sus datos dirigiéndose a HIDIME BELLEZA, C/
        Salitre 11, Bajo, 29002 Málaga (Málaga) o a&nbsp;
        <Link href="mailto:info@inandoutbelleza.com">info@inandoutbelleza.com</Link>, acompañando
        copia de su DNI acreditando debidamente su identidad. En cualquier situación, Ud. tiene
        derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD).
      </Paper>

      <h2>Política de cookies</h2>
      <Paper sx={{ p: 1 }}>
        <h3>¿Qué son las cookies?</h3>
        <p>
          Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas
          web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar
          información sobre las preferencias de navegación de un usuario o de su equipo. HIDIME
          BELLEZA, S.L utiliza cookies en su portal web <Link href="/">www.inandoutbelleza.es</Link>{" "}
          para almacenar las preferencias del usuario con el fin de mejorar la calidad de nuestros
          servicios, asegurar el funcionamiento técnico tanto del portal como de las transacciones
          realizadas, y desarrollar nuevas y mejores prestaciones. Si se desactivan o rechazan las
          cookies, puede ocurrir que algunas de las funciones y/o servicios no funcionen
          adecuadamente.
        </p>
      </Paper>
      <h2>¿Qué Cookies utilizamos?</h2>
      <Paper sx={{ p: 1 }}>
        <p>Utilizamos las siguientes cookies en nuestro portal:</p>

        <div>
          <strong>DOMINIO:</strong>{" "}
          <Link href="https://www.google-analytics.com">https://www.google-analytics.com</Link>
        </div>
        <div>
          <strong>NOMBRE:</strong> GOOGLE ANALYTICS
        </div>
        <div>
          <strong>FINALIDAD:</strong> Google Analytics recopila cookies propias, los datos
          relacionados con el dispositivo y el navegador, la dirección IP y datos sobre las
          actividades en el sitio web o la aplicación para medir y elaborar informes de estadísticas
          sobre las interacciones de los usuarios
        </div>
        <div>
          <strong>CADUCIDAD:</strong> Nunca
        </div>
        <hr />
        <div>
          <strong>DOMINIO:</strong>{" "}
          <Link href="https://www.stripe.com">https://www.stripe.com</Link>
        </div>
        <div>
          <strong>NOMBRE:</strong> STRIPE
        </div>
        <div>
          <strong>FINALIDAD:</strong> Stripe es un servicio de terceros que este sitio web utiliza
          para tus compras con tarjetas de credito/debito
        </div>
        <div>
          <strong>CADUCIDAD:</strong> Nunca
        </div>
        <hr />
        <br />
      </Paper>
      <h2>¿Cómo deshabilitar las cookies en los principales navegadores?</h2>
      <Paper sx={{ p: 1 }}>
        <p>
          Normalmente es posible dejar de aceptar las Cookies del navegador, o dejar de aceptar las
          Cookies de un Servicio en particular. Todos los navegadores modernos permiten cambiar la
          configuración de Cookies. Estos ajustes normalmente se encuentra en las ‘opciones’ o
          ‘Preferencias’ del menú de su navegador. Aunque puede variar ligeramente de una versión de
          navegador a otra, la configuración de la política de cookies para los navegadores más
          utilizados es la siguiente:
        </p>

        <ul>
          <li>
            <Link
              target="_blank"
              href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
            >
              Chrome
            </Link>{" "}
            Configuración -&gt; Mostrar opciones avanzadas -&gt; Privacidad -&gt; Configuración de
            contenido.
          </li>
          <li>
            <Link
              target="_blank"
              href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
            >
              Internet Explorer/Edge
            </Link>{" "}
            Herramientas -&gt; Opciones de Internet -&gt; Privacidad -&gt; Configuración.
          </li>
          <li>
            <Link
              target="_blank"
              href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
            >
              Firefox
            </Link>{" "}
            Herramientas -&gt; Opciones -&gt; Privacidad -&gt; Historial -&gt; Configuración
            Personalizada.
          </li>
          <li>
            <Link target="_blank" href="http://support.apple.com/kb/ph5042">
              Safari
            </Link>{" "}
            Preferencias -&gt; Seguridad. Para más información, puede consultar el soporte o la
            ayuda de su navegador.
          </li>
        </ul>
      </Paper>
    </Box>
  )
}

export { Privacy }
