import { useAtom } from "jotai"
import { useMemo } from "react"

import { createTheme } from "@mui/material"
import * as locales from "@mui/material/locale"

import { _darkMode, _locale } from "./atoms/config"
import { darkTheme, lightTheme } from "@in-and-out-belleza/ui"

const useThemeWithLocale = () => {
  const [locale] = useAtom(_locale)
  const [darkMode] = useAtom(_darkMode)

  const themeWithLocale = useMemo(
    () => createTheme(darkMode ? darkTheme : lightTheme, locales[locale]),
    [locale, darkMode],
  )
  return themeWithLocale
}

export { useThemeWithLocale }
