import { Box, Typography } from "@mui/material"

const BeautyPartyOutro = () => {
  return (
    <Box component="div">
      <Typography sx={{ m: 1, p: 1 }}>
        Además de estos packs, también te ofrecemos la posibilidad de diseñar vuestro pack
        personalizado en base a vuestros gustos.
      </Typography>
      <Typography sx={{ m: 1, p: 1 }}>
        Ponte en contacto con In&Out y cuéntanos qué te apetece para tu Beauty Party
      </Typography>
    </Box>
  )
}

export { BeautyPartyOutro }
