import { getApiClient } from "@in-and-out-belleza/api/client"
import {
  AdminClient,
  AdminBooking,
  GoogleEvent,
  PublicDb,
} from "@in-and-out-belleza/api/interfaces"
import { useAdminClients } from "./admin-db"
import { usePublicDb } from "./public-db"
import { useQueries, useQuery } from "@tanstack/react-query"

const ONE_DAY = 24 * 60 * 60 * 1000

const getNextWorkingDate = (date: number) => {
  const here = new Date(date)
  if (here.getDay() === 0) return date + 1 * ONE_DAY
  if (here.getDay() === 6) return date + 2 * ONE_DAY
  return date
}

const addWorkingDate = (date: number) => {
  const here = new Date(date)
  if (here.getDay() === 0) return date + 2 * ONE_DAY
  if (here.getDay() === 6) return date + 3 * ONE_DAY
  return date
}

const getClientBookingLabel = (client: AdminClient | undefined): string => {
  return client ? `${client.name} ${client.surname}` : ""
}

const threeMinutes = 3 * 60 * 1000

const queryFn = (timestamp: number) => async () => {
  const client = getApiClient()
  const { data } = await client.post<Array<{ workerIndex: number; events: Array<AdminBooking> }>>(
    "/google/calendar/get-all",
    {
      timestamp,
    },
  )
  return data
}

const enrichBookingsWithClients = (
  rawData: Array<{ workerIndex: number; events: Array<AdminBooking> }> | undefined,
  clients: Array<AdminClient>,
  db: PublicDb,
) => {
  const ret: Array<GoogleEvent> = []
  return rawData?.reduce((acc, item) => {
    if (!item.events) return acc
    return acc.concat(
      ...item.events.map(ev => {
        const client = clients.find(i => i._id === ev.clientId)
        const worker = db.workers.find(i => i.index === item.workerIndex)
        return {
          ...ev,
          clientLabel: getClientBookingLabel(client),
          workerId: worker?.id ?? "",
        }
      }),
    )
  }, ret)
}

const getArrayOfWorkingDays = (array: Array<number>) => {
  const init: Array<number> = []
  const dates = array.reduce(acc => {
    const ref = acc[acc.length - 1]
      ? getNextWorkingDate(acc[acc.length - 1] + ONE_DAY)
      : getNextWorkingDate(Date.now())
    return acc.concat(ref)
  }, init)
  return dates
}

const useDayBookings = (timestamp: number) => {
  const date = new Date(timestamp).getTime()
  const key = new Date(timestamp).toDateString()
  const { data: clients = [] } = useAdminClients()
  const { db } = usePublicDb()
  const { data: rawData, ...rest } = useQuery({
    queryFn: queryFn(date),
    queryKey: ["day-bookings", key],
    staleTime: threeMinutes,
    cacheTime: threeMinutes,
    refetchOnWindowFocus: true,
  })
  const data = enrichBookingsWithClients(rawData, clients, db)
  return { ...rest, rawData, data }
}

const useWorkersBookings = (timestamp: number) => {
  const date = new Date(timestamp).getTime()
  const key = new Date(timestamp).toDateString()
  return useQuery({
    queryFn: queryFn(date),
    queryKey: ["day-bookings", key],
    staleTime: threeMinutes,
    cacheTime: threeMinutes,
    refetchOnWindowFocus: true,
  })
}

const useNextDaysBookings = (dates: Array<number>) => {
  return useQueries({
    queries: dates.map(date => ({
      queryFn: queryFn(date),
      queryKey: ["day-bookings", new Date(date).toDateString()],
      staleTime: threeMinutes,
      cacheTime: threeMinutes,
      refetchOnWindowFocus: true,
    })),
  })
}

export {
  getClientBookingLabel,
  useDayBookings,
  useWorkersBookings,
  useNextDaysBookings,
  getNextWorkingDate,
  addWorkingDate,
  ONE_DAY,
  getArrayOfWorkingDays,
  enrichBookingsWithClients,
}
