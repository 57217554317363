import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Link,
  Typography,
} from "@mui/material"
import { useCart } from "../../atoms/atomCart"
import { useNav } from "../../hooks/useNav"
import { Delete, ExpandMore } from "@mui/icons-material"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { Link as RouterLink } from "react-router-dom"
import { useCartGrouped } from "../Cart/useCartGrouped"
import { useEffect, useRef, useState } from "react"
import { CarouselMoreSelled } from "./CarouselMoreSelled"
import { CarouselPromotions } from "../Content/Carousel/Promotions"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import {
  BonusCard,
  Product,
  Treatment,
  isBonusCard,
  isTreatment,
} from "@in-and-out-belleza/api/interfaces"
import queryString from "query-string"
import { GiftPdfPreview } from "../GiftPdfPreview/GifPdfPreview"

type GroupedCartItem = {
  id: string
  quantity: number
  item: Product | BonusCard | Treatment | undefined
}

const AccordionCartItem = ({ item }: { item: GroupedCartItem }) => {
  const { setCart } = useCart()
  return (
    <Box component="div" key={item.id} sx={{ display: "flex", alignItems: "center" }}>
      <Delete
        sx={{ cursor: "pointer", mr: 2 }}
        onClick={() => {
          setCart(prev => prev.filter(i => i !== item.id))
        }}
      />
      <Link component={RouterLink} to={item.item?.href ?? ""}>
        {item.quantity} x {isBonusCard(item.item) ? "BONO:" : null}
        {isTreatment(item.item) ? item.item?.type : null} {item.item?.title}
      </Link>
    </Box>
  )
}

const Shop = () => {
  const { isMobile } = useDeviceInfo()
  const { cart: cartNav, treatments: treatmentsNav, products: productsNav, bonuses } = useNav()
  const { cart, gifts, setGifts, numOfArticles } = useCart()
  const prev = useRef(cart)
  const [expanded, setExpanded] = useState(false)
  const { treatments, products, bonusCards } = useCartGrouped(cart)

  useEffect(() => {
    setExpanded(true)
  }, [])

  useEffect(() => {
    const tm = setTimeout(() => {
      setExpanded(false)
    }, 3000)
    if (prev.current.length !== cart.length) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setExpanded(true)
    }
    prev.current = cart
    return () => {
      clearTimeout(tm)
    }
  }, [cart])

  return (
    <Box component="div" sx={{ pt: 1 }}>
      {numOfArticles() ? (
        <Box component="div" sx={{ mb: 1 }}>
          <Accordion sx={{ m: 0 }} expanded={expanded} onChange={(_, value) => setExpanded(value)}>
            <AccordionSummary
              sx={{
                "&.MuiAccordionSummary-root": { minHeight: 0 },
                "& .MuiAccordionSummary-content": { my: 0.5 },
                "& .MuiAccordionSummary-content.Mui-expanded": { my: 0.5 },
              }}
              expandIcon={<ExpandMore />}
            >
              <Button
                startIcon={<BeautyIcon name="basket" size={24} fill="white" />}
                component={RouterLink}
                variant="contained"
                color="secondary"
                to={cartNav.url()}
                sx={{ mr: 2 }}
              >
                TERMINA COMPRA
              </Button>
              <Typography sx={{ flexGrow: 1, alignSelf: "center" }}>
                {numOfArticles()} ARTICULO{numOfArticles() > 1 ? "S" : ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{ mb: 1 }} />
              {gifts.map((gift, index) => {
                return (
                  <Box
                    component="div"
                    key={`${index}-${gift.name}`}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Delete
                      sx={{ cursor: "pointer", mr: 2 }}
                      onClick={() => {
                        setGifts(() => {
                          gifts.splice(index, 1)
                          return [...gifts]
                        })
                      }}
                    />
                    <Link
                      href={`/api/gift-pdf/preview?${queryString.stringify(gift)}`}
                      target="_blank"
                    >
                      TARJETA REGALO PARA "{gift.name}"
                    </Link>
                  </Box>
                )
              })}
              {treatments.map(item => (
                <AccordionCartItem key={item.id} item={item} />
              ))}
              {bonusCards.map(item => (
                <AccordionCartItem key={item.id} item={item} />
              ))}
              {products.map(item => (
                <AccordionCartItem key={item.id} item={item} />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : null}
      <CarouselPromotions />
      <Divider sx={{ my: 1 }} />
      <GiftPdfPreview />
      <Divider sx={{ my: 3 }} />
      <Box component="div" sx={{ pl: isMobile ? 1 : 6 }}>
        <Typography>LOS TRATAMIENTOS MAS VENDIDOS:</Typography>
        <Link component={RouterLink} to={treatmentsNav.url()}>
          Ir a todos los tratamientos
        </Link>
      </Box>
      <CarouselMoreSelled include={["treatments"]} />
      <Divider sx={{ my: 3 }} />
      <Box component="div" sx={{ pl: isMobile ? 1 : 6 }}>
        <Typography>LOS BONOS MAS VENDIDOS:</Typography>
        <Link component={RouterLink} to={bonuses.url()}>
          Ir a todos los bonos
        </Link>
      </Box>
      <CarouselMoreSelled include={["bonusCards"]} />
      <Divider sx={{ my: 3 }} />
      <Box component="div" sx={{ pl: isMobile ? 1 : 6 }}>
        <Typography>LOS PRODUCTOS MAS VENDIDOS:</Typography>
        <Link component={RouterLink} to={productsNav.url()}>
          Ir a todos los productos
        </Link>
      </Box>
      <CarouselMoreSelled include={["products"]} />
    </Box>
  )
}

export { Shop }
