import { Button, ButtonProps, styled } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useBooking } from "../../atoms/atomBooking"
import { mapToTreatmentResult } from "../../queries/treatments-results"
import { useNav } from "../../hooks/useNav"
import { EventAvailable } from "@mui/icons-material"

type Props = ButtonProps & {
  cart: Array<string>
  title?: string
}

const BookButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.text.primary,
  fill: theme.palette.text.primary,
  "&:active, &:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
}))

const BookButton = ({ cart, title = "RESERVA", ...rest }: Props) => {
  const { db } = usePublicDb()
  const { booking } = useNav()
  const { setTreatments } = useBooking()
  const treatments = db.treatments.filter(item => cart.includes(item.id))
  const cantBookOnline = !!treatments.filter(item => !item.canBookOnline).length

  if (!treatments.length) return null
  if (cantBookOnline) {
    return (
      <BookButtonStyled startIcon={<BeautyIcon name="phone" size={20} />} {...rest}>
        LLAMANOS
      </BookButtonStyled>
    )
  }
  return (
    <BookButtonStyled
      startIcon={<EventAvailable />}
      onClick={event => {
        event.stopPropagation()
        event.preventDefault()
        setTreatments(treatments.map(mapToTreatmentResult))
        booking.navigate()
      }}
      {...rest}
    >
      {title}
    </BookButtonStyled>
  )
}

export { BookButton, BookButtonStyled }
