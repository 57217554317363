import { Box, Paper, Typography } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { useUserInfo } from "../../hooks/useUserInfo"

const UserBonusCards = () => {
  const { isMobile } = useDeviceInfo()
  const { availableTreatments } = useUserInfo()
  const { db } = usePublicDb()

  return (
    <Box component="div">
      {isMobile ? null : (
        <Box component="div" sx={{ display: "flex", m: 1, alignItems: "center" }}>
          <Typography>TRATAMIENTOS EN TU BONOS</Typography>
        </Box>
      )}
      {Object.keys(availableTreatments).map(id => {
        const item = db.treatments.find(sub => sub.id === id)
        return (
          <Paper sx={{ m: 1, p: 1 }} key={id}>
            <Typography color="primary" component="strong">
              {item?.type}: {item?.title}
            </Typography>
            <br />
            <Typography fontSize={14} component="em">
              <div>
                Tratamientos pendientes{" "}
                {availableTreatments[id].total - availableTreatments[id].used}
              </div>
            </Typography>
          </Paper>
        )
      })}
    </Box>
  )
}

export { UserBonusCards }
