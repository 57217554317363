import { AdminOrder, isProductId } from "@in-and-out-belleza/api/interfaces"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartGrouped } from "@in-and-out-belleza/api/utils"
import { useDate } from "@in-and-out-belleza/hooks"
import { downloadURI } from "@in-and-out-belleza/utils"
import { PictureAsPdf } from "@mui/icons-material"
import { Box, Button, Paper, Typography } from "@mui/material"
import queryString from "query-string"

type Props = {
  order: AdminOrder
}

const ClientOrderDetail = ({ order }: Props) => {
  const { db } = usePublicDb()
  const { longDate } = useDate()
  const { treatments, products, bonusCards } = getCartGrouped(
    db,
    order.cart.map(i => i.id),
  )
  return (
    <Paper sx={{ m: 1, p: 1 }}>
      <Box
        component="div"
        sx={{
          lineHeight: "46px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {order.gifts.map((item, index) => {
          return (
            <Box component="div" sx={{ display: "flex" }} key={`${index}-${item.name}`}>
              <PictureAsPdf
                sx={{ mx: 1 }}
                onClick={() => {
                  downloadURI(
                    `/api/gift-pdf/${item.id}?${queryString.stringify({
                      ...item,
                      cart: item.cart.map(i => i.id),
                      credit: item.credit.amount,
                    })}`,
                  )
                }}
              />
              <Typography color="primary" sx={{ flexGrow: 1 }}>
                REGALO PARA "{item.name}"
              </Typography>
            </Box>
          )
        })}
        {[...products, ...treatments, ...bonusCards].map(item => {
          const used = order.cart.find(i => i.id === item.id)
          return (
            <Box component="div" sx={{ display: "flex" }} key={item.id}>
              <Typography color="primary" sx={{ flexGrow: 1 }}>
                {item.quantity} x {item.item?.title}
              </Typography>
              {used?.used ? (
                <Typography color="#aaa">
                  {isProductId(item.id) ? "Enviado " : "Usado "}
                  {used.timestamp ? longDate(used.timestamp) : ""}
                </Typography>
              ) : null}
            </Box>
          )
        })}
      </Box>
      <Button
        sx={{ m: 1 }}
        onClick={() => downloadURI(`/api/pdf/${order._id}`, `COMPRA - ${longDate(order.created)}`)}
        variant="contained"
      >
        DESCARGAR BILLETE
      </Button>
      <Typography fontSize={14} component="em">
        Comprado: {longDate(order.created)}
      </Typography>
    </Paper>
  )
}

export { ClientOrderDetail }
