import { ArrowBack } from "@mui/icons-material"
import { AppBar, Box, IconButton, styled, Toolbar, Typography } from "@mui/material"
import { useBooking } from "../../atoms/atomBooking"

import { useNav } from "../../hooks/useNav"
import { SearchInput } from "../Search"
import { SearchTreatment } from "../Search/SearchTreatment"

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  "&:active, &:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}))

const MobileHeader = () => {
  const { navigationTitle, back, booking, cart, shop } = useNav()
  const title = navigationTitle()
  const { treatments, setTreatments } = useBooking()

  return (
    <Box component="div" sx={{ flexGrow: 1, mt: 1 }}>
      <AppBar position="sticky" color="secondary">
        <Toolbar sx={{ padding: 0 }}>
          {title ? (
            <Box component="div" sx={{ width: "100%" }}>
              <IconButtonStyled sx={{ ml: 1, position: "fixed" }} onClick={back}>
                <ArrowBack color="primary" />
              </IconButtonStyled>
              <Typography
                variant="h6"
                sx={{
                  pt: "6px",
                  ml: "60px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "calc(100% - 70px)",
                }}
              >
                {title}
              </Typography>
            </Box>
          ) : booking.isPath() ? (
            <SearchTreatment
              sx={{ width: "100%", padding: 0, mx: 1 }}
              value={treatments}
              setValue={setTreatments}
            />
          ) : (
            <SearchInput
              sx={{ width: "100%", padding: 0, mx: 1 }}
              shouldAddToCart={shop.isPath() || cart.isPath()}
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export { MobileHeader }
