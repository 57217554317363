import { Box } from "@mui/material"

const CreditCardImages = () => {
  return (
    <Box component="div" sx={{ mt: 1, display: "flex", justifyContent: "center", gap: 1 }}>
      <Box
        component="div"
        sx={{
          background: "url('/assets/credit-cards.png') 0 1342px",
          width: 90,
          height: 58,
        }}
      ></Box>
      <Box
        component="div"
        sx={{
          background: "url('/assets/credit-cards.png') 0 1258px",
          width: 90,
          height: 58,
        }}
      ></Box>
      <Box
        component="div"
        sx={{
          background: "url('/assets/credit-cards.png') 0 1006px",
          width: 90,
          height: 58,
        }}
      ></Box>
    </Box>
  )
}

export { CreditCardImages }
