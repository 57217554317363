import { Gift } from "@in-and-out-belleza/api/interfaces"
import { atom, useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"

type CartSendTo = {
  name?: string
  address?: string
  city?: string
  cap?: string
  tel?: string
  isGift?: number
}

const emptyGift = { activeStep: 0, cart: [], name: "", credit: 0 }
const _cart = atomWithStorage<Array<string>>("inandout.cart", [])
const _gifts = atomWithStorage<Array<Gift>>("inandout.gifts", [])
const _createGift = atomWithStorage<Gift>("inandout.create-gift", emptyGift)
const _openSearchCart = atom<boolean>(false)
const _sendTo = atom<CartSendTo>({ isGift: 0, name: "", address: "", city: "", cap: "", tel: "" })

const useCart = () => {
  const [cart, setCart] = useAtom(_cart)
  const [gifts, setGifts] = useAtom(_gifts)
  const [createGift, setCreateGift] = useAtom(_createGift)
  const [openSearch, setOpenSearch] = useAtom(_openSearchCart)
  const [sendTo, setSendTo] = useAtom(_sendTo)
  const numOfArticles = () => {
    return cart.length + gifts.length
  }
  return {
    cart,
    openSearch,
    setCart,
    setOpenSearch,
    sendTo,
    setSendTo,
    gifts,
    setGifts,
    numOfArticles,
    createGift,
    setCreateGift,
  }
}

export { useCart, emptyGift }
export type { CartSendTo, Gift }
