import { Remove } from "@mui/icons-material"
import { Box, Chip, Typography } from "@mui/material"
import { useBooking } from "../../atoms/atomBooking"
import { useDate, useDeviceInfo } from "@in-and-out-belleza/hooks"
import { SearchTreatment } from "../Search/SearchTreatment"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { usePublicDb } from "@in-and-out-belleza/api/resources"

const BookingSelect = () => {
  const { isMobile } = useDeviceInfo()
  const { db } = usePublicDb()
  const { treatments, setTreatments } = useBooking()
  const { currency } = useDate()
  const price = getCartTotal(
    db,
    treatments.map(i => i.id),
  ).real
  return (
    <Box component="div">
      {!isMobile ? (
        <Box component="div" sx={{ p: "8px 0", mb: 1 }}>
          <Typography>PIDE UNA NUEVA CITA:</Typography>
          <SearchTreatment value={treatments} setValue={setTreatments} />
        </Box>
      ) : null}
      {treatments.length ? (
        <Box component="div" sx={{ backgroundColor: "black", color: "white", m: -1, mb: 1, p: 1 }}>
          <Box component="div" sx={{ display: "flex" }}>
            <Typography flexGrow={1}>LOS TRATAMIENTOS DE TU CITA:</Typography>
            <Typography>{currency(price)}</Typography>
          </Box>
          {treatments.map(item => (
            <Chip
              sx={{ m: "8px 8px 0 0", background: "white" }}
              key={item.id}
              label={item.label}
              deleteIcon={<Remove />}
              onDelete={() => {
                setTreatments(prev => {
                  const index = prev.findIndex(sub => sub.id === item.id)
                  prev.splice(index, 1)
                  return [...prev]
                })
              }}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  )
}

export { BookingSelect }
