import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useNav } from "../../hooks/useNav"
import { BuyButton } from "../Buttons/BuyButton"
import { MetatagProduct } from "../MetatagProduct"
import { NotFoundPage } from "../NotFoundPage"
import { GiftButton } from "../Buttons/GiftButton"
import { getCartTotal } from "@in-and-out-belleza/api/utils"

const BonusCard = () => {
  const { db } = usePublicDb()
  const { pathname } = useLocation()
  const { bonuses } = useNav()
  const bonus = db.bonusCards.find(item => item.href === pathname)

  if (!bonus) return <NotFoundPage />
  const price = getCartTotal(db, bonus.treatments.map(i => new Array(i.quantity).fill(i.id)).flat())
  return (
    <Box component="div">
      <MetatagProduct
        name={bonus.title}
        description={bonus.description}
        photo={""}
        cart={[bonus.id]}
        brand={"In&Out"}
        href={bonus.href}
      />
      <Paper component={Link} to={bonuses.url()} sx={{ m: 1, p: 1, display: "flex" }}>
        <Typography>VOLVER A TODOS LOS BONOS</Typography>
        <Box component="div" sx={{ flexGrow: 1, justifyContent: "end", display: "flex" }}>
          <BeautyIcon name="discount" size={20} />
        </Box>
      </Paper>
      <Paper sx={{ m: 1, p: 1, "& img": { width: "100%" } }}>
        <Typography variant="h6">{bonus.title}</Typography>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Typography dangerouslySetInnerHTML={{ __html: bonus.description }} />
        {bonus.credit ? (
          <Box component="div">
            <Box component="div" sx={{ mt: 1, mb: 1 }} />
            <Typography fontSize={22}>
              Credito includo: <strong>{bonus.credit.toFixed(2)} Euro</strong>
            </Typography>
          </Box>
        ) : null}
        <Divider sx={{ mt: 1, mb: 1 }} />
        {price.real !== price.total ? (
          <GiftButton cart={bonus.treatments.map(i => new Array(i.quantity).fill(i.id)).flat()} />
        ) : null}{" "}
        <BuyButton cart={[bonus.id]} />
      </Paper>
      {bonus.treatments.length ? (
        <Box component="div">
          <Box component="div" sx={{ mt: 1, mb: 1 }} />
          <Typography sx={{ m: 1, p: 1 }}>Trataminetos incluidos:</Typography>
          {bonus.treatments.map(trt => {
            const treatment = db.treatments.find(item => item.id === trt.id)
            if (!treatment) return null
            return (
              <Paper sx={{ m: 1, p: 1, display: "flex" }} key={trt.id}>
                <Typography sx={{ flexGrow: 1 }}>
                  {trt.quantity} x {treatment.title}
                </Typography>
                <Button component={Link} to={treatment.href}>
                  VER
                </Button>
              </Paper>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export { BonusCard }
