import { AdminWorker, Center } from "@in-and-out-belleza/api/interfaces"
import { Box, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useBookingInsert } from "../../queries/insert-bookig"
import { TreatmentsSearchItem } from "../../queries/treatments-results"
import { UserLoginStepper } from "../User/UserLoginStepper"
import { GenericDialog, useConfirmDialog } from "@in-and-out-belleza/ui"
import { queryClient } from "../../contexts/queryClient"
import { useBooking } from "../../atoms/atomBooking"
import { BookButtonStyled } from "../Buttons/BookButton"
import { usePublicDb } from "@in-and-out-belleza/api/resources"

type Props = {
  onSuccess: () => void
  hour: {
    timestamp: number
    duration: number
    worker: AdminWorker
  } | null
  center: Center
  treatments: Array<TreatmentsSearchItem>
  text?: string | JSX.Element
}

const BookingConfirm = ({ onSuccess, hour, center, treatments, text }: Props) => {
  const { db } = usePublicDb()
  const [open, setOpen] = useState(false)
  const { user } = useAuth()
  const { mutateAsync, isLoading } = useBookingInsert()
  const { setHour } = useBooking()
  const { enqueueSnackbar } = useSnackbar()
  const { Dialog, openDialog } = useConfirmDialog()

  const book = async () => {
    await mutateAsync({
      hour: hour?.timestamp ?? null,
      centerId: center.id,
      treatments: treatments.map(item => item.id),
      workerId: hour?.worker.id ?? "",
    })
      .then(() => {
        onSuccess()
        enqueueSnackbar("TU CITA HA SIDO CONFIRMADA", { variant: "success" })
        queryClient.invalidateQueries(["day-bookings"])
      })
      .catch(error => {
        setHour(null)
        queryClient.invalidateQueries(["public-db"])
        queryClient.invalidateQueries(["day-bookings"])
        enqueueSnackbar("UN ERROR HA OCURRIDO", { variant: "error" })
        fetch("/api/client/error", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userAgent: window.navigator.userAgent,
            pathname: window.location.pathname,
            error_name: `BOOKING FOR ${treatments.map(i => i.label).join(",")}`,
            error_message: error.toString(),
          }),
        })
      })
  }

  const buttonText = isLoading ? "CONFIRMANDO ..." : text ?? "CONFIRMA TU CITA"
  return (
    <Box component="div">
      <Dialog />
      <BookButtonStyled
        disabled={!hour || isLoading || !db.settings.bookingOnlineEnabled}
        variant="contained"
        onClick={async () => {
          openDialog(`¿Estas reservando en?`, center.address).then(() => {
            if (user.logged) {
              book()
            } else {
              setOpen(true)
            }
          })
        }}
      >
        <Typography>
          {db.settings.bookingOnlineEnabled ? buttonText : "RESERVA ONLINE DESACTIVADA"}
        </Typography>
      </BookButtonStyled>
      <GenericDialog title="" open={open} onClose={() => setOpen(false)}>
        <UserLoginStepper
          description="Utilizaremos este correo para enviarte la confirmacion de la cita."
          onClose={async () => {
            setOpen(false)
            await book()
          }}
        />
      </GenericDialog>
    </Box>
  )
}

export { BookingConfirm }
