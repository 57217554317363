import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { useDeviceInfo, useSearchResults } from "@in-and-out-belleza/hooks"
import { BuyButton } from "../Buttons/BuyButton"

const BonusCards = () => {
  const results = useSearchResults({ include: ["bonusCards"] })
  const { isMobile } = useDeviceInfo()

  return (
    <Grid container spacing={0.2}>
      {results.map(item => (
        <Grid item xs={12} sm={6} key={item.id}>
          <Paper key={item.id} sx={{ m: 1, position: "relative", display: "block", height: "98%" }}>
            <Box component="div"
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: isMobile ? undefined : "200px",
              }}
            >
              <Box component="div">
                <Typography variant="h6">{item.label}</Typography>
                <Divider />
                <Typography
                  sx={{ pt: 1, textAlign: "justify", "& img": { width: "100%" } }}
                  fontSize={13}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </Box>
              <Box component="div" sx={{ display: "flex", marginTop: "auto" }}>
                <Button component={Link} to={item.href} sx={{ flexGrow: 1 }}>
                  VER TRATAMIENTOS
                </Button>
                <BuyButton cart={[item.id]} variant="contained" sx={{ margin: 0.5 }} />
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export { BonusCards }
