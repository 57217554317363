import { Middleware } from "../../utils/Middleware"

const WindowHistoryListener = () => {
  const _pushState = window.history.pushState
  const _replaceState = window.history.replaceState
  const _go = window.history.go
  const _back = window.history.back
  const _forward = window.history.forward

  const middleware = new Middleware<
    [string | number | undefined, string | undefined, string | URL | null | undefined]
  >()

  const callMd = middleware.use(function (next, key, value, context) {
    next(key, value, context)
    return [key, value, context]
  })

  window.history.pushState = function (...args) {
    callMd(...args)
    _pushState.call(this, ...args)
  }

  window.history.replaceState = function (...args) {
    callMd(...args)
    _replaceState.call(this, ...args)
  }

  window.history.go = function (...args) {
    callMd(...args, undefined, undefined)
    _go.call(this, ...args)
  }

  window.history.back = function () {
    callMd(undefined, undefined, undefined)
    _back.call(this)
  }

  window.history.forward = function () {
    callMd(undefined, undefined, undefined)
    _forward.call(this)
  }

  return middleware
}

export { WindowHistoryListener }
