import { Box, Button, styled } from "@mui/material"

const Form = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
}))

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

const Wrapper = styled(Box)(({ theme }) => ({
  padding: "30px",
  maxWidth: "450px",
  margin: "0 auto",
  "& svg": {
    fill: theme.palette.primary.main,
  },
  "& a": {
    padding: "10px",
    margin: "10px 0",
  },
}))

export { Form, SubmitButton, Wrapper }
