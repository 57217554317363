import { Button, Paper, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { useNav } from "../../hooks/useNav"

const GiftPdfPreview = () => {
  const { gift } = useNav()
  return (
    <Paper sx={{ p: 1, textAlign: "center" }}>
      <Typography variant="h3" color="primary">
        ¿ESTAS BUSCANDO UN REGALO DE BELLEZA EN MALAGA?
      </Typography>
      <Typography sx={{ my: 2 }}>
        ¡Elige los tratamientos que quieres y consigue un regalo personalizado!
      </Typography>
      <Button component={Link} variant="contained" to={gift.url()}>
        CONSIGUELO AQUI
      </Button>
    </Paper>
  )
}

export { GiftPdfPreview }
