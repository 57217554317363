import { ImageList, ImageListItem } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { InfiniteList } from "@in-and-out-belleza/ui"

const Photos = () => {
  const { db } = usePublicDb()

  return (
    <ImageList variant="woven" sx={{ margin: 0 }}>
      <InfiniteList
        items={db.photos.map(i => ({ ...i, id: i.url }))}
        renderItem={item => {
          return (
            <ImageListItem>
              <img
                src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                alt={item.name}
                loading="lazy"
              />
            </ImageListItem>
          )
        }}
      />
    </ImageList>
  )
}

export { Photos }
