import SvgIcon from "@mui/icons-material/Favorite"
import Rating, { RatingProps } from "@mui/material/Rating"
import { styled } from "@mui/material/styles"

import { ReactComponent as SvgLogo } from "../../../assets/apple.svg"

const StyledRating = styled(Rating)(({ theme }) => {
  return {
    "& .MuiRating-iconFilled": {
      color: theme.palette.primary.main,
    },
    "& .MuiRating-iconHover": {
      color: theme.palette.primary.dark,
    },
  }
})

const AppleRating = (props: RatingProps) => {
  return (
    <StyledRating
      precision={0.1}
      icon={<SvgIcon component={SvgLogo} inheritViewBox />}
      emptyIcon={<SvgIcon component={SvgLogo} inheritViewBox />}
      {...props}
    />
  )
}

export { AppleRating }
