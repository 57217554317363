import { Box, Button, Paper, Typography } from "@mui/material"
import { useTreatmentsHours } from "../../queries/get-booking-hours"
import { dateUtils } from "@in-and-out-belleza/utils"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useNav } from "../../hooks/useNav"
import { useBooking } from "../../atoms/atomBooking"
import { Treatment } from "@in-and-out-belleza/api/interfaces"
import { getTreatmentsDuration } from "@in-and-out-belleza/api/utils"
import { mapToTreatmentResult } from "../../queries/treatments-results"
import { BookingConfirm } from "../Booking/BookingConfirm"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { useEffect, useState } from "react"
import { Splash } from "@in-and-out-belleza/ui"

function getCenters(
  data: Array<{ centerIndex: number; timestamp: number; duration: number; workerIndex: number }>,
) {
  return data
    .map(i => i.centerIndex)
    .filter((o, i, a) => a.indexOf(o) === i)
    .sort()
}

const NextAvailableBookings = ({ ids }: { ids: Array<Treatment> }) => {
  const [centerIndex, setCenterIndex] = useState<number | null>(null)
  const { db } = usePublicDb()
  const { data, isLoading } = useTreatmentsHours(ids.map(i => i.id))
  const { booking } = useNav()
  const { setTreatments, setHour } = useBooking()
  const { isMobile } = useDeviceInfo()

  useEffect(() => {
    const centers = getCenters(data)
    if (centers.length) {
      setCenterIndex(prev => {
        if (prev && centers.includes(prev)) return prev
        return centers[0]
      })
    }
  }, [data])

  if (isLoading) return <Splash />

  return (
    <Box component="div">
      {data.length ? (
        <Typography fontSize={22} sx={{ mb: 1 }}>
          LAS CITAS MAS PROXIMAS:
        </Typography>
      ) : null}
      {data.length ? (
        <Box component="div" sx={{ textAlign: "center" }}>
          {db.centers.map(item => (
            <Button
              onClick={() => {
                setCenterIndex(item.index)
                setHour(null)
              }}
              disabled={!getCenters(data).includes(item.index)}
              variant={item.index === centerIndex ? "contained" : "outlined"}
              key={item.id}
            >
              {item.label}
            </Button>
          ))}
        </Box>
      ) : null}

      <Box component="div">
        {data
          .filter(item => item.centerIndex === centerIndex)
          .splice(0, 5)
          .map(item => {
            const center = db.centers.find(c => c.index === item.centerIndex)
            const worker = db.workers.find(w => w.index === item.workerIndex)
            const duration = getTreatmentsDuration(
              db,
              ids.map(i => i.id),
              worker?.id ?? "",
            )
            if (!worker || !center) return null
            const hour = { duration, timestamp: item.timestamp, worker }
            return (
              <Paper sx={{ m: 1, p: 1, display: "flex" }} key={item.timestamp}>
                <BookingConfirm
                  text="CONFIRMA"
                  hour={hour}
                  center={center}
                  treatments={ids.map(mapToTreatmentResult)}
                  onSuccess={() => {
                    setHour(null)
                    setTreatments([])
                    booking.navigate()
                  }}
                />
                <Box component="div" sx={{ flexGrow: 1, ml: 1 }}>
                  <Typography sx={{ fontSize: 12, color: "#888" }}>{center?.address}</Typography>
                  <Box component="div" sx={{ display: "flex", fontSize: isMobile ? 14 : 18, alignItems: "center" }}>
                    <Box component="div" flexGrow={1}>{dateUtils.toLongDate(item.timestamp)}</Box>
                    <Typography>{dateUtils.toShortHour(item.timestamp)}</Typography>
                  </Box>
                </Box>
              </Paper>
            )
          })}
      </Box>
    </Box>
  )
}

export { NextAvailableBookings }
