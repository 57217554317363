import { useLocation } from "react-router-dom"

import { Box, Divider, Paper, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDate } from "@in-and-out-belleza/hooks"
import { BookButton } from "../Buttons/BookButton"
import { BuyButton } from "../Buttons/BuyButton"
import { NotFoundPage } from "../NotFoundPage"

const NewsDetail = () => {
  const { db } = usePublicDb()
  const { longDate } = useDate()
  const { pathname } = useLocation()
  const item = db.news.find(sub => sub.href === pathname)

  if (!item) return <NotFoundPage />

  return (
    <Paper sx={{ margin: "8px", padding: 1 }}>
      <Typography color="primary" fontSize={24}>
        {item.title}
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Typography variant="caption">{longDate(item.date)}</Typography>
      <Divider sx={{ mt: 1, mb: 1 }} />
      {item.photo ? (
        <Box component="div" sx={{ textAlign: "center" }}>
          <img
            style={{ maxHeight: "500px", maxWidth: "100%" }}
            src={item.photo}
            alt={item.title}
            loading="lazy"
          />
          <Divider />
        </Box>
      ) : null}

      <Box component="div" sx={{ display: "flex" }}>
        <BookButton cart={item.cart} variant="contained" sx={{ margin: 0.5 }} />
        <Box component="div" sx={{ flexGrow: 1, justifyContent: "end", display: "flex" }}>
          <BuyButton cart={item.cart} variant="contained" sx={{ margin: 0.5 }} />
        </Box>
      </Box>
      <Box component="div" sx={{ pt: 2 }} dangerouslySetInnerHTML={{ __html: item.description }} />
    </Paper>
  )
}

export { NewsDetail }
