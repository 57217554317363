import { CarouselProvider, Slide, Slider } from "pure-react-carousel"

import { Box, Card, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BuyButton } from "../../Buttons/BuyButton"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"

const CarouselProducts = () => {
  const { db } = usePublicDb()
  const { isMobile } = useDeviceInfo()
  const products = db.products.filter(item => item.position === 1 && item.photo)
  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={products.length}
      infinite
      isIntrinsicHeight
      isPlaying
      interval={7000}
      visibleSlides={isMobile ? 1 : 3}
    >
      <Slider>
        {products.map((product, index) => {
          return (
            <Slide key={product.id + index} index={index} style={{ margin: "0 2px" }}>
              <Card
                sx={{
                  height: "160px",
                  p: 1,
                }}
              >
                <Box
                  component="img"
                  src={product.photo}
                  sx={{
                    position: "absolute",
                    maxWidth: "148px",
                    height: "148px",
                    bottom: "8px",
                  }}
                />
                <Typography
                  sx={{ position: "absolute", width: "196px", right: " 8px" }}
                  color="primary.main"
                >
                  {product.title}
                </Typography>
                <BuyButton
                  cart={[product.id]}
                  variant="contained"
                  sx={{ position: "absolute", bottom: "8px", right: "8px" }}
                />
              </Card>
            </Slide>
          )
        })}
      </Slider>
    </CarouselProvider>
  )
}

export { CarouselProducts }
