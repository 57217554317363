import { PropsWithChildren, useState } from "react"
import { Box, SxProps } from "@mui/material"
import { useElementSize } from "@in-and-out-belleza/hooks"

type Props = PropsWithChildren & {
  margin?: number
  duration?: number
  minHeight?: number
  id?: string
  sx?: SxProps
}

const AutoAnimateHeight = ({
  children,
  duration = 0.3,
  margin = 0,
  minHeight = 0,
  id,
  sx,
}: Props) => {
  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
  const { height } = useElementSize(wrapper)

  return (
    <Box
      component="div"
      sx={{
        height: Math.max(height + margin, minHeight),
        transition: `height ${duration}s`,
        overflow: "hidden",
        ...sx,
      }}
      id={id}
    >
      <div ref={setWrapper}>{children}</div>
    </Box>
  )
}

export { AutoAnimateHeight }
