import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { ArrowBack } from "@mui/icons-material"
import { Box } from "@mui/material"
import { useNav } from "../../hooks/useNav"
import { useBooking } from "../../atoms/atomBooking"

const BackButton = () => {
  const { width } = useDeviceInfo()
  const { back, home, booking } = useNav()
  const { treatments, setTreatments } = useBooking()
  if (width < 920) return null
  if (home.isPath()) return null
  return (
    <Box
      component="div"
      sx={{
        position: "fixed",
        left: `${(width - 800) / 2 - 60}px`,
        top: 70,
        width: 50,
        height: 50,
        color: "#black",
        borderRadius: 20,
        p: 1.4,
        cursor: "pointer",
      }}
    >
      <ArrowBack
        onClick={() => {
          if (booking.isPath() && treatments.length) {
            setTreatments([])
            return
          }
          back()
        }}
      />
    </Box>
  )
}

export { BackButton }
