import { styled } from "@mui/material"

export const ErrorPageStyled = styled("div")(() => ({
  width: "85%",
  margin: "0",
  paddingLeft: "15%",
  color: "white",
  "& h2": {
    paddingTop: "140px",
    paddingBottom: "20px",
  },
  "& h6": {
    fontSize: 14,
    paddingTop: "80px",
    paddingRight: "20px",
  },
}))
