import { forwardRef, useState } from "react"
import { useAtom } from "jotai"
import {
  AdsClick,
  AspectRatio,
  Delete,
  Dvr,
  Edit,
  Height,
  Keyboard,
  LeakAdd,
  OpenInBrowser,
  Storage,
} from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { sortFlowEventsByStart } from "../../api/api"
import { _flowEvents } from "../../atoms/state"
import { FlowEvent } from "../../types/flow.types"
import { FlowModalEdit } from "../FlowModals/FlowModalEdit"
import { FlowModalJsonViewer } from "../FlowModals/FlowModalJsonViewer"

const icons = {
  "browser-location": () => <OpenInBrowser />,
  "local-storage": () => <Storage />,
  "user-keypress": () => <Keyboard />,
  "user-resize": () => <AspectRatio />,
  "browser-init": () => <AspectRatio />,
  "user-typing": () => <Keyboard />,
  "user-click": () => <AdsClick />,
  "user-scroll": () => <Height />,
  "http-request": () => <LeakAdd />,
  "ws-request": () => <LeakAdd />,
  "document-element": () => <Dvr />,
}

type Props = {
  onDelete: (index: number) => void
  onEdit: (index: number, description: string) => void
}

const FlowList = forwardRef<HTMLDivElement, Props>(({ onDelete, onEdit }, ref) => {
  const [events] = useAtom(_flowEvents)
  const [open, setOpen] = useState<number | null>(null)
  const [openJson, setOpenJson] = useState<FlowEvent | null>(null)
  return (
    <Box component="div" ref={ref} style={{ overflowY: "scroll", height: "100%" }}>
      {events.sort(sortFlowEventsByStart).map((event, index) => {
        return (
          <Box
            component="div"
            onClick={() => setOpenJson(event)}
            key={index}
            sx={theme => ({
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "pointer",
              "&:hover": {
                background: "#00000022",
              },
              "& button": {
                height: 14,
                width: 14,
                px: 1.4,
              },
              "& > p > svg": {
                fontSize: 14,
                paddingTop: "1px",
                mx: 0.5,
                fill: theme.palette.primary.main,
              },
            })}
          >
            <Typography sx={{ lineHeight: "20px", pl: 1 }}>
              {(index + 1).toString().padStart(2, "0")}
              <IconButton
                sx={{ ml: 0.5 }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onDelete(index)
                }}
              >
                <Delete color="error" sx={{ fontSize: 18 }} />
              </IconButton>
              <IconButton
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setOpen(index)
                }}
              >
                <Edit color="action" sx={{ fontSize: 18 }} />
              </IconButton>
              {icons[event.type]()}
              {event.description}
            </Typography>
          </Box>
        )
      })}
      <FlowModalEdit onEdit={onEdit} open={open} setOpen={setOpen} />
      <FlowModalJsonViewer open={openJson} setOpen={setOpenJson} />
    </Box>
  )
})

export { FlowList }
