import { Delete } from "@mui/icons-material"
import { Alert, Box, Button, IconButton, Paper, Typography } from "@mui/material"
import { useState } from "react"
import { FileUploader } from "react-drag-drop-files"

const IOname = "In&Out Belleza"

const WorkWithUs = () => {
  const [files, setFiles] = useState<Array<File>>([])
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const handleChange = (files: Array<File>) => {
    setFiles(prev => prev.concat(Array.from(files)))
  }

  return (
    <Box component="div" sx={{ maxWidth: 500, m: "0 auto", px: 1, "& p": { py: 1 } }}>
      <Typography variant="h6" sx={{ textAlign: "center", py: 1 }}>
        TRABAJA CON NOSOTROS
      </Typography>
      <Typography>
        Únete a nuestro equipo de belleza en {IOname} y sé parte de una experiencia en el mundo de
        la estética.
      </Typography>
      <Typography>
        ¿Quiénes buscamos? Apasionados por la belleza y el bienestar. Si tienes experiencia en
        estética o campos relacionados ¡te invitamos a unirte!
      </Typography>
      <Typography>
        Envía tu currículum y carta de presentación y únete a nuestra familia de profesionales que
        celebran la belleza en todas sus formas en {IOname}. ¡Esperamos conocerte pronto!
      </Typography>
      <Paper
        sx={{
          p: 1,
          my: 2,
          "& .drop_area": { borderColor: "green", minWidth: "100px" },
          "& .drop_area svg path": { fill: "green" },
        }}
      >
        <FileUploader
          classes="drop_area"
          label="Carga o suelta tu currículum y carta de presentación"
          multiple
          handleChange={handleChange}
          name="file"
          types={["PDF", "DOC", "DOCX"]}
          fileOrFiles={files.length ? files : null}
        />

        {files.map((file, index) => {
          return (
            <Box component="div" key={`${file.name}-${index}`} sx={{ display: "flex" }}>
              <IconButton
                onClick={() => {
                  setFiles(prev => {
                    prev.splice(prev.indexOf(file), 1)
                    return [...prev]
                  })
                }}
              >
                <Delete />
              </IconButton>
              <Typography sx={{ flexGrow: 1, mt: 1 }}>{file.name}</Typography>
            </Box>
          )
        })}

        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 1 }}
          disabled={!files.length || loading}
          onClick={() => {
            setLoading(true)
            const formData = new FormData()
            files.forEach(file => {
              formData.append("fileUpload", file, file.name)
            })
            fetch("/api/cv/updload", {
              method: "post",
              body: formData,
            })
              .then(() => {
                setFiles([])
                setSent(true)
              })
              .finally(() => {
                setLoading(false)
              })
          }}
        >
          ENVIAR
        </Button>
        {sent ? <Alert sx={{ mt: 2 }}>Gracias. Nos pondremos en contacto contigo.</Alert> : null}
      </Paper>
    </Box>
  )
}

export { WorkWithUs }
