import { Component, ErrorInfo, ReactNode } from "react"
import { ErrorPage } from "./ErrorPage"

type Props = {
  children: ReactNode
  ErrorPage?: ReactNode
  onError?: (error: Error, errorInfo: ErrorInfo) => void
}

type State = {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public override state: State = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onError) this.props.onError(error, errorInfo)
  }

  public override render() {
    if (this.state.hasError) {
      return this.props.ErrorPage || <ErrorPage />
    }

    return this.props.children
  }
}

export { ErrorBoundary }
