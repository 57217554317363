import { Box, Button, Paper, Typography } from "@mui/material"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useDate } from "@in-and-out-belleza/hooks"
import { useAtom } from "jotai"
import { _lastOrderAmount } from "../../atoms/atomLastAnonymousOrder"
import { Link } from "react-router-dom"
import { useNav } from "../../hooks/useNav"
import { ClientOrderDetail } from "../User/ClientOrderDetail"

const EmptyCart = () => {
  const { user } = useAuth()
  const { shortDate } = useDate()
  const { shop } = useNav()
  const [order] = useAtom(_lastOrderAmount)
  const [userLastOrder] = user.orders.sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
  )
  const lastOrder = order ?? userLastOrder

  return (
    <Box component="div" sx={{ p: 1 }}>
      {lastOrder ? (
        <Paper sx={{ p: 1 }}>
          {order ? (
            <Typography sx={{ mt: 1, fontSize: 24, color: "primary.main" }}>
              GRACIAS POR TU COMPRA!
            </Typography>
          ) : (
            <Typography>Ultima compra online: ({shortDate(lastOrder.created)})</Typography>
          )}

          <Typography fontSize={12}>Pagado: {(lastOrder.amount / 100).toFixed(2)} EURO</Typography>
          <ClientOrderDetail order={lastOrder} />
        </Paper>
      ) : null}
      <Box component="div" sx={{ mb: 1 }}>
        {" "}
      </Box>
      <Paper sx={{ p: 1 }}>
        <Box component="div" sx={{ pt: 6, maxWidth: "400px", margin: "0 auto", p: 3 }}>
          <img src="/assets/empty-cart.png" alt="carrito vacio" width={"100%"} />
        </Box>
        <Typography sx={{ textAlign: "center", fontSize: 24, mt: 2 }}>
          TU CARRITO ESTA VACIO
        </Typography>
        <Box component="div" sx={{ my: 2, display: "flex", justifyContent: "center" }}>
          <Button component={Link} to={shop.url()} variant="contained" onClick={() => {}}>
            IR A LA TIENDA ONLINE
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export { EmptyCart }
