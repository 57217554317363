import { Callback, Context, Next, Stack } from "./Stack"

class Middleware<T extends Array<unknown>> {
  private _autoProceedToAfter: boolean
  private _beforeFn: Array<Callback<T, Context>>
  private _afterFn: Array<Callback<T, Context>>

  constructor({ autoProceedToAfter = false } = {}) {
    this._autoProceedToAfter = autoProceedToAfter
    this._beforeFn = []
    this._afterFn = []
  }

  before(callback: Callback<T, Context>) {
    this._beforeFn.push(callback)
    return () => this._beforeFn.splice(this._beforeFn.indexOf(callback), 1)
  }
  after(callback: Callback<T, Context>) {
    this._afterFn.push(callback)
    return () => this._afterFn.splice(this._afterFn.indexOf(callback), 1)
  }
  use(callback = (next: Next<T>, ...args: T): T => args, context?: Context) {
    return (...args: T) => {
      let result: T | undefined
      const stack = new Stack<T, Context>(context ?? {})
      stack.add(function (next, ..._) {
        next(...args)
      })
      this._beforeFn.forEach(cb => stack.add(cb))
      stack.add((next, ...innerArgs) => {
        result = callback.call(context, next, ...innerArgs)
        this._autoProceedToAfter && next(...result)
      })
      this._afterFn.forEach(cb => stack.add(cb))
      stack.run()
      return result?.[0]
    }
  }
}

export { Middleware }
