import { useAtom } from "jotai"
import { Link } from "react-router-dom"
import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import { InfiniteList } from "@in-and-out-belleza/ui"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDate, useDeviceInfo } from "@in-and-out-belleza/hooks"
import { _news } from "../../atoms/news"
import { useEffect } from "react"

const News = () => {
  const { isMobile } = useDeviceInfo()
  const { db } = usePublicDb()
  const { longDate } = useDate()
  const [_, setNews] = useAtom(_news)
  useEffect(() => {
    setNews(db.news.map(item => item.id))
  }, [db.news, setNews])

  return (
    <InfiniteList
      items={db.news}
      renderItem={item => {
        return (
          <Paper key={item.id} sx={{ margin: "8px", padding: 1 }}>
            <Typography color="primary" fontSize={30}>
              {item.title}
            </Typography>
            <Divider />
            {item.photo ? (
              <Box component="div"
                sx={{
                  background: `url(${item.photo})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  height: isMobile ? "250px" : "350px",
                }}
              ></Box>
            ) : null}
            <Divider />
            <Typography variant="caption">{longDate(item.date)}</Typography>
            <br />
            <Button component={Link} to={item.href}>
              LEER EL ARTICULO
            </Button>
          </Paper>
        )
      }}
    />
  )
}

export { News }
