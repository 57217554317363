import { useEffect, useState } from "react"
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  styled,
  SwipeableDrawer,
  Typography,
} from "@mui/material"

import { useAuth } from "@in-and-out-belleza/authentication"
import { useDate } from "@in-and-out-belleza/hooks"
import { Logo, useAcceptDialog, useConfirmDialog } from "@in-and-out-belleza/ui"
import { BonusCardDetailMobile } from "./BonusCardDetailMobile"
import { ArrowForwardIos, Delete } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { useNav } from "../../hooks/useNav"
import { AdminClientNotifications } from "@in-and-out-belleza/api/interfaces"
import { useSnackbar } from "notistack"

const BoxStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(),
  "& .MuiPaper-root": {
    padding: theme.spacing(1.5),
    "& > span": {
      float: "right",
    },
    "& div:nth-of-type(1) svg": {
      fill: theme.palette.primary.main,
    },
  },
  "& hr": {
    margin: "10px",
  },
}))

const DrawerStyled = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: theme.spacing(1.5),
    marginTop: "10px",
  },
  "& > .MuiPaper-root": {
    minHeight: `${window.screen.availHeight - 150}px`,
    margin: "0 5px",
    borderRadius: "10px 10px 0 0",
    padding: theme.spacing(1.5),
  },
}))

const UserMobile = () => {
  const { logout, user, deleteUser, resfreshStatus } = useAuth()
  const [openBonus, setOpenBonus] = useState<string | undefined>()
  const [promotions, setPromotions] = useState<AdminClientNotifications["promotions"]>(
    user.notifications.promotions,
  )
  const [bookReminder, setBookReminder] = useState<AdminClientNotifications["bookReminder"]>(
    user.notifications.bookReminder,
  )
  const { shortDate } = useDate()
  const { accountOnlineBuy, accountBooking } = useNav()
  const { openDialog, Dialog } = useConfirmDialog()
  const { openDialog: openAcceptDialog, Dialog: AcceptDialog } = useAcceptDialog()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setOpenBonus(prev => {
      if (!user.bonusCards.find(item => item._id === prev)) {
        return undefined
      }
      return prev
    })
  }, [user.bonusCards])

  return (
    <BoxStyled>
      <Paper sx={{ display: "flex", padding: "0 !important", position: "relative" }}>
        <Box component="div" sx={{ p: 1, pb: 0 }}>
          <img
            width={132}
            height={132}
            src={`/api/qr-code/${user.id}`}
            loading="lazy"
            alt="QR USUARIO"
          />
        </Box>
        <Box component="div" sx={{ p: "12px 0", width: "100%" }}>
          <Logo size={30} />
          <Box component="div" sx={{ mt: 1 }} />
          {/* <Typography>CREDITO: {bonusCardsCredit.toFixed(2)} Euro</Typography> */}
          <Typography sx={{ mt: 2 }} fontSize={13}>
            Hola {user.name}!
          </Typography>
        </Box>
        <Button
          onClick={() => {
            const hasFustureBookings = user.bookings.some(book => book.start > Date.now())
            if (hasFustureBookings) {
              openAcceptDialog(
                "INFORMACION:",
                "Tienes citas pendientes. No puedes eliminar tu cuenta.",
              )
            } else {
              openDialog(
                "ELIMINA TU CUENTA:",
                "Estas seguro que quieres eliminar to cuenta? Todas tus informaciones seran eliminada de In&Out.",
              ).then(() => {
                deleteUser.mutate({ email: user.email, password: "" })
              })
            }
          }}
          color="error"
          variant="contained"
          size="small"
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <Delete />
        </Button>
      </Paper>

      <Paper sx={{ mt: 1 }}>
        <span>
          {user.orders.length ? (
            <IconButton component={Link} to={accountOnlineBuy.url()}>
              <ArrowForwardIos />
            </IconButton>
          ) : null}
        </span>
        <Typography color="primary" component="strong">
          TUS COMPRAS ONLINE: ({user.orders.length})
        </Typography>
        <br />
        <Typography fontSize={14} component="em">
          {user.orders.length ? (
            <div>Tu ultima compra: {shortDate(user.orders[user.orders.length - 1].created)}</div>
          ) : (
            <div>Ninguna compra en nuestra tienda online</div>
          )}
        </Typography>
      </Paper>
      <Paper sx={{ mt: 1 }}>
        <span>
          {user.bookings.length ? (
            <IconButton component={Link} to={accountBooking.url()}>
              <ArrowForwardIos />
            </IconButton>
          ) : null}
        </span>
        <Typography color="primary" component="strong">
          TU HISTORICO DE CITAS: ({user.bookings.length})
        </Typography>
        <br />
        <Typography fontSize={14} component="em">
          {user.bookings.length ? (
            <div>Tu ultima cita: {shortDate(user.bookings[user.bookings.length - 1].start)}</div>
          ) : (
            <div>Ninguna cita registrada en nuestros centros</div>
          )}
        </Typography>
      </Paper>
      <Typography sx={{ mt: 1 }}>COMUNICACIONES:</Typography>

      <Paper sx={{ p: 1, borderRadius: "6px" }}>
        <Box component="div" sx={{ display: "flex", mb: 1 }}>
          <Typography sx={{ ml: 1, flexGrow: 1, alignSelf: "center", color: "primary.main" }}>
            Recordatorios citas
          </Typography>
          <Select
            sx={{ ml: 1, minWidth: 130 }}
            size="small"
            value={bookReminder}
            onChange={ev => {
              const value = ev.target.value as AdminClientNotifications["bookReminder"]
              setBookReminder(value)
              const notifications: AdminClientNotifications = {
                ...user.notifications,
                bookReminder: value,
              }
              fetch("/api/login/update-user-info", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ notifications }),
              })
                .then(() => {
                  enqueueSnackbar("Hemos guardado tu preferencia", { variant: "success" })
                })
                .then(resfreshStatus)
            }}
          >
            <MenuItem value={"email"}>EMAIL</MenuItem>
            {/* <MenuItem value={"push"}>MOVIL</MenuItem> */}
          </Select>
        </Box>
        <Box component="div" sx={{ display: "flex", mb: 1 }}>
          <Typography sx={{ ml: 1, flexGrow: 1, alignSelf: "center", color: "primary.main" }}>
            Promociones
          </Typography>
          <Select<AdminClientNotifications["promotions"]>
            sx={{ ml: 1, minWidth: 130 }}
            size="small"
            value={promotions}
            onChange={ev => {
              const value = ev.target.value as AdminClientNotifications["promotions"]
              setPromotions(value)
              const notifications: AdminClientNotifications = {
                ...user.notifications,
                promotions: value,
              }
              fetch("/api/login/update-user-info", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ notifications }),
              })
                .then(() => {
                  enqueueSnackbar("Hemos guardado tu preferencia", { variant: "success" })
                })
                .then(resfreshStatus)
            }}
          >
            <MenuItem value={"none"}>NO QUIERO</MenuItem>
            <MenuItem value={"email"}>EMAIL</MenuItem>
            {/* <MenuItem value={"push"}>MOVIL</MenuItem> */}
          </Select>
        </Box>
      </Paper>
      <Divider />
      <Button size="large" fullWidth variant="contained" onClick={() => logout.mutate()}>
        SALIR DE TU CUENTA
      </Button>
      <br />
      <br />
      <DrawerStyled
        anchor="bottom"
        open={!!openBonus}
        onClose={() => setOpenBonus(undefined)}
        onOpen={() => {}}
      >
        <BonusCardDetailMobile id={openBonus} />
      </DrawerStyled>
      <Dialog />
      <AcceptDialog />
    </BoxStyled>
  )
}

export { UserMobile }
