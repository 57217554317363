import { useState } from "react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"

function useConfirmDialog() {
  const [resolve, setResolve] = useState<(() => void) | null>(null)
  const [open, isOpen] = useState(false)
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")

  const handleClose = (accepted: boolean) => {
    isOpen(false)
    if (accepted && resolve) {
      resolve()
    }
    setResolve(null)
  }

  const openDialog = (title: string, text: string) => {
    isOpen(true)
    setTitle(title)
    setText(text)
    return new Promise<void>(res => {
      setResolve(() => () => res())
    })
  }

  return {
    Dialog: () => (
      <Dialog
        sx={{ ".MuiDialog-paper": { minWidth: 300, pr: 2 } }}
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Button onClick={() => handleClose(true)} variant="contained">
            Si
          </Button>
          <Button onClick={() => handleClose(false)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    ),
    openDialog,
  }
}

export { useConfirmDialog }
