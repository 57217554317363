import axios from "axios"

const getApiClient = (baseURL?: string) => {
  const client = axios.create({
    withCredentials: true,
    baseURL,
  })

  return client
}

export { getApiClient }
