import { Treatment } from "@in-and-out-belleza/api/interfaces"
import { useMemo } from "react"

import { usePublicDb } from "@in-and-out-belleza/api/resources"

type TreatmentsSearchItem = {
  id: `TRT-${number}`
  type: "treatments"
  icon: string
  label: string
  description: string
  priority: number
  href: string
  group: string
}

const isSearchTreatment = (trt: TreatmentsSearchItem | string): trt is TreatmentsSearchItem => {
  return typeof trt !== "string" && "id" in trt
}

const trtLabels: Record<string, string> = {
  Faciales: "Tratamientos Faciales",
  Corporales: "Tratamientos Corporales",
}

function mapToTreatmentResult(item: Treatment): TreatmentsSearchItem {
  return {
    type: "treatments" as const,
    group: trtLabels[item.type] ?? item.type,
    icon: "treatments",
    label: `${item.type}: ${item.title}`,
    id: item.id,
    priority: 2,
    href: item.href,
    description: item.description.replace(/(<([^>]+)>)/gi, ""),
  }
}

const useTreatmentsResults = (): Array<TreatmentsSearchItem> => {
  const { db } = usePublicDb()
  return useMemo(() => {
    return [
      ...db.treatments.filter(item => item.canBookOnline).map(item => mapToTreatmentResult(item)),
    ].sort((first, second) => {
      if (first.priority !== second.priority) {
        return first.priority - second.priority
      }
      if (first.group !== second.group) {
        return first.group.localeCompare(second.group)
      }
      return db.cartPriority.indexOf(second.id) - db.cartPriority.indexOf(first.id)
    })
  }, [db])
}

export { useTreatmentsResults, isSearchTreatment, mapToTreatmentResult }
export type { TreatmentsSearchItem }
