import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { useSearchResults } from "@in-and-out-belleza/hooks"
import { BeautyIcon, Logo } from "@in-and-out-belleza/ui"
import { Box, Divider, Grid, Paper, Typography } from "@mui/material"
import { useLocation } from "react-router-dom"
import { useNav } from "../../hooks/useNav"
import { CarouselTreatments } from "../Content/Carousel/Treatments"
import { CarouselProducts } from "../Content/Carousel/Products"
import { CarouselPromotions } from "../Content/Carousel/Promotions"
import { CarouselBeautyParties } from "../Content/Carousel/BeautyParties"

type Props = {
  carousel?: "treatments" | "promotions" | "products" | "beautyParties"
}

const NotFoundPage = ({ carousel = "promotions" }: Props) => {
  const { navigate } = useNav()
  const { db } = usePublicDb()
  const location = useLocation()
  const paths = location.pathname.split("/").filter(item => item !== "es" && item !== "")
  const search = useSearchResults({
    include: ["treatments", "products", "bonusCards"],
  })

  const words = paths.map(path => path.split("-")).flat()
  const description = search.filter(item => {
    return words.filter(word => item.description.toLowerCase().includes(word)).length > 1
  })

  const label = search.filter(item => {
    if (description.find(i => i.id === item.id)) return false
    return words.filter(word => item.label.toLowerCase().includes(word)).length > 0
  })

  const toShow = [...description, ...label].slice(0, 12)

  return (
    <Box component="div" sx={{ py: 2 }}>
      <Box component="div" sx={{ mx: "auto", width: "200px" }}>
        <Logo size={50} sx={{ mb: 2 }} />
      </Box>
      <Divider />
      <Typography sx={{ textAlign: "center", fontSize: 20, color: "primary.main" }}>
        Lo sentimos ... esta pagina ya no existe!
      </Typography>
      <Divider />
      <Box component="div" sx={{ my: 2 }} />
      {carousel === "treatments" && <CarouselTreatments />}
      {carousel === "products" && <CarouselProducts />}
      {carousel === "promotions" && <CarouselPromotions />}
      {carousel === "beautyParties" && <CarouselBeautyParties />}

      {toShow.length ? (
        <Typography sx={{ textAlign: "center", mt: 1 }}>Podria enteresarte tambien:</Typography>
      ) : null}
      <Box component="div" sx={{ my: 2 }} />
      <Grid container spacing={2} sx={{ display: "flex", p: 1 }}>
        {toShow.map(item => {
          return (
            <Grid
              key={item.id}
              item
              xs={12}
              sm={4}
              onClick={() => {
                navigate(item.href)
              }}
              sx={{ alignItems: "stretch", display: "flex", cursor: "pointer" }}
            >
              <Paper sx={{ p: 1, width: "100%" }}>
                <Box component="div" sx={{ mr: 2 }}>
                  <BeautyIcon name={item.icon} size={16} />
                </Box>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", flexGrow: 2 }}>
                  <Typography fontSize={15}>{item.label}</Typography>
                  <Typography sx={{ color: "#888" }} fontSize={11}>
                    {getCartTotal(db, [item.id]).real.toFixed(2)} Euro
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          )
        })}
      </Grid>
      {!toShow.length ? (
        <Box component="div">
          <Typography>NUESTROS CENTROS:</Typography>
          {db.centers.map(center => {
            return (
              <Paper key={center.id} sx={{ my: 1, p: 1 }}>
                <Typography sx={{ fontSize: 20 }}>Centro belleza {center.label}</Typography>
                <Typography fontSize={16}>{center.address}</Typography>
                <Typography fontSize={14}>
                  TEL: {center.phone} - MOVIL: {center.mobile}
                </Typography>
              </Paper>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export { NotFoundPage }
