import { useInfiniteQuery } from "@tanstack/react-query"

import { getApiClient } from "@in-and-out-belleza/api/client"
import { AdminReview } from "@in-and-out-belleza/api/interfaces"

const client = getApiClient()
const fetchPage = async (page: number) => {
  const { data } = await client.get<Array<AdminReview>>(`/api/reviews/${page}`)
  return data
}

const oneDay = 24 * 60 * 60 * 1000

const useReviews = () => {
  return useInfiniteQuery({
    queryKey: ["reviews"],
    queryFn: ({ pageParam = 0 }) => fetchPage(pageParam),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length ? allPages.length : undefined
    },
    staleTime: oneDay,
    cacheTime: oneDay,
  })
}

export { useReviews }
