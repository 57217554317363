const intersectArray = (array1: Array<string>, array2: Array<string>) => {
  const clone2 = array2.slice(0)
  for (const item of array1) {
    if (clone2.includes(item)) {
      clone2.splice(clone2.indexOf(item), 1)
    }
  }
  if (clone2.length) return []
  return array2.slice(0)
}

export { intersectArray }
