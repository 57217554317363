import { Box, Typography } from "@mui/material"

const BeautyPartyIntro = () => {
  return (
    <Box component="div">
      <Typography sx={{ m: 1, p: 1 }}>
        Reúnete con tus amigas, compañeras de trabajo, familiares… y organizaros vuestra propia
        fiesta. Consejos, maquillaje, tratamientos de belleza, palomitas, bebidas, chuches… ¡y
        risas! ¿Te apetece?
      </Typography>
      <Typography sx={{ m: 1, p: 1 }}>
        Para organizar una Beauty Party sólo debes ponerte en contacto con In&Out, indicarnos la
        fecha en la que estáis interesadas, el número de personas (que deberá ser superior a 4) y el
        pack de tratamientos del que queréis disfrutar.
      </Typography>
      <Typography sx={{ m: 1, p: 1 }}>
        Durante las Beauty Parties, In&Out permanece cerrado al público, por lo que para fechas muy
        concretas se recomienda reservar con tiempo.
      </Typography>
    </Box>
  )
}

export { BeautyPartyIntro }
