import { useCallback, useEffect } from "react"
import { atom, useAtom } from "jotai"
import { FlowClientConfiguration } from "../types/flow.types"

const _config = atom<FlowClientConfiguration | null>(null)

let isFetching: Promise<FlowClientConfiguration> | null = null

const fetchConfig = async () => {
  isFetching = fetch("/flow/configuration").then(res => res.json())
  const data = isFetching
  isFetching = null
  return data
}

const useConfig = () => {
  const [config, setConfig] = useAtom(_config)
  useEffect(() => {
    if (config || isFetching) return
    fetchConfig().then(setConfig)
  })

  const update = useCallback(() => {
    if (isFetching) {
      isFetching.then(() => {
        fetchConfig().then(setConfig)
      })
    } else {
      fetchConfig().then(setConfig)
    }
  }, [setConfig])

  return { config, update }
}

export { useConfig }
