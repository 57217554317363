import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { dateUtils, xmlUtils } from "@in-and-out-belleza/utils"
import { Helmet } from "react-helmet"

type Props = {
  name: string
  description: string
  photo: string
  cart: Array<string>
  brand: string
  href: string
}
const MetatagProduct = ({
  name,
  description: htmlDescription,
  photo,
  cart,
  brand,
  href,
}: Props) => {
  const description = xmlUtils.stripHtmlTags(htmlDescription)
  const { db } = usePublicDb()
  const price = getCartTotal(db, cart)
  const data = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name,
    image: [`https://www.inandoutbelleza.es${photo}`],
    description,
    sku: "",
    mpn: "",
    brand: {
      "@type": "Brand",
      name: brand,
    },
    offers: {
      url: `https://www.inandoutbelleza.es${href}`,
      priceCurrency: "EUR",
      price: dateUtils.toFormatNumber(price.total),
      priceValidUntil: `${new Date().getFullYear()}-12-31`,
      itemCondition: "https://schema.org/UsedCondition",
      availability: "https://schema.org/InStock",
    },
  }
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(data)}</script>
    </Helmet>
  )
}

export { MetatagProduct }
