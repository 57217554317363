export function resetPWAandReload(cacheName = "") {
  navigator.serviceWorker
    .getRegistrations()
    .then(regs => Promise.all(regs.map(reg => reg.unregister())))
    .then(() =>
      caches.keys().then(async names => {
        for (const name of names) {
          if (!cacheName || name.startsWith(cacheName)) {
            caches.delete(name)
          }
          caches.delete(name)
        }
      }),
    )
    .then(() => window.location.reload())
}
