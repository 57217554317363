import { useState } from "react"
import { GenericDialog } from "@in-and-out-belleza/ui"
import { TermsAndConditions } from "../Content/TermsAndConditions"
import { Box, Button, Link } from "@mui/material"

const CartTermsAndConditions = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Link
        onClick={ev => {
          ev.preventDefault()
          setOpen(true)
        }}
        href=""
        sx={{ mt: 1, display: "block" }}
      >
        Terminos y condiciones de venta
      </Link>
      <GenericDialog open={open} onClose={() => setOpen(false)} title={"TERMINOS Y CONDICIONES"}>
        <TermsAndConditions excludeTitle />
        <Box component="div" sx={{ display: "flex", justifyContent: "right", pt: 1 }}>
          <Button variant="contained" onClick={() => setOpen(false)}>
            CERRAR
          </Button>
        </Box>
      </GenericDialog>
    </>
  )
}

export { CartTermsAndConditions }
