import { useEffect, useRef } from "react"
import { useAtom } from "jotai"
import { _flowElements, createFlowEvent, sendFlowEvent } from "../../atoms/state"
import { getFlowElementName } from "../../components/ScreenElements"
import { FlowElement, FlowEvent } from "../../types/flow.types"
import { EventListenerMiddleware } from "./event-listeners"
import { isHtmlElement } from "../../components/FlowElement"

const getEventId = (event: Event) => {
  const date = Math.round(Date.now() / 500)
  return `${event.type}-${date}`
}

const getHTMLText = (path: Array<EventTarget>, target: HTMLElement | SVGElement) => {
  if (target instanceof HTMLElement) {
    try {
      const regExp = new RegExp(target.innerText.split(/\n/)[0], "i")
      return path.length === 3
        ? ""
        : target.innerHTML.match(regExp)?.[0] ?? target.innerText.replace(/\n/g, " ")
    } catch (E) {
      return target.innerText.replace(/\n/g, " ")
    }
  }
  return ""
}

const getDisplayText = (path: Array<EventTarget>, target: HTMLElement | SVGElement) => {
  const ph = target instanceof HTMLInputElement ? target.placeholder : ""
  const htmlText = getHTMLText(path, target)
  return htmlText.length > 20
    ? `${htmlText.substring(0, 20)}...`
    : htmlText || ph || target.role || target.ariaLabel || ""
}

const findTargetIndex = (event: Event) => {
  const path = event.composedPath()
  const labelIndex = path.findIndex(item => item instanceof Element && item.tagName === "LABEL")
  if (labelIndex !== -1) return labelIndex
  return Math.max(
    ...[
      0,
      path.findIndex(item => item instanceof Element && item.tagName === "A"),
      path.findIndex(item => item instanceof Element && item.tagName === "BUTTON"),
      path.findIndex(item => item instanceof Element && item.tagName === "INPUT"),
      path.findIndex(item => item instanceof Element && item.getAttribute("role") === "button"),
    ].filter(i => i !== -1),
  )
}

const getName = (element: HTMLElement | SVGElement) => {
  switch (element.tagName) {
    case "A":
      return "Link"
    case "LABEL":
      return "Label"
    case "INPUT":
      if (element instanceof HTMLInputElement)
        switch (element.type) {
          case "text":
            return "TextInput"
          default:
            return `${element.tagName}[${element.type}]`
        }
      break
    case "BUTTON":
      return "Button"
    case "LI":
      return "ListItem"
    default:
      if (element.role) {
        return `Role[${element.role}]`
      }
      return `<${element.tagName.toLocaleLowerCase()}>`
  }
}

const getAttribute = (target: HTMLElement | SVGElement, name: string) => {
  if (target.getAttribute(name)) return `[${name}="${target.getAttribute(name)}"]`
  return ""
}

const getFlowContainers = (path: Array<EventTarget>, elements: Array<FlowElement>) => {
  return elements.filter(i => i.el && path.includes(i.el)).reverse()
}

export const getSelector = (path: Array<EventTarget>, target: HTMLElement | SVGElement) => {
  const text = getDisplayText(path, target)
  const iText = text ? ` ${text}` : ""
  const label = `${getName(target)}${iText}`
  const tag = target.tagName.toLocaleLowerCase()
  const type = getAttribute(target, "type")
  const ph = getAttribute(target, "placeholder")
  const href = getAttribute(target, "href")
  const role = getAttribute(target, "role")
  const al = getAttribute(target, "aria-label")
  const dataFlow = getAttribute(target, "data-flow")
  const selector = `${tag}${type}${ph}${href}${role}${al}${dataFlow}`
  const innerText = getHTMLText(path, target)
  const similar = Array.from(document.querySelectorAll(selector))
  const itemIndex = similar.findIndex(el => el === target)
  return {
    selector: `${selector}`,
    innerText,
    label,
    itemIndex,
  }
}

const getContainersLabel = (target: HTMLElement | SVGElement, containers: Array<FlowElement>) => {
  return containers
    .slice(0)
    .reverse()
    .map(cont => getFlowElementName(cont))
    .join(" => ")
}

const eventListener = EventListenerMiddleware()
const position = { x: 0, y: 0 }
const scrollY = { value: 0, timeout: setTimeout(() => {}) }
const size = { width: window.innerWidth, height: window.innerHeight, timeout: setTimeout(() => {}) }
const pressedKeys: Array<string> = []
let activeElement: Element | null = null

const ActionsRecording = () => {
  const prev = useRef<string | null>(null)
  const textInput = useRef<FlowEvent | null>(null)
  const [elements] = useAtom(_flowElements)
  const mousePosition = (event: MouseEvent) => {
    Object.assign(position, { x: event.clientX, y: event.clientY })
  }

  useEffect(() => {
    const localStorage: Record<string, string> = {}
    Object.entries(window.localStorage).forEach(([key, value]) => {
      if (!key.startsWith("flow.")) {
        localStorage[key] = value
      }
    })
    sendFlowEvent({
      type: "browser-init",
      description: `The window browser is opened "${size.width}x${size.height}"`,
      timestamps: { start: new Date(Date.now() - 1000).toISOString() },
      data: {
        height: size.height,
        width: size.width,
        localStorage,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    function receive(event: MessageEvent) {
      try {
        const data = JSON.parse(event.data)
        if (data.type === "flow-user-simulate-type") {
          sendFlowEvent({
            type: "user-typing",
            description: `The user types "${data.value ?? ""}"`,
            data: {
              value: data.value ?? "",
              who: "user",
            },
          })
        }
      } catch (e) {
        // nothing
      }
    }
    window.addEventListener("message", receive)
    return () => {
      window.removeEventListener("message", receive)
    }
  }, [])

  useEffect(() => {
    const dispose = eventListener.before(function (next, event, options) {
      const who = event.isTrusted ? ("user" as const) : ("application" as const)
      if (event.type === "resize") {
        clearTimeout(size.timeout)
        size.height = window.innerHeight
        size.width = window.innerWidth
        size.timeout = setTimeout(() => {
          sendFlowEvent({
            type: "user-resize",
            description: `The ${who} resize to ${size.width}x${size.height}`,
            data: {
              height: size.height,
              width: size.width,
              who,
            },
          })
        }, 500)
      }
      if (
        event.type === "keyup" &&
        (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement)
      ) {
        const hidden = event.target.type === "password"
        const val = event.target.value
        const hiddenVal = new Array(val.length).fill("_").join("")
        const description = `The ${who} types "${hidden ? hiddenVal : val}"`
        textInput.current = createFlowEvent({
          type: "user-typing",
          description,
          timestamps: { start: textInput.current?.timestamps.start ?? new Date().toISOString() },
          data: {
            value: val,
            who,
          },
        })
      }
      if (event instanceof KeyboardEvent) {
        if (prev.current !== getEventId(event) && event.type === "keyup") {
          const trackCodes = ["Enter", "Tab", "ArrowLeft", "ArrowRight", "ArrowDown", "ArrowUp"]
          if (trackCodes.includes(event.code)) {
            sendFlowEvent({
              type: "user-keypress",
              description: `The ${who} press ${event.code}`,
              data: {
                code: event.code,
                who,
              },
            })
          }
          prev.current = getEventId(event)
        }
      }
      if (event instanceof PointerEvent) {
        const path = event.composedPath()
        const index = findTargetIndex(event)
        const target = path[index]
        if (target instanceof HTMLElement || target instanceof SVGElement) {
          if (prev.current !== getEventId(event) && event.type === "click") {
            prev.current = getEventId(event)
            if (
              event.target instanceof HTMLElement &&
              event.target.classList.contains("flow-click-ignore")
            ) {
              // FLOW element: IGNORE
            } else {
              const containers = getFlowContainers(path, elements)
              const label = getContainersLabel(target, containers)
              const sel = getSelector(path, target)
              let containerSelector = containers[0]?.selector.selector ?? ""
              if (
                containers[0] &&
                containers[0].el === document.querySelectorAll(sel.selector)[sel.itemIndex]
              ) {
                containerSelector = ""
              }
              const description = `The user clicks on "${label ? label + " => " : ""}${sel.label}"`
              sendFlowEvent({
                type: "user-click",
                description,
                data: {
                  mouseX: position.x,
                  mouseY: position.y,
                  selector: sel,
                  innerText: sel.innerText,
                  containerSelector,
                },
              })
            }
          }
        }
      }
      if (event.type === "scroll") {
        clearTimeout(scrollY.timeout)
        scrollY.value = window.scrollY
        scrollY.timeout = setTimeout(() => {
          if (isHtmlElement(event.target)) {
            const sel = getSelector(event.composedPath(), event.target)
            sendFlowEvent({
              type: "user-scroll",
              description: `The ${who} scrolls on ${sel.label} vertically to ${event.target.scrollTop}`,
              data: {
                scrollY: event.target.scrollTop,
                selector: sel,
                who,
              },
            })
          } else if (event.target === window.document) {
            sendFlowEvent({
              type: "user-scroll",
              description: `The ${who} scrolls vertically to ${scrollY.value}`,
              data: {
                scrollY: scrollY.value,
                who,
              },
            })
          }
        }, 500)
      }
      next(event, options)
    })

    const keyDown = (ev: KeyboardEvent) => pressedKeys.push(ev.code)
    const keyUp = () => pressedKeys.pop()
    const focusIn = () => {
      if (activeElement !== document.activeElement) {
        activeElement = document.activeElement
      }
    }
    const focusOut = () => {
      if (activeElement !== null) {
        if (
          textInput.current !== null &&
          (activeElement instanceof HTMLInputElement ||
            activeElement instanceof HTMLTextAreaElement)
        ) {
          sendFlowEvent(textInput.current)
          textInput.current = null
        }
        activeElement = null
      }
    }
    window.addEventListener("keydown", keyDown)
    window.addEventListener("keyup", keyUp)
    window.addEventListener("mousemove", mousePosition)
    document.addEventListener("focusin", focusIn)
    document.addEventListener("focusout", focusOut)

    const int = window.setInterval(() => {
      if (
        document.activeElement instanceof HTMLIFrameElement &&
        document.activeElement !== activeElement
      ) {
        activeElement = document.activeElement
        const target = document.activeElement
        const path = [target]
        const containers = getFlowContainers(path, elements)
        const sel = getSelector(path, target)
        const description = `The user clicks on Iframe ${sel.label}"`
        sendFlowEvent({
          type: "user-click",
          description,
          data: {
            mouseX: position.x,
            mouseY: position.y,
            selector: sel,
            innerText: sel.innerText,
            containerSelector: containers[0]?.selector.selector ?? "",
          },
        })
      }
    }, 1000)
    return () => {
      document.removeEventListener("focusin", focusIn)
      document.removeEventListener("focusout", focusOut)
      window.removeEventListener("keydown", keyDown)
      window.removeEventListener("keyup", keyUp)
      window.removeEventListener("mousemove", mousePosition)
      clearInterval(int)
      dispose()
    }
  }, [elements])

  return null
}

export { ActionsRecording }
