import {
  getArrayOfWorkingDays,
  useNextDaysBookings,
  usePublicDb,
  useWorkersBookings,
} from "@in-and-out-belleza/api/resources"
import { getCalendar, getAvailableHours } from "@in-and-out-belleza/api/utils"
import { AdminWorker, GoogleGetHoursRequest } from "@in-and-out-belleza/api/interfaces"
import { dateUtils } from "@in-and-out-belleza/utils"

type HookParams = GoogleGetHoursRequest & { treatments: Array<string> }

const useAvailableHours = ({ date, treatments }: HookParams) => {
  const { db } = usePublicDb()
  const { data, isFetching } = useWorkersBookings(date)
  const calendar = getCalendar(db, date)
  if (!treatments.length || !calendar || !data || isFetching) return { hours: [], isLoading: true }
  const available = getAvailableHours(db, data, date, treatments)
  const centers = Object.keys(available).reduce((acc, key) => {
    const value = available[Number(key)].filter((item, index, array) => {
      if (item.timestamp < Date.now() + 10 * 60 * 1000) return false
      const subIndex = array.findIndex(i => i.timestamp === item.timestamp)
      return subIndex === index
    })
    const closed = db.closures.find(c => {
      return dateUtils.startOfTheDay(date).getTime() === c.from && c.centerIndex === Number(key)
    })
    if (closed) return { ...acc, [key]: [] }

    return {
      ...acc,
      [key]: value
        .map(i => {
          const worker = db.workers.find(s => s.index === i.workerIndex)
          return {
            ...i,
            worker,
          }
        })
        .sort((a, b) => a.timestamp - b.timestamp),
    }
  }, {} as Record<number, Array<{ timestamp: number; duration: number; worker: AdminWorker }>>)

  const isClosed = Object.keys(centers)
    .map(Number)
    .every(centerIndex => {
      return !!db.closures.find(c => {
        return dateUtils.startOfTheDay(date).getTime() === c.from && c.centerIndex === centerIndex
      })
    })
  return {
    centers,
    isLoading: false,
    isClosed,
  }
}

const useTreatmentsHours = (ids: Array<string>) => {
  const { db } = usePublicDb()
  const dates = getArrayOfWorkingDays([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
  const daysData = useNextDaysBookings(dates)
  const days = daysData.map((dayData, index) => {
    const date = dates[index]
    return getAvailableHours(db, dayData.data ?? [], date, ids)
  })
  const data = days
    .map(day => {
      return Object.keys(day)
        .map(centerIndex =>
          day[Number(centerIndex)].map(i => ({ ...i, centerIndex: Number(centerIndex) })),
        )
        .flat()
    })
    .flat()
    .filter((item, index, array) => {
      if (item.timestamp < Date.now() + 10 * 60 * 1000) return false
      const subIndex = array.findIndex(i => i.timestamp === item.timestamp)
      return subIndex === index
    })
    .sort((first, second) => first.timestamp - second.timestamp)

  return { isLoading: daysData.filter(i => i.isLoading).length > 0, data }
}

export { useAvailableHours, useTreatmentsHours }
