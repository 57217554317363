import { Middleware } from "../../utils/Middleware"

const LocalStorageListener = () => {
  const _setItem = window.Storage.prototype.setItem
  const _removeItem = window.Storage.prototype.removeItem

  const middleware = new Middleware<[string, string | null, unknown]>()

  const callMd = middleware.use(function (next, key, value, context) {
    next(key, value, context)
    return [key, value, context]
  })

  window.Storage.prototype.setItem = function (key, value) {
    callMd(key, value, this)
    _setItem.call(this, key, value)
  }

  window.Storage.prototype.removeItem = function (key) {
    callMd(key, null, this)
    _removeItem.call(this, key)
  }

  return middleware
}

export { LocalStorageListener }
