import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { useNav } from "../../hooks/useNav"
import { Logo, Splash } from "@in-and-out-belleza/ui"
import { useGift } from "../../queries/get-gift"
import { BookButton } from "../Buttons/BookButton"
import { useCartGrouped } from "../Cart/useCartGrouped"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { dateUtils } from "@in-and-out-belleza/utils"

// const Snowfall = lazy(() => import("react-snowfall"))

const GiftCard = () => {
  const { cart } = useNav()
  const { id: param } = useParams()
  const dashIndex = param?.lastIndexOf("-") === -1 ? param.length : param?.lastIndexOf("-") ?? 0
  const orderId = param?.substring(0, dashIndex) ?? ""
  const index = Number(param?.substring(dashIndex + 1))

  const { isLoading, data, isError } = useGift({ orderId, index })
  const { treatments } = useCartGrouped(data?.cart.map(i => i.id) ?? [])
  const { db } = usePublicDb()

  if (isLoading) {
    return <Splash />
  }
  if (!dashIndex || !orderId || dashIndex === -1 || !data || isError) {
    return (
      <Box component="div">
        <Paper sx={{ p: 1 }}>
          <Box component="div" sx={{ pt: 6, maxWidth: "400px", margin: "0 auto", p: 3 }}>
            <img src="/assets/empty-cart.png" alt="carrito vacio" width={"100%"} />
          </Box>
          <Typography sx={{ textAlign: "center", fontSize: 24, mt: 2 }}>
            ESTE REGALO AUN NO HA SIDO COMPLETADO
          </Typography>
          <Box component="div" sx={{ my: 2, display: "flex", justifyContent: "center" }}>
            <Button component={Link} to={cart.url()} variant="contained" onClick={() => {}}>
              IR AL CARRITO
            </Button>
          </Box>
        </Paper>
      </Box>
    )
  }

  return (
    <Box component="div">
      {/* <Snowfall {...animation} style={{ zIndex: 1, position: "fixed" }} /> */}
      <Box component="div" sx={{ p: 1 }}>
        <Typography sx={{ textAlign: "center", py: 2 }} variant="h3">
          Enhorabuena {data.name}!
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          Has recibido un regalo y ya puedes disfratuar de eso!
        </Typography>
      </Box>
      <Box component="div">
        {treatments.map(item => {
          const trt = item.item
          const used = data.cart.filter(i => i.id === item.id && i.used).length === data.cart.length
          if (!trt) return null
          return (
            <Paper key={item.id} sx={{ m: 1, p: 1 }}>
              <Typography>
                {item.quantity} x {trt.type}: {trt.title}
              </Typography>

              {used ? (
                <Typography color="gray">YA HAS USADO LOS TRATAMIENTOS</Typography>
              ) : (
                <Box component="div">
                  <Divider sx={{ my: 1 }} />
                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ flexGrow: 1 }}>
                      <BookButton cart={[item.id]} title="RESERVA CITA" />
                    </Box>
                    <Button component={Link} to={`${trt.href}?booking=true`}>
                      VER DETALLES
                    </Button>
                  </Box>
                </Box>
              )}
            </Paper>
          )
        })}
        {data.credit.amount ? (
          <Paper sx={{ m: 1, p: 1 }}>
            <Typography>CHEQUE REGALO de {dateUtils.toCurrency(data.credit.amount)}</Typography>

            {data.credit.used ? (
              <Typography color="gray">YA HAS RESCATADO EL CHEQUE</Typography>
            ) : null}
          </Paper>
        ) : null}
      </Box>
      <Divider sx={{ py: 1 }} />
      <Box component="div" sx={{ margin: "0 auto", width: 160, py: 2 }}>
        <Logo size={40} />
      </Box>
      <Paper sx={{ m: 1, px: 1 }}>
        <Typography sx={{ pt: 1 }}>NUESTROS CENTROS:</Typography>
        <Divider sx={{ mb: 2 }} />
        {db.centers.map(center => {
          return (
            <Box component="div" key={center.id} sx={{ mb: 2 }}>
              <Typography fontSize={16}>
                <strong>{center.label}</strong>: {center.address}
              </Typography>
              <Typography fontSize={14}>
                TEL: {center.phone} - MOVIL: {center.mobile}
              </Typography>
            </Box>
          )
        })}
      </Paper>
    </Box>
  )
}

export { GiftCard }
