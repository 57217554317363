import { User } from "@in-and-out-belleza/api/interfaces"

export function getAnonymousUser(isLoggingIn = false): User {
  return {
    id: "",
    hasBonusCards: false,
    bookings: [],
    orders: [],
    bonusCards: [],
    logged: false,
    email: "",
    isLoggingIn,
    nextBookings: [],
    name: "",
    tel: "",
    permissions: {
      pages: [],
      centers: [],
    },
    physicalCenters: [],
    appVersion: "",
    dbVersion: 0,
    notifications: {
      bookReminder: "email",
      promotions: "email",
    },
  }
}
