import { useEffect, useState } from "react"

const observeElementSize = (fixedRef: Element, onResize: ResizeObserverCallback) => {
  if (!window.ResizeObserver) return () => {}
  const resizeObserver = new window.ResizeObserver(onResize)
  resizeObserver.observe(fixedRef)
  return () => resizeObserver.unobserve(fixedRef)
}

const useElementSize = <T extends Element>(element: T | null) => {
  const [height, setHeight] = useState<number>(element?.clientHeight || 0)
  const [width, setWidth] = useState<number>(element?.clientWidth || 0)

  useEffect(() => {
    if (!element) return
    setHeight(element.clientHeight)
    setWidth(element.clientWidth)
    return observeElementSize(element, () => {
      setHeight(element.clientHeight)
      setWidth(element.clientWidth)
    })
  }, [element])

  return { height, width }
}

export { useElementSize }
