import { Center } from "@in-and-out-belleza/api/interfaces"
import { GenericDialog } from "@in-and-out-belleza/ui"
import {
  Box,
  Button,
  capitalize,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Link as MaterialLink,
  Divider,
} from "@mui/material"
import { useState } from "react"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useDate } from "@in-and-out-belleza/hooks"
import { getCalendar } from "@in-and-out-belleza/api/utils"
import { decimalToHours } from "../Content/decimalToHours"

export function CentersMenu() {
  const { db } = usePublicDb()
  const [center, setCenter] = useState<Center | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const cal = getCalendar(db, Date.now())
  const { getDayName } = useDate()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box component="div" sx={{ display: "inline-block" }}>
      <Button
        size="large"
        color="inherit"
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        CONTACTOS
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {db.centers.map(item => {
          return (
            <MenuItem
              key={item.index}
              sx={{ displa: "flex" }}
              onClick={() => {
                setCenter(item)
                handleClose()
              }}
            >
              <Box component="div" sx={{ flexGrow: 1 }}>
                {capitalize(item.label.toLocaleLowerCase())}
                <Typography fontSize={12}>{item.address}</Typography>
              </Box>
              <Box component="div" sx={{ width: "40px" }}></Box>
              <IconButton component={MaterialLink} href={`tel:${item.mobile}`}>
                <BeautyIcon name="phone" size={20} />
              </IconButton>
              <IconButton
                component={MaterialLink}
                target="_blank"
                href={`https://maps.google.com/?q=place_id:${item.google.placeId}`}
              >
                <BeautyIcon name="location" size={20} />
              </IconButton>
              <IconButton>
                <BeautyIcon name="time" size={20} />
              </IconButton>
            </MenuItem>
          )
        })}
      </Menu>
      <GenericDialog
        open={!!center}
        title={`CENTRO BELLEZA ${center?.label}:`}
        onClose={() => setCenter(null)}
      >
        <Box component="div" sx={{ minWidth: 450 }}>
          <Typography fontSize={16}>{center?.address}</Typography>
          <Typography fontSize={14}>Tel: (+34) {center?.phone}</Typography>
          <Typography fontSize={14}>Movil: (+34) {center?.mobile}</Typography>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Typography sx={{ mb: 2 }}>HORARIO: </Typography>
          {cal.map((arr, day) => {
            const start = arr.filter(item => item[0] === center?.index).map(item => item[2])
            const end = arr.filter(item => item[0] === center?.index).map(item => item[3])

            return (
              <Box component="div" sx={{ display: "flex" }} key={day}>
                <Typography width={180} fontSize={14}>
                  {getDayName(day)}
                </Typography>
                <Typography key={day} fontSize={14}>
                  {Math.min(...start) === Infinity
                    ? "CERRADO"
                    : `${decimalToHours(Math.min(...start))} - ${decimalToHours(Math.max(...end))}`}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </GenericDialog>
    </Box>
  )
}
