import { Middleware } from "../../utils/Middleware"

const WindowOpenListener = () => {
  const _open = window.open

  const middleware = new Middleware<
    [string | URL | undefined, string | undefined, string | undefined]
  >()

  const callOpen = middleware.use(function (next, key, value, context) {
    next(key, value, context)
    return [key, value, context]
  })

  window.open = function (url?: string | URL, target?: string, features?: string) {
    callOpen(url, target, features)
    return _open.call(this, url, target, features)
  }

  return middleware
}

export { WindowOpenListener }
