import { useAuth } from "@in-and-out-belleza/authentication"
import { isValidEmail } from "@in-and-out-belleza/validation"
import { Box, Button, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useForm } from "react-hook-form"

const Newsletter = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; newsletter: boolean }>()
  const hasEmailError = errors.email !== undefined
  const [error, setError] = useState<string>("")
  const [ok, setOk] = useState<boolean>(false)
  const { resfreshStatus } = useAuth()

  if (ok) {
    return (
      <Box component="div" sx={{ maxWidth: 400, margin: "0 auto" }}>
        <Typography variant="h5" sx={{ pt: 2 }}>
          NEWSLETTER de In&Out:
        </Typography>
        <Typography sx={{ mt: 2, fontSize: 20 }}>
          Hemos aceptado tu solicitud y ya NO recibiras nuestros correos promocionales.
        </Typography>
        <Typography sx={{ mt: 4 }}>Un Saludo</Typography>
      </Box>
    )
  }

  return (
    <Box component="div" sx={{ maxWidth: 400, margin: "0 auto" }}>
      <Typography variant="h5" sx={{ pt: 2 }}>
        NEWSLETTER de In&Out:
      </Typography>
      <Typography>Quiero darme de baja de este servicio</Typography>
      <Typography></Typography>
      <form
        onSubmit={handleSubmit(({ email }) => {
          fetch(`/api/newsletter/unsubscribe?email=${email}`)
            .then(res => res.text())
            .then(res => {
              if (res === "ok") {
                resfreshStatus()
                setOk(true)
              } else {
                setError("ESTE CORREO NO TIENE UNA SUBSCRIPCION")
              }
            })
        })}
        noValidate
        name="newsletter"
      >
        <TextField
          autoComplete="email"
          error={hasEmailError}
          fullWidth
          helperText={hasEmailError ? "Por favor ingresa un correo valido" : ""}
          label="Correo Electronico"
          margin="normal"
          style={{ backgroundColor: "transparent" }}
          type="email"
          variant="outlined"
          {...register("email", { required: true, validate: isValidEmail })}
        />
        {error ? <Typography color="error">{error}</Typography> : null}
        <Button sx={{ mt: 1 }} fullWidth type="submit" variant="contained">
          DARME DE BAJA
        </Button>
      </form>
    </Box>
  )
}

export { Newsletter }
