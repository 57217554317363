import { Add, Remove } from "@mui/icons-material"
import { Box, Divider, IconButton, Paper, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { useCartGrouped } from "./useCartGrouped"
import { SetStateAction } from "jotai"
import { Dispatch } from "react"

type Props = {
  cart: Array<string>
  setCart?: Dispatch<SetStateAction<Array<string>>>
  title?: string
}

const CartTreatments = ({ cart, setCart, title = "" }: Props) => {
  const { db } = usePublicDb()
  const { treatments } = useCartGrouped(cart)

  if (!treatments.length) return null

  return (
    <Paper sx={{ p: 1 }}>
      {title}
      {treatments.map((el, index) => {
        return (
          <Box component="div" key={el.id}>
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ flexGrow: 1 }}
                color="green"
                component={Link}
                to={el.item?.href ?? ""}
              >
                {el.item?.type}: {el.item?.title} [x{el.quantity}]
              </Typography>
              {setCart ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setCart?.(prev => {
                      prev.splice(prev.indexOf(el.id), 1)
                      return [...prev]
                    })
                  }}
                >
                  <Remove />
                </IconButton>
              ) : null}
              {setCart ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setCart?.(prev => [...prev, el.id])
                  }}
                >
                  <Add />
                </IconButton>
              ) : null}
              <Typography sx={{ textAlign: "right", minWidth: "80px" }}>
                {getCartTotal(db, new Array(el.quantity).fill(el.id)).total.toFixed(2)} €
              </Typography>
            </Box>
            {index !== treatments.length - 1 ? <Divider /> : null}
          </Box>
        )
      })}
    </Paper>
  )
}

export { CartTreatments }
