import { lazy, useEffect, useState } from "react"
import { HomeMobile } from "./HomeMobile/HomeMobile"
import snowUrl from "../../../assets/images/snow.png"
import heartUrl from "../../../assets/images/heart.png"
import { Hours } from "./Hours"
import { useLocation } from "react-router-dom"
import { useNav } from "../../hooks/useNav"
import { DrawerStyled } from "../Booking/Drawer.styled"
import { Locations } from "./Locations"
import { CallUs } from "./CallUs"
import { Box } from "@mui/material"

const Snowfall = lazy(() => import("react-snowfall"))

const whatFalls = () => {
  const month = new Date().getMonth()
  const day = new Date().getDate()
  const snow = new Image()
  snow.src = snowUrl
  const heart = new Image()
  heart.src = heartUrl
  if (month === 11) return { images: [snow], radius: [5, 9] as [number, number] }
  if (month === 1 && day < 15)
    return { images: [heart], radius: [6, 22] as [number, number], snowflakeCount: 11 }
  return undefined
}

const Content = () => {
  const animation = whatFalls()
  const [openDrawer, setOpenDrawer] = useState("")
  const location = useLocation()
  const { home, hours, locations, callUs } = useNav()

  useEffect(() => {
    if (hours.isPath()) setOpenDrawer(hours.url())
    if (locations.isPath()) setOpenDrawer(locations.url())
    if (callUs.isPath()) setOpenDrawer(callUs.url())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Box component="div">
      {animation ? <Snowfall {...animation} style={{ zIndex: 1, position: "fixed" }} /> : null}
      <HomeMobile />
      <DrawerStyled
        anchor="bottom"
        open={!!openDrawer}
        onClose={() => {
          setOpenDrawer("")
          home.navigate()
        }}
        onOpen={() => {}}
      >
        {openDrawer === hours.url() ? <Hours /> : null}
        {openDrawer === locations.url() ? <Locations /> : null}
        {openDrawer === callUs.url() ? <CallUs /> : null}
      </DrawerStyled>
    </Box>
  )
}

export { Content }
