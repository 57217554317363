import { Link } from "react-router-dom"
import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDate } from "@in-and-out-belleza/hooks"
import { InfiniteList } from "@in-and-out-belleza/ui"
import { activePromotions } from "@in-and-out-belleza/api/utils"

const Promotions = () => {
  const { db } = usePublicDb()
  const { endsIn } = useDate()

  return (
    <InfiniteList
      items={activePromotions(db)}
      renderItem={item => {
        return (
          <Paper key={item.id} sx={{ margin: "8px", padding: 1 }}>
            <Typography color="primary" fontSize={30}>
              {item.title}
            </Typography>
            <Typography>Termina {endsIn(item.to)}</Typography>
            <Divider />
            <Box
              component="div"
              sx={{
                background: `url(${item.photo})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "250px",
              }}
            ></Box>
            <Divider />
            <br />
            <Button component={Link} to={item.href}>
              LEER TODO
            </Button>
          </Paper>
        )
      }}
    />
  )
}

export { Promotions }
