import {
  Box,
  Button,
  Link,
  MenuItem,
  Paper,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useAuth } from "@in-and-out-belleza/authentication"
import { DrawerStyled } from "../Booking/Drawer.styled"
import { IOSSwitch } from "../Content/IOSSwitch"
import { Privacy } from "../Content/Privacy"
import { Flow } from "@in-and-out-belleza/flow"
import { AdminClientNotifications } from "@in-and-out-belleza/api/interfaces"
import emailMisspelled, { top100 } from "email-misspelled"

const regExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const isValidEmail = (email: string | undefined) => regExp.test(email || "")

const telRegExp = /^(\+|00)?[1-9][0-9 \-().]{6,32}$/
const isValidTel = (tel: string) => telRegExp.test(tel)

type Props = {
  onClose: () => Promise<void>
  description: string
}

function UserLoginStepper({ onClose, description }: Props) {
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [promotions, setPromotions] = useState<AdminClientNotifications["promotions"]>("email")
  const [name, setName] = useState("")
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [hasNameError, setHasNameError] = useState("")
  const [hasTelError, setHasTelError] = useState("")
  const [hasEmailError, setHasEmailError] = useState("")
  const [hasPasswordError, setHasPasswordError] = useState("")
  const [hasPrivacyError, setHasPrivacyError] = useState("")
  const [privacy, setPrivacy] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [sendCodeTimeout, setSendCodeTimeout] = useState(0)
  const { login, resfreshStatus } = useAuth()
  const emailChecker = useMemo(() => emailMisspelled({ domains: top100, maxMisspelled: 4 }), [])

  const suggestions = emailChecker(email)

  useEffect(() => {
    const timeout = setInterval(() => {
      if (sendCodeTimeout > 0) {
        setSendCodeTimeout(Math.max(0, sendCodeTimeout - 1000))
      }
    }, 1000)
    return () => clearInterval(timeout)
  }, [sendCodeTimeout])

  const steps = [
    {
      label: "Escribe tu correo",
      description: (
        <Box component="div">
          {description}
          <TextField
            autoComplete="email"
            size="small"
            fullWidth
            label="Correo"
            margin="normal"
            style={{ backgroundColor: "transparent" }}
            variant="outlined"
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            error={!!hasEmailError}
            helperText={hasEmailError}
          />
          <Box
            component="div"
            sx={{
              height: 50,
              background: "#ddd",
              textTransform: "none",
              pl: 2,
              py: 0.5,
            }}
          >
            {!suggestions.length && (
              <Typography sx={{ fontSize: 13 }}>
                El correo parece de forma {isValidEmail(email) ? "correcta" : "incorrecta"}
              </Typography>
            )}
            {suggestions.length > 0 && isValidEmail(email) && (
              <Box component="div" sx={{ display: "flex" }}>
                <Box component="div" sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: 13 }}>Querias ecribir?</Typography>
                  <Typography sx={{ fontSize: 13 }}>{suggestions[0].corrected}</Typography>
                </Box>
                <Button
                  tabIndex={-1}
                  onClick={() => setEmail(suggestions[0].corrected)}
                  variant="contained"
                >
                  SI
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      ),
    },
    {
      label: "Revisa tus datos",
      description: (
        <Box component="div">
          <TextField
            autoComplete="given-name"
            size="small"
            fullWidth
            label="Nombre"
            margin="normal"
            style={{ backgroundColor: "transparent" }}
            variant="outlined"
            value={name}
            onChange={event => setName(event.target.value)}
            error={!!hasNameError}
            helperText={hasNameError}
          />
          <TextField
            autoComplete="phone"
            size="small"
            fullWidth
            label="Telefono"
            margin="normal"
            style={{ backgroundColor: "transparent" }}
            variant="outlined"
            type="tel"
            value={tel}
            onChange={event => setTel(event.target.value)}
            error={!!hasTelError}
            helperText={hasTelError}
          />
          <Paper sx={{ p: 1 }}>
            <Typography sx={{ ml: 0.5, flexGrow: 1, alignSelf: "center" }}>
              Recibir promociones desde In&Out:
            </Typography>
            <Select
              sx={{ mt: 1 }}
              fullWidth
              size="small"
              value={promotions}
              onChange={ev => {
                const value = ev.target.value as AdminClientNotifications["bookReminder"]
                setPromotions(value)
              }}
            >
              <MenuItem value={"none"}>NO QUIERO</MenuItem>
              <MenuItem value={"email"}>EMAIL</MenuItem>
              {/* <MenuItem value={"push"}>MOVIL</MenuItem> */}
            </Select>
          </Paper>
        </Box>
      ),
    },
    {
      label: "Codigo de seguridad",
      description: (
        <Box component="div">
          <Box
            component="div"
            sx={{
              background: "#00000022",
              p: 1,
              borderRadius: "6px",
              display: "flex",
              border: `1px solid ${hasPrivacyError ? "red" : "transparent"}`,
            }}
          >
            <IOSSwitch
              onChange={() => {
                setPrivacy(prev => !prev)
                setHasPrivacyError("")
              }}
              checked={privacy}
            />
            <Typography sx={{ ml: 1 }} fontSize={14}>
              He leido y acepto la{" "}
              <Link
                onClick={() => {
                  setOpenDrawer(true)
                }}
              >
                Cláusula Informativa y la Política de cookies
              </Link>
            </Typography>
          </Box>
          <Typography sx={{ m: "8px 8px 0 12px" }} fontSize={14} color="#d32f2f">
            {hasPrivacyError}
          </Typography>
          <TextField
            disabled={login.isLoading || isLoading}
            size="small"
            fullWidth
            label="Codigo seguridad"
            margin="normal"
            style={{ backgroundColor: "transparent" }}
            variant="outlined"
            type="text"
            inputMode="numeric"
            value={password}
            onChange={event => setPassword(event.target.value)}
            error={!!hasPasswordError}
            helperText={hasPasswordError}
          />
        </Box>
      ),
    },
  ]

  const handleNext = async () => {
    if (activeStep === 0) {
      if (isValidEmail(email)) {
        if (sendCodeTimeout <= 1) {
          setHasEmailError("")
          setIsLoading(true)
          await fetch("/api/login/smart-login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email }),
          })
            .then(res => res.json())
            .then(res => {
              setPromotions(res.promotions)
              setSendCodeTimeout(60 * 1000)
              setName(res.name)
              setTel(res.tel)
              setActiveStep(1)
            })
          setIsLoading(false)
        } else {
          setSendCodeTimeout(60 * 1000)
          setActiveStep(1)
        }
      } else {
        setHasEmailError("Por favor ingresa un correo valido")
      }
    } else if (activeStep === 1) {
      if (name.trim().length < 2) return setHasNameError("Por favor ingresa tu nombre")
      if (!isValidTel(tel)) return setHasTelError("Por favor ingresa un telefono valido")
      setHasNameError("")
      setHasTelError("")
      setActiveStep(2)
    } else if (activeStep === 2) {
      if (!privacy) return setHasPrivacyError("Por favor accepta nuestra clausolas")
      if (!password)
        return setHasPasswordError("Por favor ingresa el codigo que hemos inviado por correo")
      if (password) {
        setHasPasswordError("")
        await login
          .mutateAsync({ email, password })
          .then(async () => {
            const notifications: AdminClientNotifications = {
              promotions,
              bookReminder: "email",
            }
            await fetch("/api/login/update-user-info", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                name,
                tel,
                privacy: true,
                isSmartInactive: false,
                notifications,
              }),
            })
              .then(() => {
                setIsLoading(true)
                return resfreshStatus()
              })
              .then(() => {
                setIsLoading(true)
                return onClose()
              })
              .then(() => {
                setIsLoading(false)
              })
              .catch(() => {
                setIsLoading(false)
              })
          })
          .catch(() => {
            setHasPasswordError("Il codigo no es valido")
          })
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <Flow
      uniqueName="User Login Dialog"
      elements={[
        {
          role: "container",
          selector: ".MuiDialog-container .MuiPaper-root",
          portal: true,
          zIndex: 1301,
        },
      ]}
    >
      <Box component="div" sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical" component={Box}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 2 ? <Typography variant="caption">Ultimo paso</Typography> : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Box component="div">{step.description}</Box>
                <Box component="div" sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={login.isLoading || isLoading}
                    >
                      {index === steps.length - 1 ? "Confirma" : "Siguiente"}
                    </Button>
                    {index !== steps.length - 1 ? (
                      <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Atras
                      </Button>
                    ) : null}
                    {index === steps.length - 1 ? (
                      <Button
                        disabled={sendCodeTimeout > 0}
                        onClick={async () => {
                          await fetch("/api/login/smart-login", {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ email }),
                          }).then(() => {
                            setSendCodeTimeout(60 * 1000)
                          })
                        }}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Reinvia{sendCodeTimeout > 0 ? ` (${sendCodeTimeout / 1000})` : null}
                      </Button>
                    ) : null}
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <DrawerStyled
          sx={{ zIndex: 1401 }}
          anchor="bottom"
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false)
          }}
          onOpen={() => {}}
        >
          <Privacy />
          <br />
          <Button
            variant="contained"
            onClick={() => {
              setOpenDrawer(false)
              setPrivacy(true)
              setHasPrivacyError("")
            }}
          >
            ESTOY DE ACUERDO
          </Button>
        </DrawerStyled>
      </Box>
    </Flow>
  )
}

export { UserLoginStepper }
