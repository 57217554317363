import * as ReactDOM from "react-dom/client"
import { FlowWrapper } from "./components/FlowWrapper"
import { ScreenElements } from "./components/ScreenElements"
import { LocalStorageRecording } from "./middlewares/local-storage"
import { RequestRecording } from "./middlewares/requests-listeners"
import { ActionsRecording } from "./middlewares/user-actions"
import { WsRecording } from "./middlewares/web-sockets-listener"
import { WindowHistory } from "./middlewares/window"
import { PropsWithChildren, StrictMode } from "react"

/**
 * add Flow to you Application
 */

const flow = async (): Promise<({ children }: PropsWithChildren) => JSX.Element> => {
  if (process.env["FLOW_DISABLED"]) {
    return ({ children }: PropsWithChildren) => <StrictMode>{children}</StrictMode>
  }
  const isFlow = new URL(window.location.href).searchParams.get("flow") !== null

  if (!isFlow) {
    const flow = document.createElement("div")
    flow.id = "flow-wrapper"
    Object.assign(flow.style, { zIndex: 1500, fontFamily: "Courier" })
    document.body.prepend(flow)

    ReactDOM.createRoot(flow).render(
      <>
        <WindowHistory />
        <ActionsRecording />
        <RequestRecording />
        <WsRecording />
        <LocalStorageRecording />
        <ScreenElements />
      </>,
    )

    return ({ children }: PropsWithChildren) => <>{children}</>
  }

  const flow = document.createElement("div")
  flow.id = "flow-wrapper"
  window.name = "flow"
  window.document.title = `FLOW: ${window.document.title}`
  Object.assign(flow.style, { zIndex: 1, fontFamily: "Courier" })
  document.body.style.overflow = "hidden"
  document.body.prepend(flow)
  ReactDOM.createRoot(flow).render(<FlowWrapper />)
  Object.assign(document.body.style, { margin: 0 })
  return new Promise(() => {})
}

export { flow }
