import { useEffect, useState } from "react"
import { Box, Button, Paper, Typography } from "@mui/material"
import {
  ONE_DAY,
  addWorkingDate,
  getNextWorkingDate,
  usePublicDb,
} from "@in-and-out-belleza/api/resources"
import { useWindowFocusEffect } from "../../hooks/useWindowFocus"
import { useDate } from "@in-and-out-belleza/hooks"
import { useAvailableHours } from "../../queries/get-booking-hours"
import { getSpainDate } from "@in-and-out-belleza/api/utils"
import { useBooking } from "../../atoms/atomBooking"
import { CarouselProvider, Slide, Slider } from "pure-react-carousel"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { BookingConfirm } from "./BookingConfirm"
import { queryClient } from "../../contexts/queryClient"

const LoggedBooking = () => {
  const { dayNameDate, hourInterval } = useDate()
  const { isMobile } = useDeviceInfo()
  const { db } = usePublicDb()
  const { center, hour, treatments, setCenter, setTreatments, setHour } = useBooking()
  const [date, setDate] = useState(getNextWorkingDate(Date.now()))
  const [minDate, setMinDate] = useState(getNextWorkingDate(Date.now()))
  const { centers, isLoading, isClosed } = useAvailableHours({
    date,
    center: center.index,
    treatments: treatments.map(trt => trt.id),
  })

  useWindowFocusEffect(() => {
    setDate(getNextWorkingDate(Date.now()))
    setMinDate(getNextWorkingDate(Date.now()))
  })

  useEffect(() => {
    queryClient.refetchQueries(["day-bookings"])
  }, [])

  useEffect(() => {
    setHour(null)
  }, [treatments, date, setHour])

  useEffect(() => {
    if (!centers) return
    if (!centers[center.index].length) {
      const [index = -1] = Object.entries(centers).find(([_, hours]) => hours.length) ?? []
      const cent = db.centers.find(cent => cent.index === Number(index))
      if (!cent) return
      setCenter(cent)
    }
  }, [centers, center, setCenter, db.centers])

  const availableDates = [minDate]
  while (availableDates.length < 15) {
    availableDates.push(getNextWorkingDate(availableDates[availableDates.length - 1] + ONE_DAY))
  }

  const visibleSlides = isMobile ? 3 : 5
  const currentSlide = Math.min(
    Math.max(0, availableDates.findIndex(d => d === date) - (isMobile ? 1 : 2)),
    availableDates.length - visibleSlides,
  )

  const hasNoFreeHours =
    !isLoading &&
    treatments.length &&
    !(centers && Object.values(centers).filter(hours => hours.length).length)

  return (
    <Box component="div">
      <Box component="div">
        <Typography>SELECIONA UNA FECHA:</Typography>
        <CarouselProvider
          naturalSlideWidth={0}
          naturalSlideHeight={0}
          totalSlides={availableDates.length}
          isIntrinsicHeight
          visibleSlides={visibleSlides}
          currentSlide={currentSlide}
        >
          <Slider>
            {availableDates.map((timestamp, index) => {
              return (
                <Slide key={timestamp} index={index} style={{ margin: "0 2px", flexGrow: 1 }}>
                  <Button
                    role="user-booking-date"
                    fullWidth
                    variant={date === timestamp ? "contained" : "outlined"}
                    sx={{ height: "100%", fontSize: "12px", p: 0.5 }}
                    onClick={() => setDate(addWorkingDate(timestamp))}
                  >
                    {dayNameDate(timestamp)}
                  </Button>
                </Slide>
              )
            })}
          </Slider>
        </CarouselProvider>
      </Box>
      <Paper sx={{ p: 1, mb: 1, mt: 1 }}>
        <Typography>SELECIONA TU CENTRO:</Typography>
        <Box component="div" sx={{ gap: 0.5, display: "flex", justifyContent: "center" }}>
          {db.centers.map(item => (
            <Button
              onClick={() => {
                setCenter(item)
                setHour(null)
              }}
              disabled={!(centers && centers[item.index].length)}
              variant={item.index === center.index ? "contained" : "outlined"}
              key={item.id}
            >
              {item.label}
            </Button>
          ))}
        </Box>
        <Typography fontSize={12} sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
          {center.address}
        </Typography>
      </Paper>
      <Box component="div">
        <Box component="div" sx={{ minHeight: isMobile ? undefined : "220px" }}>
          {isLoading && treatments.length ? <Box component="div">BUSCANDO HORARIOS...</Box> : null}
          {hasNoFreeHours && !isClosed ? (
            <Typography sx={{ pt: 7, color: "green", textAlign: "center" }}>
              Lo sentimos... este dia estamos completos en TODOS los centros para realizar este
              servicio
            </Typography>
          ) : null}
          {hasNoFreeHours && isClosed ? (
            <Typography sx={{ pt: 7, color: "green", textAlign: "center" }}>
              ESTAMOS CERRADOS
            </Typography>
          ) : null}

          {!isLoading && centers && centers[center.index].length ? (
            <Box component="div">
              <Box component="div" sx={{ display: "flex" }}>
                <Typography sx={{ flexGrow: 1 }}>SELECIONA UN HORARIO:</Typography>
                <Typography>
                  {hour
                    ? hourInterval(hour.timestamp, hour.timestamp + hour.duration * 60 * 60 * 1000)
                    : null}
                </Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "&:after": { content: "''", flexGrow: 100 },
                }}
              >
                {centers[center.index].map(({ timestamp, duration, worker }) => (
                  <Button
                    role="user-booking-hour"
                    onClick={() => setHour({ timestamp, duration, worker })}
                    size="small"
                    variant={hour?.timestamp === timestamp ? "contained" : "outlined"}
                    sx={{ m: 0.35, p: 0, flexGrow: 1, minWidth: "65px" }}
                    key={timestamp}
                  >
                    {getSpainDate(timestamp).time}
                  </Button>
                ))}
              </Box>
              <Box component="div" sx={{ display: "flex", m: "16px 0", justifyContent: "center" }}>
                <BookingConfirm
                  text={`CONFIRMA`}
                  hour={hour}
                  center={center}
                  treatments={treatments}
                  onSuccess={() => {
                    setHour(null)
                    setTreatments([])
                  }}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export { LoggedBooking }
