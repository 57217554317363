import { Dispatch } from "react"
import { SetStateAction, useAtom } from "jotai"
import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import { _flowEvents } from "../../atoms/state"

type Props = {
  open: number | null
  setOpen: Dispatch<SetStateAction<number | null>>
  onEdit: (index: number, description: string) => void
}

const FlowModalEdit = ({ open, setOpen, onEdit }: Props) => {
  const [events] = useAtom(_flowEvents)
  return (
    <Modal
      open={open !== null}
      onClose={() => setOpen(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="div"
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Modify the flow entry description:
        </Typography>
        <Box component="div" sx={{ py: 2 }}>
          <form
            onSubmit={ev => {
              ev.preventDefault()
              if (open === null) return
              const el = ev.currentTarget.elements.namedItem("description")
              const description = el instanceof HTMLTextAreaElement ? el.value : ""
              onEdit(open, description)
              setOpen(null)
            }}
          >
            <TextField
              required
              rows={3}
              multiline
              name="description"
              size="small"
              defaultValue={events[open ?? 0]?.description}
              fullWidth
              label="Description"
            />
            <Box component="div" sx={{ display: "flex", gap: 2, justifyContent: "end", pt: 2 }}>
              <Button onClick={() => setOpen(null)}>CANCEL</Button>
              <Button type="submit" variant="contained" color="success">
                SAVE
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}

export { FlowModalEdit }
