import { getApiClient } from "@in-and-out-belleza/api/client"
import { useMutation } from "@tanstack/react-query"
import { useAuth } from "@in-and-out-belleza/authentication"

type Props = { hour: number | null; treatments: Array<string>; centerId: string; workerId: string }

const insertBooking = ({ hour, treatments, centerId, workerId }: Props) => {
  const client = getApiClient()
  return client.post("/google/calendar/insert", {
    centerId,
    start: new Date(hour ?? Date.now()).getTime(),
    treatments,
    workerId,
  })
}

const useBookingInsert = () => {
  const { resfreshStatus } = useAuth()
  return useMutation(insertBooking, {
    onSuccess: async () => {
      await resfreshStatus()
    },
  })
}

export { useBookingInsert }
