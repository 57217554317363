import { Tooltip, styled, tooltipClasses } from "@mui/material"

import { ExtraProps } from "./EllipsisTooltip.types"

export const StyledTooltip = styled(
  ({ className, ...props }: ExtraProps) => <Tooltip {...props} classes={{ popper: className }} />,
  { name: "EllipsisTooltip", shouldForwardProp: prop => prop !== "tooltipStyles" },
)(props => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...props.tooltipStyles,
  },
}))
