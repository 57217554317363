import "./styles.css"
import "pure-react-carousel/dist/react-carousel.es.css"

import { PropsWithChildren } from "react"
import * as ReactDOM from "react-dom/client"
import { QueryClientProvider } from "@tanstack/react-query"
import { BrowserRouter as Router } from "react-router-dom"
import { SnackbarProvider } from "notistack"

import { App } from "./app/app"
import { queryClient } from "./app/contexts/queryClient"
import { useHydrateAtoms } from "jotai/utils"
import { queryClientAtom } from "jotai-tanstack-query"
import { AuthProvider } from "@in-and-out-belleza/authentication"
import { flow } from "@in-and-out-belleza/flow"

const HydrateAtoms = ({ children }: PropsWithChildren) => {
  useHydrateAtoms([[queryClientAtom, queryClient]])
  return <>{children}</>
}

setTimeout(() => {
  window.document.documentElement.classList.remove("loading")
}, 800)

flow().then(Wrapper => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
  root.render(
    <Wrapper>
      <Router>
        <AuthProvider queryClient={queryClient} accountEmailSent={"/es/cuenta/email-sent/"}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
              autoHideDuration={2000}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
              <HydrateAtoms>
                <App />
              </HydrateAtoms>
            </SnackbarProvider>
          </QueryClientProvider>
        </AuthProvider>
      </Router>
    </Wrapper>,
  )
})
