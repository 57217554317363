import { Box, Grid, Paper, Typography } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon, ReadMoreText } from "@in-and-out-belleza/ui"
import { useNav } from "../../hooks/useNav"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { BookButton } from "../Buttons/BookButton"
import { BuyButton } from "../Buttons/BuyButton"
import { xmlUtils } from "@in-and-out-belleza/utils"

const FamilyTreatments = () => {
  const { db } = usePublicDb()
  const { menuHref } = useParams()
  const { treatments } = useNav()
  const trts = db.treatments
    .filter(item => item.menuHref === menuHref)
    .map(item => {
      const price = getCartTotal(db, [item.id])
      const discount = price.real - price.total
      return { ...item, discount }
    })
    .sort((first, second) => {
      if (first.discount - second.discount === 0) {
        return first.title.localeCompare(second.title)
      }
      return first.discount - second.discount
    })

  return (
    <Box component="div">
      <Paper component={Link} to={treatments.url()} sx={{ m: 1, p: 1, display: "flex" }}>
        <Typography>VOLVER A TODOS LOS TRATAMIENTOS</Typography>
        <Box component="div" sx={{ flexGrow: 1, justifyContent: "end", display: "flex" }}>
          <BeautyIcon name="treatments" size={20} />
        </Box>
      </Paper>
      <Grid container spacing={0.2}>
        {trts.map(item => {
          return (
            <Grid item xs={12} sm={6} key={item.id} component={Link} to={item.href}>
              <Paper sx={{ m: 1, position: "relative", display: "block", height: "98%" }}>
                <Box component="div" sx={{ p: 1 }}>
                  <Typography flexGrow={1} variant="h6">
                    {item.title}
                  </Typography>
                  <Box component="div" sx={{ display: "flex", mb: 1 }}>
                    <BookButton cart={[item.id]} variant="contained" sx={{ margin: 0.5 }} />
                    <Box component="div" sx={{ flexGrow: 1, justifyContent: "end", display: "flex" }}>
                      <BuyButton cart={[item.id]} variant="contained" sx={{ margin: 0.5 }} />
                    </Box>
                  </Box>
                  <ReadMoreText text={xmlUtils.stripHtmlTags(item.description)} clampedLines={2} />
                </Box>
              </Paper>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export { FamilyTreatments }
