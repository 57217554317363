import { Link as RouterLink } from "react-router-dom"

import { AppBar, Badge, Box, Button, IconButton, Link, Toolbar } from "@mui/material"

import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useNav } from "../../hooks/useNav"
import { Logo } from "@in-and-out-belleza/ui"
import { SearchInput } from "../Search"
import { BoxStyled } from "./DesktopHeader.styles"
import { useCart } from "../../atoms/atomCart"
import { CentersMenu } from "./CentersMenu"
import { Flow } from "@in-and-out-belleza/flow"

const DesktopHeader = () => {
  const { home, shop, booking, account, cart: cartNav, gift } = useNav()
  const { user } = useAuth()
  const { numOfArticles } = useCart()
  return (
    <Flow
      uniqueName="Header"
      elements={[
        { role: "link", selector: "a", zIndex: 1500 },
        { role: "menu", selector: "button", zIndex: 1500 },
      ]}
    >
      <BoxStyled logged={user.logged || false}>
        <AppBar position="fixed" color="secondary">
          <Toolbar>
            <Link component={RouterLink} to={home.url()} color="inherit" underline="none">
              <Logo size={35} sx={{ width: "auto" }} />
            </Link>
            <SearchInput
              shouldAddToCart={shop.isPath() || cartNav.isPath()}
              sx={{
                display: { xs: "none", sm: "flex" },
                maxWidth: "700px",
                ml: "30px",
                flexGrow: 8,
              }}
            />
            <Box component="div" sx={{ flexGrow: 1 }} />
            <Box component="div">
              <Button component={RouterLink} to={home.url()} size="large" color="inherit">
                HOME
              </Button>
              <Button component={RouterLink} to={booking.url()} size="large" color="inherit">
                <Badge badgeContent={user.nextBookings.length} color="error">
                  CITAS
                </Badge>
              </Button>
              <Button component={RouterLink} to={gift.url()} size="large" color="inherit">
                REGALA
              </Button>
              <Button component={RouterLink} to={shop.url()} size="large" color="inherit">
                <Badge badgeContent={numOfArticles()} color="error">
                  TIENDA
                </Badge>
              </Button>
              <CentersMenu />
              <IconButton
                component={RouterLink}
                to={account.url()}
                size="large"
                edge="end"
                color="inherit"
              >
                <BeautyIcon name="user" size={32} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </BoxStyled>
    </Flow>
  )
}

export { DesktopHeader }
