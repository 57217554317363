const escapeXML = function escape(string: string) {
  return string
    .replace(/>/g, "&gt;")
    .replace(/</g, "&lt;")
    .replace(/'/g, "&apos;")
    .replace(/"/g, "&quot;")
    .replace(/&/g, "&amp;")
}

const stripHtmlTags = (string: string) =>
  string
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/&nbsp;/g, " ")
    .replace(/\n/g, " ")

const xmlUtils = {
  escapeXML,
  stripHtmlTags,
}

export { xmlUtils }
