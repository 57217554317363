import { useEffect, useRef, useState } from "react"
import { AutoAnimateHeight } from "../AutoAnimateHeight"
import { Box, Typography, styled } from "@mui/material"

const ReadMoreLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
  cursor: "pointer",
  textDecoration: "underline",
  marginTop: theme.spacing(1),
  display: "inline-block",
  ":hover": {
    textDecoration: "none",
  },
}))

const ClampedText = styled(Box)(() => {
  return {
    overflow: "hidden",
    textOverflow: "ellipsis",
    overflowY: "hidden",
    display: "-webkit-box",
    overflowWrap: "break-word",
    WebkitBoxOrient: "vertical",
    fontSize: 18,
    color: "text.primary",
  }
})

type Props = {
  minHeight?: number
  clampedLines?: number
  text: string | undefined
}

const ReadMoreText = ({ text, minHeight, clampedLines = 3 }: Props) => {
  const ref = useRef<HTMLParagraphElement>(null)
  const [isClamped, setIsClamped] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const hasText = !!text

  useEffect(() => {
    setShowMore(false)
  }, [text])

  useEffect(() => {
    if (!ref.current) return
    if (!showMore) {
      setIsClamped(ref.current.clientHeight !== ref.current.scrollHeight)
    }
  }, [ref, text, showMore])

  return (
    <AutoAnimateHeight minHeight={minHeight} sx={{ width: "100%", px: 1 }}>
      <ClampedText
        ref={ref}
        sx={{
          WebkitLineClamp: showMore ? undefined : clampedLines,
          lineClamp: showMore ? undefined : clampedLines,
        }}
        dangerouslySetInnerHTML={{ __html: text ?? "" }}
      />

      {hasText && isClamped ? (
        <ReadMoreLink
          onClick={() => {
            setShowMore(prev => !prev)
          }}
        >
          Leer {showMore ? "menos" : "mas"}
        </ReadMoreLink>
      ) : null}
    </AutoAnimateHeight>
  )
}

export { ReadMoreText }
