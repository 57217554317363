import { useCallback, useEffect, useRef, useState } from "react"
import { Box, CircularProgress, Link, styled } from "@mui/material"

const ShowMore = styled("div")(() => ({
  textAlign: "left",
  fontSize: "12px",
  "& a": {
    cursor: "pointer",
    display: "inline-block",
  },
}))

type Props<ItemType> = {
  items: Array<ItemType>
  renderItem: (item: ItemType, index: number) => JSX.Element | null
  fetchNextPage?: () => void
  hasNextPage?: boolean | undefined
  loading?: boolean
  showNextItemsNum?: boolean
  paginate?: number
  onClick?: () => void
  showMoreLabel?: { more: string; less: string }
}

function ShowMoreList<ItemType = unknown>({
  items,
  renderItem,
  fetchNextPage,
  hasNextPage = false,
  loading = false,
  paginate = 5,
  showNextItemsNum = false,
  onClick,
  showMoreLabel = { more: "Veer todas", less: "Veer menos" },
}: Props<ItemType>) {
  const myRef = useRef<HTMLDivElement>(null)
  const [numOfStories, setNumOfStories] = useState(paginate)
  const showLess = numOfStories >= items.length && !hasNextPage
  const showProgress = loading && items.length < numOfStories

  const handleShowMore = useCallback(() => {
    if (showLess) {
      setNumOfStories(paginate)
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    } else {
      setNumOfStories(items.length)
    }
    onClick?.()
  }, [showLess, paginate, onClick, items.length])

  useEffect(() => {
    if (numOfStories >= items.length - paginate && hasNextPage && fetchNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, numOfStories, items, hasNextPage, paginate])

  const next = Math.min(paginate, items.length - numOfStories)

  return (
    <Box component="div" ref={myRef}>
      {items.filter((_, index) => index < numOfStories).map(renderItem)}
      {showProgress && (
        <CircularProgress
          data-testid="show-more-list-loading"
          sx={{ display: "flex", margin: "0 auto" }}
        />
      )}
      {items.length > paginate && !showProgress ? (
        <ShowMore>
          <Link onClick={handleShowMore}>
            {showLess
              ? showMoreLabel.less
              : `${showMoreLabel.more}${showNextItemsNum ? ` (${next})` : ""}`}
          </Link>
        </ShowMore>
      ) : null}
    </Box>
  )
}

export { ShowMoreList }
