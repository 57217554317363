import { useEffect } from "react"
import { parseJSON } from "../../api/api-utils"
import { sendFlowEvent } from "../../atoms/state"
import { HTTPMethod } from "../../types/flow.types"
import { XMLHttpRequestMiddleware } from "./requests-listeners"
import { FetchMiddleware } from "./fetch-middleware"

const requestMd = XMLHttpRequestMiddleware()
const fetchMd = FetchMiddleware()

const getFetchMethod = (input: URL | RequestInfo, init?: RequestInit): HTTPMethod => {
  if (input instanceof URL) {
    return getMethod(init?.method)
  } else if (typeof input === "string") {
    return getMethod(init?.method)
  }
  return getMethod(input.method)
}

const getMethod = (req: string | undefined): HTTPMethod => {
  if (req === "POST") return "post"
  if (req === "DELETE") return "delete"
  if (req === "PUT") return "put"
  if (req === "PATCH") return "patch"
  return "get"
}

const RequestRecording = () => {
  useEffect(() => {
    const dispose = requestMd.after((next, ...args) => {
      const [req] = args
      if (req.responseURL) {
        sendFlowEvent({
          type: "http-request",
          description: `the application requests to "${new URL(req.responseURL).href}"`,
          data: {
            url: req.responseURL.toString(),
            status: req.status,
            responseText: req.responseText,
            responseJSON: parseJSON(req.responseText),
            method: getMethod(req.method),
            requestBody: req.sendBody,
            requestJSON: parseJSON(req.sendBody ?? ""),
            responseHeaders: req.getAllResponseHeaders(),
            statusText: req.statusText,
          },
        })
      }
      next(...args)
    })
    const disposeFetch = fetchMd.after((next, ...args) => {
      const [res, input, init] = args
      res?.then(async response => {
        const method = getFetchMethod(input, init)
        const body = response.bodyUsed ? response.body : await response.json()
        sendFlowEvent({
          type: "http-request",
          description: `the application requests to "${response.url}"`,
          data: {
            url: response.url,
            status: response.status,
            responseText: JSON.stringify(body),
            responseJSON: body,
            method,
            requestBody: init?.body?.toString() ?? "",
            requestJSON: init && init.body ? JSON.parse(init.body?.toString()) : "",
            responseHeaders: Array.from(response.headers.entries())
              .map(([key, value]) => `"${key}": "${value}"`)
              .join(""),
            statusText: response.statusText,
          },
        })
      })
    })
    return () => {
      dispose()
      disposeFetch()
    }
  }, [])

  return null
}

export { RequestRecording }
