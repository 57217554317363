import { isTreatment } from "@in-and-out-belleza/api/interfaces"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { useDate, useDeviceInfo } from "@in-and-out-belleza/hooks"

import queryString from "query-string"
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material"
import { SetStateAction, Suspense, lazy, useCallback, useEffect, useState } from "react"
import { CartTreatments } from "../Cart/CartTreatments"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import { emptyGift, useCart } from "../../atoms/atomCart"
import { useNav } from "../../hooks/useNav"
import { ErrorBoundary } from "@in-and-out-belleza/ui"

const Document = lazy(() => import("../PdfDocument"))
const Page = lazy(() => import("../PdfPage"))

const Gift = () => {
  const { db } = usePublicDb()
  const [numOfPages, setNumOfPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const { currency } = useDate()
  const { setGifts, createGift, setCreateGift } = useCart()
  const { width } = useDeviceInfo()
  const { cart: catNav } = useNav()

  const { name, cart, activeStep, credit = 0 } = createGift

  useEffect(() => {
    setCreateGift(g => ({ ...g, credit: g.credit ?? 0 }))
  }, [setCreateGift])

  const setCart = useCallback(
    (newValue: SetStateAction<Array<string>>) => {
      setCreateGift(found => {
        return { ...found, cart: newValue instanceof Function ? newValue(found.cart) : newValue }
      })
    },
    [setCreateGift],
  )

  const setName = useCallback(
    (name: string) => {
      setCreateGift(found => {
        return { ...found, name }
      })
    },
    [setCreateGift],
  )

  const setCredit = useCallback(
    (credit: number) => {
      setCreateGift(found => {
        return { ...found, credit }
      })
    },
    [setCreateGift],
  )

  const handleNext = () => {
    setCreateGift(found => {
      return { ...found, activeStep: found.activeStep + 1 }
    })
  }

  const handleBack = () => {
    setCreateGift(found => {
      return { ...found, activeStep: found.activeStep - 1 }
    })
  }

  useEffect(() => {
    if (createGift.activeStep === 1 && createGift.cart.length === 0 && createGift.credit === 0) {
      setCreateGift(prev => ({ ...prev, activeStep: 0 }))
    }
  }, [createGift, setCreateGift])

  const giftTotal = getCartTotal(db, cart).real + credit

  return (
    <Box component="div" sx={{ margin: "0 auto", px: 1 }}>
      <Typography variant="h3" sx={{ textAlign: "center", pt: 1 }}>
        CREA TU REGALO:
      </Typography>
      <Box component="div" sx={{ p: 1 }}>
        <Typography>
          ¡Bienvenido a nuestra página de creación de regalos de belleza{" "}
          <strong>personalizados</strong>! Podrás crear un regalo único para alguien{" "}
          <em>especial</em> en tu vida.
        </Typography>
      </Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>ELIGE UNO O MAS TRATAMIENTOS</StepLabel>
          <StepContent>
            <Box component="div">
              <Box component="div" sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Typography sx={{ flexGrow: 1 }}>CHEQUE REGALO:</Typography>
                <Select
                  size="small"
                  value={credit}
                  color="primary"
                  onChange={ev => setCredit(Number(ev.target.value))}
                >
                  <MenuItem value={0}>Ninguno</MenuItem>
                  {new Array(11).fill(0).map((_, index) => {
                    const val = 50 + index * 10
                    return (
                      <MenuItem key={index} value={val}>
                        {currency(val)}{" "}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
              <Divider sx={{ mb: 1 }} />
              <Autocomplete
                renderTags={() => null}
                size="small"
                filterSelectedOptions
                value={cart.map(id => db.treatments.find(t => t.id === id)).filter(isTreatment)}
                onChange={(event, newValue) => setCart(newValue.map(t => t.id))}
                selectOnFocus
                clearOnBlur
                multiple
                handleHomeEndKeys
                options={db.treatments.filter(i => !i.deleted)}
                getOptionLabel={option => {
                  if (typeof option === "string") {
                    return option
                  }
                  return `${option?.type}: ${option?.title}`
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option?.type}: {option?.title} - {currency(getCartTotal(db, [option.id]).real)}
                  </li>
                )}
                renderInput={params => <TextField {...params} label="Tratamientos ..." />}
              />
              <Box component="div" sx={{ mt: 1 }} />
              <CartTreatments cart={cart} setCart={setCart} />
              <Typography sx={{ textAlign: "right", my: 1, mr: 1 }}>
                PRECIO DEL REGALO: {currency(giftTotal)}
              </Typography>
            </Box>
            <Box component="div" sx={{ mb: 2 }}>
              <Button
                variant="contained"
                disabled={giftTotal <= 0}
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                SIGUIENTE
              </Button>
            </Box>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>PARA QUIEN ES?</StepLabel>
          <StepContent>
            <TextField
              inputProps={{ autoComplete: "off" }}
              value={name}
              onChange={ev => setName(ev.target.value)}
              label="Este nombre aparecerá en el regalo"
              size="small"
              fullWidth
            />

            <Box component="div" sx={{ mb: 2 }}>
              <Button
                variant="contained"
                disabled={!name}
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                SIGUIENTE
              </Button>
              <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                ATRAS
              </Button>
            </Box>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>AVANCE DE LA TARJETA REGALO</StepLabel>

          <StepContent>
            <Box component="div" sx={{ mb: 2 }}>
              <Button
                variant="contained"
                disabled={!name}
                onClick={() => {
                  setGifts(prev => [...prev, createGift])
                  setCreateGift({ ...emptyGift })
                  catNav.navigate()
                }}
                sx={{ mt: 1, mr: 1 }}
              >
                AÑADE AL CARRITO
              </Button>
              <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                ATRAS
              </Button>
              <Box component="div" sx={{ mt: 2 }}>
                <ErrorBoundary
                  ErrorPage={
                    <Box component="div">
                      <br />
                      <Typography
                        component={Link}
                        target="_blank"
                        href={`/api/gift-pdf/preview?${queryString.stringify({
                          name,
                          cart,
                          credit,
                        })}`}
                      >
                        HAZ CLICK AQUI PARA ABRIR EL AVANCE EN OTRA PESTAÑA
                      </Typography>
                    </Box>
                  }
                >
                  <Suspense>
                    <Document
                      onLoadSuccess={({ numPages }: { numPages: number }) => {
                        setNumOfPages(numPages)
                        setPageNumber(1)
                      }}
                      file={`/api/gift-pdf/preview?${queryString.stringify({
                        name,
                        cart,
                        credit,
                      })}`}
                    >
                      <Page
                        width={Math.min(800, width) - 60}
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </Document>
                  </Suspense>
                  {numOfPages ? (
                    <Box component="div"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: Math.min(800, width) - 60,
                        background: "#ddd",
                      }}
                    >
                      <IconButton
                        disabled={pageNumber === 1}
                        onClick={() => setPageNumber(prev => prev - 1)}
                      >
                        <ArrowBackIos />
                      </IconButton>
                      <Typography sx={{ flexGrow: 1, textAlign: "center" }}>
                        PAGINA {pageNumber} de {numOfPages}
                      </Typography>
                      <IconButton
                        disabled={pageNumber === numOfPages}
                        onClick={() => setPageNumber(prev => prev + 1)}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  ) : null}
                </ErrorBoundary>
              </Box>
            </Box>
          </StepContent>
        </Step>
      </Stepper>

      {/* <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button sx={{ mt: 1, mr: 1 }}>Reset</Button>
          </Paper> */}
    </Box>
  )
}

export { Gift }
