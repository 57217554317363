import {
  AdminBeautyParty,
  AdminBonus,
  AdminBonusCard,
  AdminClientNotifications,
  AdminEquipment,
  AdminNews,
  AdminOrder,
  AdminPhoto,
  AdminPost,
  AdminProduct,
  AdminPromotion,
  AdminTreatment,
  BonusCreditTransaction,
  BonusTransaction,
  BonusTreatmentTransaction,
  DbSettings,
} from "./admin-api-interfaces"
import { UserGoogleEvent } from "./google-interfaces"

type CalendarDay = {
  centerIndex: number
  workerIndex: number
  from: number
  to: number
  created: number
}

type CalendarWeek = {
  created: number
  days: [
    Array<CalendarDay>,
    Array<CalendarDay>,
    Array<CalendarDay>,
    Array<CalendarDay>,
    Array<CalendarDay>,
    Array<CalendarDay>,
    Array<CalendarDay>,
  ]
}
interface Calendar {
  _id: string
  from: number
  to: number
  weeks: Array<CalendarWeek>
  exceptions: Array<{
    timestamp: number
    workerIndex: number
    centerIndex: number
    from: number
    to: number
  }>
}

interface Promotion extends AdminPromotion {
  href: string
}

interface BonusCard extends AdminBonusCard {
  href: string
}

interface Product extends AdminProduct {
  menuHref: string
  href: string
  photoLink: string
  link: string
}

interface Center {
  _id: string
  color: string
  id: string
  index: number
  label: string
  address: string
  phone: string
  mobile: string
  closed: boolean
  physical: boolean
  billRef: string
  bbvaRef: string | null
  rooms: Array<string>
  google: {
    latitude: string
    longitude: string
    placeId: string
    embedSrc: string
  }
}

interface AdminWorker {
  _id: string
  email: string | undefined
  id: string
  label: string
  fullName: string
  index: number
  breakTimeDuration: number
  photo: string
  treatments: Array<{ id: string; duration: number }>
  isWaitingList?: boolean
  autoAcceptHours?: boolean
  totalHolidays?: number
  deleted?: boolean
}

type Photo = { url: string; position: string }
interface Treatment extends AdminTreatment {
  menuHref: string
  href: string
  photoType: Photo
}

interface News extends AdminNews {
  href: string
  cart: Array<string>
}

interface BeautyParty extends AdminBeautyParty {
  href: string
}

interface AdminReview {
  created: number
  description: string
  lang: "es"
  name: string
  rate: number
  userId: string
  _id: string
}

interface AdminUser {
  id: string
  name: string
  permissions: {
    pages: Array<string>
    centers: Array<string>
  }
  adminLevel: number
  otp: Array<string>
}

interface PublicDb {
  products: Array<Product>
  centers: Array<Center>
  allCenters: Array<Center>
  workers: Array<AdminWorker>
  equipments: Array<AdminEquipment>
  treatments: Array<Treatment>
  bonusCards: Array<BonusCard>
  promotions: Array<Promotion>
  photos: Array<AdminPhoto>
  news: Array<News>
  reviews: { count: number; average: number }
  beautyParties: Array<BeautyParty>
  cartPriority: Array<string>
  calendars: Array<Calendar>
  posts: Array<AdminPost>
  processes: Array<AdminProcess>
  closures: Array<AdminClosure>
  adminUsers: Array<AdminUser>
  settings: DbSettings
}

interface UserBooking {
  calendarId: string
  start: string
  end: string
  eventId: string
  location: string
  summary: string
  label: string
  treatments: Array<string>
}

const isBonusTreatmentTransaction = (
  transaction: BonusTransaction,
): transaction is BonusTreatmentTransaction => {
  return "treatmentId" in transaction
}

const isBonusCreaditTransaction = (
  transaction: BonusTransaction,
): transaction is BonusCreditTransaction => {
  return "amount" in transaction
}

const isTreatmentId = (id: string): id is `TRT-${number}` => id.startsWith("TRT-")
const isBonusCardId = (id: string): id is `TAR-${number}` => id.startsWith("TAR-")
const isProductId = (id: string): id is `PRD-${number}` => id.startsWith("PRD-")
const isTreatment = (
  item:
    | Treatment
    | BonusCard
    | Product
    | AdminTreatment
    | AdminBonusCard
    | AdminProduct
    | undefined,
): item is Treatment => !!item && isTreatmentId(item.id)
const isBonusCard = (
  item:
    | Treatment
    | BonusCard
    | Product
    | AdminTreatment
    | AdminBonusCard
    | AdminProduct
    | undefined,
): item is BonusCard => !!item && isBonusCardId(item.id)

const isAdminBonus = (bonus: AdminBonus | AdminOrder | UnusedBonus): bonus is AdminBonus => {
  return (bonus as AdminBonus).clientId !== undefined
}

const isAdminOrder = (bonus: AdminBonus | AdminOrder | UnusedBonus): bonus is AdminOrder => {
  return (bonus as AdminOrder).userId !== undefined
}
interface AdminProcess {
  _id: string
  duration: number
  processId: number
  summary: string
  treatments: Array<string>
  deleted?: boolean
}

interface User {
  logged: boolean
  id: string
  hasBonusCards: boolean
  bonusCards: Array<AdminBonus>
  orders: Array<AdminOrder>
  bookings: Array<UserGoogleEvent>
  nextBookings: Array<UserGoogleEvent>
  email: string
  isLoggingIn?: boolean
  name: string
  tel: string
  adminLevel?: number
  permissions: {
    pages: Array<string>
    centers: Array<string>
  }
  physicalCenters: Array<string>
  appVersion: string
  dbVersion: number
  notifications: AdminClientNotifications
  otp?: Array<string>
}

interface AdminClosure {
  _id: string
  type: "holiday" | "bankHoliday" | "closure" | "exception"
  description: string
  status?: "accepted" | "pending" | "rejected"
  centerIndex?: number
  workerIndex?: number
  from: number
  to: number
  created: number
}

type Gift = {
  cart: Array<string>
  credit: number
  name: string
  activeStep: number
}

type UnusedBonus = {
  transactions?: Array<BonusTransaction>
  treatments?: Array<`TRT-${number}`>
  cart: { id: string }
  timestamp: number
}

export type {
  PublicDb,
  Product,
  User,
  UserBooking,
  Promotion,
  Photo,
  BonusCard,
  Center,
  Treatment,
  AdminWorker,
  AdminUser,
  Calendar,
  CalendarWeek,
  CalendarDay,
  AdminReview,
  AdminProcess,
  Gift,
  AdminClosure,
  UnusedBonus,
}

export {
  isBonusCreaditTransaction,
  isBonusTreatmentTransaction,
  isTreatment,
  isTreatmentId,
  isProductId,
  isBonusCardId,
  isBonusCard,
  isAdminBonus,
  isAdminOrder,
}
