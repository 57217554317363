import { Alert, Box, Paper, Typography } from "@mui/material"
import { CartSendTo, useCart } from "../../../atoms/atomCart"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useAuth } from "@in-and-out-belleza/authentication"
import { getCartAndGiftsTotal, getChargeAmount, roundToInt } from "@in-and-out-belleza/api/utils"
import { CartBonusCards } from "../CartBonusCards"
import { CartProducts } from "../CartProducts"
import { CartTreatments } from "../CartTreatments"
import { useEffect, useState } from "react"
import { EmptyCart } from "../EmptyCart"
import { CartProductInfo } from "../CartProductInfo"
import { useSearchParams } from "react-router-dom"
import { useDate } from "@in-and-out-belleza/hooks"
import { Helmet } from "react-helmet"
import { SabadellForm } from "./SabadelForm"
import { isValidEmail } from "@in-and-out-belleza/validation"
import queryString from "query-string"
import { useAtom } from "jotai"
import { _lastOrderAmount } from "../../../atoms/atomLastAnonymousOrder"
import { CartTermsAndConditions } from "../CartTermsAndConditions"
import { CartGifts } from "../CartGifts"

const isValidSendTo = (sendTo: CartSendTo) => {
  if (!sendTo.name || sendTo.name.length === 0) return false
  if (!sendTo.address || sendTo.address.length === 0) return false
  if (!sendTo.cap || sendTo.cap.length === 0) return false
  if (!sendTo.city || sendTo.city.length === 0) return false
  if (!sendTo.tel || sendTo.tel.length === 0) return false
  return true
}

const GENERIC = "Opss ... un error ha ocurrido, vuelve a intentarlo"

const SabadellCart = () => {
  const { currency } = useDate()
  const { user, resfreshStatus } = useAuth()
  const { db } = usePublicDb()
  const { cart, sendTo, setCart, setGifts, gifts, numOfArticles } = useCart()
  const [searchParams, setSearchParams] = useSearchParams()
  const [hasError, setHasError] = useState("")
  const cartAmount = getCartAndGiftsTotal(db, cart, gifts)
  const [email, setEmail] = useState<string>("")
  const hasProducts = !!cart.filter(i => i.startsWith("PRD-")).length
  const paymentEnabled = isValidEmail(email) && (!hasProducts || isValidSendTo(sendTo))
  const chargeAmount = getChargeAmount(db, cart)
  const [_, setLastOrder] = useAtom(_lastOrderAmount)

  useEffect(() => {
    if (searchParams.get("error")) {
      const message =
        searchParams.get("error") === "generic" ? GENERIC : searchParams.get("error") ?? GENERIC
      setHasError(message)
      searchParams.delete("error")
      setSearchParams(searchParams)
    } else if (searchParams.get("success")) {
      const email = localStorage.getItem("inandout.buyEmail")
      const orderId = searchParams.get("r")
      setCart([])
      setGifts([])
      searchParams.delete("r")
      searchParams.delete("success")
      setSearchParams(searchParams)
      if (email) {
        fetch(`/api/sabadell-confirm?${queryString.stringify({ email, orderId })}`)
          .then(res => res.json())
          .then(order => {
            resfreshStatus()
            if (order._id) {
              setLastOrder(order)
            }
          })
      }
    }
  }, [setCart, searchParams, setGifts, setSearchParams, setLastOrder, resfreshStatus])

  useEffect(() => {
    const addToCart = searchParams.get("addCart")
    if (addToCart) {
      setCart([...addToCart.split("|")])
      searchParams.delete("addCart")
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams, setCart])

  useEffect(() => {
    localStorage.setItem("inandout.buyEmail", email)
  }, [email])

  useEffect(() => {
    if (user.logged) {
      setEmail(prev => {
        if (!prev) return user.email
        return prev
      })
    }
  }, [user])

  if (numOfArticles() === 0) {
    return <EmptyCart />
  }

  return (
    <Box component="div" sx={{ p: 1 }}>
      <Helmet>
        <script src={process.env["IFRAME_URL"] ?? ""} />
      </Helmet>
      <CartTermsAndConditions />
      <CartGifts />
      <Box component="div" sx={{ m: 1 }}></Box>
      <CartTreatments cart={cart} setCart={setCart} title="TRATAMIENTOS:" />
      <Box component="div" sx={{ m: 1 }}></Box>
      <CartProducts />
      <Box component="div" sx={{ m: 1 }}></Box>
      <CartBonusCards />
      <Box component="div" sx={{ m: 1 }}></Box>
      {cartAmount.total > 0 ? (
        <Paper sx={{ p: 2 }}>
          {cartAmount.discount !== 0 ? (
            <Box component="div">
              <Box component="div" sx={{ display: "flex" }}>
                <Typography sx={{ flexGrow: 1, textAlign: "right" }}>TOTAL:</Typography>
                <Typography
                  sx={{ width: "120px", textAlign: "right", textDecoration: "line-through" }}
                >
                  {currency(cartAmount.total)}
                </Typography>
              </Box>
              <Box component="div" sx={{ display: "flex" }}>
                <Typography sx={{ flexGrow: 1, textAlign: "right", fontSize: 12 }}>
                  DESCUENTO PROMOCION:
                </Typography>
                <Typography sx={{ width: "120px", textAlign: "right", fontSize: 12 }}>
                  {currency(cartAmount.discount)}
                </Typography>
              </Box>
            </Box>
          ) : null}
          {hasProducts ? (
            <Box component="div" sx={{ display: "flex" }}>
              <Box component="div" sx={{ flexGrow: 1, textAlign: "right" }}>
                GASTOS DE ENVIOS:
                <Typography sx={{ color: "#999", fontSize: 12, mt: -1 }}>
                  No se aplicarà ningun coste a partir de {currency(db.settings.freeChargeLimit)} de
                  compras en productos
                </Typography>
              </Box>
              <Typography sx={{ width: "120px", textAlign: "right" }}>
                {currency(chargeAmount)}
              </Typography>
            </Box>
          ) : null}
          <Box component="div" sx={{ display: "flex" }}>
            <Typography sx={{ flexGrow: 1, textAlign: "right" }}>TOTAL A PAGAR:</Typography>
            <Typography sx={{ width: "120px", textAlign: "right" }}>
              {(cartAmount.real + chargeAmount).toFixed(2)} €
            </Typography>
          </Box>
        </Paper>
      ) : null}
      <Box component="div" sx={{ m: 1 }}></Box>
      <Paper sx={{ p: 1 }}>
        <Typography color="primary" fontSize={14}>
          En el correo te enviaremo un resumen de los gastos y un PDF que podras utilizar como
          regalo
        </Typography>
      </Paper>
      {hasProducts ? <CartProductInfo /> : null}
      {hasError ? (
        <Paper sx={{ mt: 1 }}>
          <Alert severity="error">{hasError}</Alert>
        </Paper>
      ) : null}
      <SabadellForm
        value={roundToInt((cartAmount.real + chargeAmount) * 100)}
        disabled={!paymentEnabled}
        setEmail={setEmail}
        email={email}
        cart={cart}
        gifts={gifts}
        sendTo={sendTo}
      />
    </Box>
  )
}

export { SabadellCart }
