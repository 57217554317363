import { TextField } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledTextInput = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: "10px",
  "& .MuiInputBase-root": {
    padding: "3px 4px 3px 10px",
    margin: 0,
  },
  "& input": {
    height: "20px",
  },
}))

const StyledSearchItem = styled("li")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: "10px",
  margin: "5px",
  "& h4": {
    margin: 0,
    padding: 0,
    lineHeight: "17px",
  },
  "& h6": {
    margin: 0,
    padding: 0,
    overflow: "hidden",
    linehHeight: "11px",
    maxHeight: "30px",
    color: theme.palette.primary.main,
  },
  "&.MuiAutocomplete-option.Mui-focused": {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.primary.contrastText,
    },
    "& svg": {
      fill: theme.palette.primary.contrastText,
    },
  },
  "& svg": {
    minWidth: "48px",
    fill: theme.palette.primary.main,
    padding: "3px",
    border: "1px solid black",
    marginRight: "5px",
  },
}))

export { StyledTextInput, StyledSearchItem }
