import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Autocomplete, Box, InputAdornment, styled, SxProps } from "@mui/material"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { SearchItem, useSearchResults } from "@in-and-out-belleza/hooks"
import { StyledSearchItem, StyledTextInput } from "./SearchInput.styles"
import { useCart } from "../../atoms/atomCart"
import MiniSearch from "minisearch"
type Props = {
  sx: SxProps
  shouldAddToCart?: boolean
}

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
  "& svg": {
    fill: theme.palette.secondary.main,
  },
}))

const SearchInput = ({ sx, shouldAddToCart }: Props) => {
  const navigate = useNavigate()
  const [value, setValue] = useState<SearchItem | null>(null)
  const data = useSearchResults({
    include: shouldAddToCart ? ["products", "treatments", "bonusCards"] : undefined,
  })
  const { isMobile, height } = useDeviceInfo()
  const { setCart, openSearch, setOpenSearch } = useCart()

  const miniSearch = useMemo(() => {
    const miniSearch = new MiniSearch<SearchItem>({
      fields: ["label", "description", "group", "href"],
      storeFields: [],
      idField: "key",
      searchOptions: {
        fuzzy: 0.2,
        boost: { label: 2 },
        prefix: true,
      },
    })
    miniSearch.addAll(data)
    return miniSearch
  }, [data])

  // Index all documents

  // Search with default options

  return (
    <Autocomplete
      filterOptions={(options, { inputValue }) =>
        miniSearch.search(inputValue).map(({ id }) => data.find(d => d.key === id) ?? data[0])
      }
      id="search-autocomplete"
      value={value}
      open={openSearch}
      onOpen={() => setOpenSearch(true)}
      onClose={() => {
        setValue(null)
        setOpenSearch(false)
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") return
        if (newValue?.href) {
          if (shouldAddToCart && newValue.canSellOnline) {
            setCart(prev => [...prev, newValue.id])
          } else {
            navigate(newValue?.href)
          }
          setTimeout(() => {
            const el = document.querySelector(".MuiAutocomplete-clearIndicator") as HTMLElement
            el?.click()
            document.getElementById("search-autocomplete")?.blur()
          }, 200)
        }
      }}
      groupBy={option => option.group}
      selectOnFocus
      clearOnBlur
      clearOnEscape
      freeSolo
      handleHomeEndKeys
      options={data}
      getOptionLabel={option => {
        if (typeof option === "string") {
          return option
        }
        return option.label
      }}
      ListboxProps={{
        style: {
          padding: 0,
          maxHeight: isMobile ? `${height / 2}px` : undefined,
        },
      }}
      renderOption={(props, option) => (
        <StyledSearchItem {...props} key={option.key}>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <h4>{option.label}</h4>
            <h6>{option.description}</h6>
          </Box>
          {/* <BeautyIcon name={option.icon} size={48} /> */}
        </StyledSearchItem>
      )}
      sx={sx}
      renderInput={params => (
        <StyledTextInput
          {...params}
          placeholder={`${shouldAddToCart ? "Compra" : "Busca"} productos, tratamientos, bonos ...`}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornmentStyled position="start">
                {" "}
                <BeautyIcon size={24} name="search" />
              </InputAdornmentStyled>
            ),
          }}
        />
      )}
    />
  )
}

export { SearchInput }
