import { Box, Typography } from "@mui/material"
import { useLocation } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { NotFoundPage } from "../NotFoundPage"

const Post = () => {
  const { db } = usePublicDb()
  const { pathname } = useLocation()
  const post = db.posts.find(item => item.href === pathname)

  if (!post) return <NotFoundPage />

  return (
    <Box component="div" sx={{ m: 1, p: 1, "& img": { display: "block", margin: "0 auto", maxWidth: "100%" } }}>
      <Typography sx={{ pt: 1, pb: 2 }} variant="h5">
        {post.title}
      </Typography>
      <Typography dangerouslySetInnerHTML={{ __html: post.html }} />
    </Box>
  )
}

export { Post }
