import { atom, useAtom } from "jotai"
import { useCallback, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

const _num = atom<Record<string, number>>({})

type Props<ItemType> = {
  items: Array<ItemType>
  renderItem: (item: ItemType) => JSX.Element | null
  fetchNextPage?: () => void
  withState?: boolean
}

function InfiniteList<ItemType extends { key?: string; id?: string; _id?: string }>({
  renderItem,
  items,
  fetchNextPage,
  withState,
}: Props<ItemType>) {
  const { pathname } = useLocation()
  const [atomNum, seAtomtNum] = useAtom(_num)
  const [stateNum, setStateNum] = useState(1)
  const num = withState ? stateNum : atomNum[pathname]
  const show = items.slice(0, num ?? 1)
  const observer = useRef<IntersectionObserver>()

  const lastItem = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver(
        entries => {
          if (entries.some(entry => entry.isIntersecting)) {
            fetchNextPage?.()
            if (withState) {
              setStateNum(prev => {
                const actual = prev ?? 1
                if (!fetchNextPage && items.length === actual) return prev
                return actual + 1
              })
            } else {
              seAtomtNum(prev => {
                const actual = prev[pathname] ?? 1
                if (!fetchNextPage && items.length === actual) return prev
                return { ...prev, [pathname]: actual + 1 }
              })
            }
          }
        },
        { rootMargin: "1800px" },
      )
      if (node) {
        observer.current.observe(node)
      }
    },
    [fetchNextPage, items.length, pathname, seAtomtNum, withState],
  )

  return (
    <>
      {show.map(item => {
        return (
          <div ref={lastItem} key={item?.key || item?.id || item?._id}>
            {renderItem(item)}
          </div>
        )
      })}
    </>
  )
}

export { InfiniteList }
