import { createTheme } from "@mui/material"

const lightTheme = createTheme({
  typography: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    h1: {
      fontSize: 35,
    },
    h2: {
      fontSize: 30,
    },
    h3: {
      fontSize: 25,
    },
  },
  palette: {
    primary: {
      main: "#56ab29",
      light: "#d3ff00",
      dark: "#36671b",
      contrastText: "#eeeeee",
    },
    secondary: {
      light: "#feaeff",
      main: "#111111",
    },
    background: {
      default: "#eeeeee",
    },
    text: {
      primary: "#111111",
      secondary: "#56ab29",
    },
    info: {
      main: "#7d7d7d",
    },
  },
})

const darkTheme = createTheme({
  typography: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
  },
  palette: {
    primary: {
      main: "#56ab29",
      light: "#d3edc4",
      contrastText: "#111111",
    },
    secondary: {
      main: "#eeeeee",
    },
    background: {
      default: "#111111",
    },
    text: {
      primary: "#eeeeee",
      secondary: "#56ab29",
    },
  },
})

export { darkTheme, lightTheme }
