import { Stack, SvgIcon, SxProps, Typography, Box } from "@mui/material"
import { ReactComponent as SvgLogo } from "./apple.svg"

type Props = {
  size?: number
  sx?: SxProps
}

const Logo = ({ size = 80, sx }: Props) => {
  return (
    <Stack
      sx={{ width: "100%", fontSize: `${size}px`, "& svg": { color: "primary.main" }, ...sx }}
      direction="row"
    >
      <SvgIcon
        inheritViewBox
        component={SvgLogo}
        sx={{
          width: `${size}px`,
          height: "unset",
        }}
      />
      <Box component="div" sx={{ paddingTop: `${size / 4}px`, paddingLeft: `${size / 6}px` }}>
        <Typography sx={{ fontSize: "0.9em", lineHeight: "0.75em" }}>
          In
          <Box component="span" sx={{ color: "text.secondary" }}>
            &
          </Box>
          Out
        </Typography>
        <Typography sx={{ fontSize: "0.45em", lineHeight: "0.7em", whiteSpace: "nowrap" }}>
          Centro Belleza
        </Typography>
      </Box>
    </Stack>
  )
}

export { Logo }
