import { Photo } from "@in-and-out-belleza/api/interfaces"
import { Box, Grid, Paper, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useNav } from "../../hooks/useNav"
import { getCartTotal } from "@in-and-out-belleza/api/utils"

type Family = Record<string, { photo: Photo; count: number; href: string; discounts: number }>

const PromoTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

const Products = () => {
  const { db } = usePublicDb()
  const { productsFamily } = useNav()
  const types = db.products.reduce((acc, item) => {
    const price = getCartTotal(db, [item.id])
    return {
      ...acc,
      [item.brand]: {
        ...acc[item.brand],
        photo: { url: `/assets/product-brands/${item.menuHref}.png`, position: "middle" },
        count: (acc[item.brand]?.count ?? 0) + 1,
        href: `${productsFamily.url(item.menuHref)}/`,
        discounts:
          (acc[item.brand]?.discounts ?? 0) +
          (price.cantSell || price.real === price.total ? 0 : 1),
      },
    }
  }, {} as Family)

  return (
    <Grid container spacing={0.2}>
      {Object.keys(types)
        .sort((first, second) => types[second].discounts - types[first].discounts)
        .map(type => {
          return (
            <Grid item xs={12} sm={6} key={type} height={{ sm: "150px" }}>
              <Paper
                sx={{
                  m: 1,
                  height: "98%",
                  position: "relative",
                  display: "block",
                }}
                component={Link}
                to={types[type].href}
              >
                <Typography fontSize={20} sx={{ p: 1 }}>
                  {type}
                </Typography>
                <Box component="div" sx={{ pl: 1 }}>
                  {types[type].discounts ? (
                    <PromoTypography>
                      {types[type].discounts} producto{types[type].discounts > 1 ? "s" : ""} en
                      PROMO
                    </PromoTypography>
                  ) : null}
                  <Typography fontSize={14}>{types[type].count} productos disponibles</Typography>
                </Box>

                <Box component="div"
                  sx={{
                    background: `url(${types[type].photo.url}) no-repeat center #ddd`,
                    backgroundSize: "cover",
                    backgroundPosition: types[type].photo.position,
                    height: "100%",
                    width: "100%",
                    opacity: 0.3,
                    position: "absolute",
                    top: 0,
                  }}
                />
              </Paper>
            </Grid>
          )
        })}
    </Grid>
  )
}

export { Products }
