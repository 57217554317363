import { useMemo } from "react"

import { usePublicDb } from "@in-and-out-belleza/api/resources"

type SearchItem = {
  id:
    | `TAR-${number}`
    | `PROM-${number}`
    | `PRD-${number}`
    | `TRT-${number}`
    | `BEA-${number}`
    | `TRT-family-${string}`
    | `PRD-family-${string}`
    | `POST-${string}`
    | `NOV-${string}`
  key: string
  type: "products" | "treatments" | "bonusCards" | "promotions" | "beautyParties" | "post" | "news"
  icon: string
  label: string
  description: string
  priority: number
  href: string
  group: string
  cart: Array<string>
  canSellOnline: boolean
  date: number
  photo: string
}

const trtLabels: Record<string, string> = {
  Faciales: "Tratamientos Faciales",
  Corporales: "Tratamientos Corporales",
}

type Props = {
  include?: Array<string>
  isAdmin?: boolean
  includeExpired?: boolean
  uniquePromotions?: boolean
}

const useSearchResults = ({
  include,
  isAdmin = false,
  includeExpired = false,
  uniquePromotions = false,
}: Props): Array<SearchItem> => {
  const { db } = usePublicDb()
  const memo = useMemo(() => {
    const ret = [
      ...db.promotions
        .filter(item => !item.deleted)
        .filter(item => (includeExpired ? true : item.to >= Date.now()))
        .filter(item => (isAdmin ? true : item.from <= Date.now()))
        .filter(item => item.discounts.filter(i => uniquePromotions || i.operator === "and").length)
        .map(item => ({
          type: "promotions" as const,
          group: "Promociones",
          photo: item.photo,
          icon: "discount",
          label: item.title,
          id: item.id,
          key: item.id,
          priority: 1,
          href: item.href,
          description: "PROMOCION DEL MES!",
          cart: item.discounts.filter(i => i.operator === "and").map(d => d.id),
          canSellOnline: true,
          date: item.from,
        })),
      ...(uniquePromotions
        ? []
        : db.promotions
            .filter(item => !item.deleted)
            .filter(item => item.to >= Date.now())
            .filter(item => (isAdmin ? true : item.from <= Date.now()))
            .filter(item => item.discounts.filter(i => i.operator === "or").length)
            .map(item => {
              return item.discounts
                .filter(i => i.operator === "or")
                .map(d => {
                  return {
                    type: "promotions" as const,
                    group: "Promociones",
                    photo: item.photo,
                    icon: "discount",
                    label: item.title,
                    id: item.id,
                    key: `${item.id}-${d.id}`,
                    priority: 1,
                    href: item.href,
                    description: "PROMOCION DEL MES!",
                    cart: [d.id],
                    canSellOnline: true,
                    date: item.from,
                  }
                })
            })
            .flat()),
      ...db.products
        .filter(item => !item.deleted)
        .map(item => ({
          type: "products" as const,
          group: "Productos",
          icon: "products",
          label: item.title,
          photo: item.photo,
          id: item.id,
          key: item.id,
          priority: 4,
          href: item.href,
          description: item.description.replace(/(<([^>]+)>)/gi, ""),
          cart: [item.id],
          canSellOnline: !!item.price,
          date: item.created,
        })),
      ...db.treatments
        .filter(item => !item.deleted)
        .map(item => ({
          type: "treatments" as const,
          group: trtLabels[item.type] ?? item.type,
          icon: "treatments",
          label: `${item.type}: ${item.title}`,
          id: item.id,
          key: item.id,
          priority: 2,
          photo: item.photo,
          href: item.href,
          description: item.description.replace(/(<([^>]+)>)/gi, ""),
          cart: [item.id],
          canSellOnline: !!item.price,
          date: item.creation,
        })),
      ...db.bonusCards
        .filter(item => !item.deleted)
        .map(item => ({
          type: "bonusCards" as const,
          group: "Bonos",
          icon: "discount",
          label: item.title,
          id: item.id,
          key: item.id,
          photo: "",
          priority: 3,
          href: item.href,
          description: item.description.replace(/(<([^>]+)>)/gi, ""),
          cart: [item.id],
          canSellOnline: !!item.price,
          date: item.from,
        })),
      ...db.beautyParties
        .filter(item => !item.deleted)
        .map(item => ({
          type: "beautyParties" as const,
          group: "Beauty Party",
          icon: "beauty",
          label: item.title,
          photo: item.photo,
          id: item.id,
          key: item.id,
          priority: 5,
          href: item.href,
          description: "Beauty party",
          cart: [item.id],
          canSellOnline: false,
          date: item.created,
        })),
      ...db.news
        .filter(item => !item.deleted)
        .map(item => ({
          type: "news" as const,
          group: "Novedad",
          icon: "news",
          label: item.title,
          photo: item.photo,
          id: item.id,
          key: item.id,
          priority: 6,
          href: item.href,
          description: "Novedad In&Out",
          cart: [],
          canSellOnline: false,
          date: item.date,
        })),
      ...db.posts
        .filter(item => item.title.toLowerCase().indexOf("promo") === -1)
        .filter(item => item.html.toLowerCase().indexOf("promo") === -1)
        .map(item => ({
          type: "post" as const,
          group: "Blog post",
          icon: "eye",
          label: item.title,
          photo: "",
          id: `POST-${item.href}` as const,
          key: `POST-${item.href}`,
          priority: 6,
          href: item.href,
          description: "Blog Post",
          cart: [],
          canSellOnline: false,
          date: item.date,
        })),
    ].sort((first, second) => {
      if (first.priority !== second.priority) {
        return first.priority - second.priority
      }
      if (first.group !== second.group) {
        return first.group.localeCompare(second.group)
      }
      if (db.cartPriority.indexOf(first.id) === -1) return 0
      if (db.cartPriority.indexOf(second.id) === -1) return 0
      return db.cartPriority.indexOf(second.id) - db.cartPriority.indexOf(first.id)
    })
    if (include) {
      return ret.filter(item => {
        return include.includes(item.type)
      })
    }
    return ret
  }, [db, include, isAdmin])

  return memo
}

export { useSearchResults }
export type { SearchItem }
