import { useEffect, useState } from "react"

import { UserGoogleEvent, PublicDb } from "@in-and-out-belleza/api/interfaces"
import { Delete } from "@mui/icons-material"
import { Box, Button, Divider, Paper, styled, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useConfirmDialog } from "@in-and-out-belleza/ui"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useDate } from "@in-and-out-belleza/hooks"
import { activePromotions, getCartTotal } from "@in-and-out-belleza/api/utils"
import { DrawerStyled } from "./Drawer.styled"
import { BuyButton } from "../Buttons/BuyButton"
import { useSnackbar } from "notistack"
import { queryClient } from "../../contexts/queryClient"

const BoxStyled = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(),
  "& .MuiPaper-root": {
    padding: theme.spacing(1.5),
  },
  "& hr": {
    margin: "10px",
  },
}))

const FutureBooking = ({ booking }: { booking: UserGoogleEvent }) => {
  const { user } = useAuth()
  const [openBookings, setOpenBookings] = useState<string | undefined>()
  const { longDate, hourInterval } = useDate()
  const { bookingDelete } = useAuth()
  const { mutateAsync, isLoading } = bookingDelete
  const { enqueueSnackbar } = useSnackbar()
  const { Dialog, openDialog } = useConfirmDialog()

  useEffect(() => {
    if (!user.bookings.find(item => item._id === openBookings)) {
      setOpenBookings(undefined)
    }
  }, [user.bookings, openBookings])

  if (!user.nextBookings.length) return null

  return (
    <BoxStyled role="user-booking">
      <Dialog />
      <Paper key={booking._id}>
        <Box component="div" sx={{ display: "flex", flexDirection: "row", mb: 1 }}>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <Typography color="primary" component="em">
              {longDate(booking.start)}
            </Typography>
            <br />
            <Typography fontSize={14} component="em">
              {hourInterval(booking.start, booking.end)}&nbsp;- {booking.center.address}
            </Typography>
          </Box>
        </Box>
        <Box component="div" sx={{ display: "flex", justifyContent: "right", gap: 1 }}>
          <Button variant="contained" color="info" onClick={() => setOpenBookings(booking._id)}>
            MAS DETALLES
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            color="error"
            onClick={async ev => {
              ev.stopPropagation()
              openDialog("", "Quieres eliminar esta cita?")
                .then(() => mutateAsync({ eventId: booking._id }))
                .then(() => {
                  enqueueSnackbar("Tu cita se ha eliminado correctamente")
                  queryClient.invalidateQueries(["day-bookings"])
                })
            }}
          >
            ELIMINA CITA
          </Button>
        </Box>
      </Paper>

      <DrawerStyled
        anchor="bottom"
        open={!!openBookings}
        onClose={() => setOpenBookings(undefined)}
        onOpen={() => {}}
      >
        <BookDetail book={user.bookings.find(item => item._id === openBookings)} />
      </DrawerStyled>
    </BoxStyled>
  )
}

export { FutureBooking }

const BookDetail = ({ book }: { book: UserGoogleEvent | undefined }) => {
  const { db } = usePublicDb()
  const { longDate, hourInterval } = useDate()
  const { bookingDelete } = useAuth()
  const { mutateAsync, isLoading } = bookingDelete
  const { enqueueSnackbar } = useSnackbar()

  if (!book) return null

  const cards = getBonusCardsWithSavings(db, book)
  const promos = getActivePromotionsForTreatments(db, book)
  const price = getCartTotal(db, book.treatments, new Date(book.start).getTime())

  return (
    <div>
      <Typography fontSize={24} color="primary" sx={{ mb: "4px" }}>
        LOS DETALLES DE TU CITA:
        <Button
          role="user-booking-delete"
          color="error"
          variant="contained"
          disabled={isLoading}
          size="small"
          sx={{ float: "right" }}
          onClick={async () => {
            await mutateAsync({ eventId: book._id })
            enqueueSnackbar("Tu cita se ha eliminado correctamente")
            queryClient.invalidateQueries(["day-bookings"])
          }}
        >
          <Delete />
        </Button>
      </Typography>
      <Divider />
      <br />
      <table width="100%">
        <tbody>
          <tr>
            <td width="90px">Fecha:</td>
            <td>{longDate(book.start)}</td>
          </tr>
          <tr>
            <td>Horario:</td>
            <td>{hourInterval(book.start, book.end)}</td>
          </tr>
          <tr>
            <td>Donde:</td>
            <td>{book.center.address}</td>
          </tr>
          {price.real > 0 ? (
            <tr>
              <td>Precio:</td>
              <td>
                {price.real.toFixed(2)}&nbsp;Euro
                <Typography component="span" fontSize={12}>
                  {price.real !== price.total
                    ? ` - Precio sin descuento: ${price.total.toFixed(2)} Euro`
                    : ""}
                </Typography>
              </td>
            </tr>
          ) : null}
          {book.label ? (
            <tr>
              <td>Notas:</td>
              <td>{book.label}</td>
            </tr>
          ) : null}
        </tbody>
      </table>

      <ul style={{ paddingLeft: "20px" }}>
        {book.treatments.map(id => {
          const trt = db.treatments.find(item => item.id === id)
          return (
            <li key={id}>
              {trt?.title} &nbsp;
              <Typography fontSize={14} component="em">
                ({trt?.type})
              </Typography>
            </li>
          )
        })}
      </ul>
      <Divider />
      <br />
      <Typography>Quieres Ahorrar?</Typography>
      {promos.map(promo => (
        <Paper key={promo.id}>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <Typography lineHeight="20px">{promo.title}</Typography>
                  <Typography fontSize={12}>hasta {longDate(promo.to)}</Typography>
                </td>
                <td rowSpan={2} width="120px">
                  <BuyButton cart={promo.discounts.map(item => item.id)} />
                </td>
              </tr>
            </tbody>
          </table>
        </Paper>
      ))}

      {cards.map(card => (
        <Paper key={card.id}>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <Typography lineHeight="20px">
                    BONO de:
                    <br />
                    {card.title}
                  </Typography>
                </td>
                <td rowSpan={2} width="120px">
                  <BuyButton cart={[card.id]} />
                </td>
              </tr>
            </tbody>
          </table>
        </Paper>
      ))}
    </div>
  )
}

const getBonusCardsWithSavings = (db: PublicDb, book: UserGoogleEvent) => {
  return db.bonusCards
    .filter(card => card.treatments.find(sub => book.treatments.includes(sub.id)))
    .sort((a, b) => a.position - b.position)
}

const getActivePromotionsForTreatments = (db: PublicDb, book: UserGoogleEvent) => {
  return activePromotions(db).filter(promo =>
    promo.discounts.filter(i => i.operator === "and").find(dis => book.treatments.includes(dis.id)),
  )
}
