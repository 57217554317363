import { AdminDb, AdminNews } from "../admin-api-interfaces"
import { BonusCard, Photo, PublicDb } from "../api-interfaces"

const getNewsHref = (item: AdminNews): string => {
  return `/es/novedades/${item.id}-${parseHref(item.title)}`
}

function parseHref(item: string) {
  return item
    .toLowerCase()
    .trim()
    .replace(/&/g, " and ")
    .replace(/[`~!¡@#$%^&*()´_|+=?¿;:'",<>{}[\]\\/]/gi, "")
    .replace(/à/g, "a")
    .replace(/ä/g, "a")
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/è/g, "e")
    .replace(/ë/g, "e")
    .replace(/ï/g, "i")
    .replace(/í/g, "i")
    .replace(/ì/g, "i")
    .replace(/ö/g, "o")
    .replace(/ò/g, "o")
    .replace(/ó/g, "o")
    .replace(/ó/g, "o")
    .replace(/ü/g, "u")
    .replace(/ù/g, "u")
    .replace(/ú/g, "u")
    .replace(/ñ/g, "n")
    .replace("&", " and ")
    .replace(/\s\s+/g, " ")
    .trim()
    .replace(/\s/g, "-")
}

const treatmentsPhotoTypes: Record<string, Photo> = {
  "Depilación con Hilo": {
    url: "/google/drive/fotos/desktop.001---depilacion-con-hilo-inout-malaga.jpg",
    position: "center center",
  },
  Nanobrows: {
    url: "/google/drive/fotos/desktop.078---cejas-perfectas-nanobrows-microblading-phybrows-malaga-depilacion-hilo.jpg",
    position: "top center",
  },
  Faciales: {
    url: "/google/drive/fotos/desktop.021---mascarilla-de-oro-malaga-in-and-out.jpeg",
    position: "center center",
  },
  Corporales: {
    url: "/google/drive/fotos/desktop.056-in-and-out-malaga-centro-de-belleza.jpg",
    position: "bottom center",
  },
  "Natura Bisse": {
    url: "/google/drive/fotos/desktop.066---vitaminac-natura-bisse-cremas-.jpg",
    position: "center center",
  },
  "Radiofrecuencia Indiba": {
    url: "/google/drive/fotos/desktop.023---indiba-professional.jpeg",
    position: "center center",
  },
  Beauty: {
    url: "https://www.inandoutbelleza.es/google/drive/fotos/desktop.054--opi-chanel-ysl-manicura-nails-beauty-centro-de-belleza.jpg?v=1.3.235",
    position: "center center",
  },
  Masajes: {
    url: "https://www.inandoutbelleza.es/google/drive/fotos/desktop.051--cabina-belleza-in-and-out-malaga-marbella-andalucia.jpg?v=1.3.235",
    position: "bottom center",
  },
  "Beauty Day": {
    url: "https://www.inandoutbelleza.es/google/drive/fotos/desktop.053--manzana-centro-de-belleza-beauty-la-gran-manzana.jpg?v=1.3.235",
    position: "top center",
  },
  "Depilación Elèctrica": {
    url: "https://www.inandoutbelleza.es/google/drive/fotos/desktop.058---centro-de-belleza-in-and-out-lujo-cabina-cosmetica.jpg?v=1.3.235",
    position: "center center",
  },
  "Depilación con Cera": {
    url: "https://www.inandoutbelleza.es/google/drive/fotos/desktop.064---malaga-relajacion-masajes-indiba-centro-de-belleza-.jpg?v=1.3.235",
    position: "center center",
  },
  Microblading: {
    url: "https://www.inandoutbelleza.es/google/drive/fotos/desktop.073---microbladingnanobrowspmu4.jpg?v=1.3.235",
    position: "top center",
  },
}

const fromRawDb = (db: AdminDb) => {
  const workers = db.workers
  const treatments = db.treatments
    .map(trt => ({
      ...trt,
      menuHref: parseHref(trt.type),
      photoType: treatmentsPhotoTypes[trt.type] ?? { url: "", position: "" },
      href: `/es/tratamientos/${parseHref(trt.type)}/${parseHref(trt.title)}`,
    }))
    .sort((first, second) => {
      if (first.type === second.type) return first.title.localeCompare(second.title)
      return first.type.localeCompare(second.type)
    })

  const bonusCards: Array<BonusCard> = db.bonusCards
    .map(card => ({
      ...card,
      href: `/es/bonos/${parseHref(card.title)}`,
    }))
    .sort((first, second) => db.cartPriority.indexOf(second.id) - db.cartPriority.indexOf(first.id))

  const products = db.products
    .map(prod => ({
      ...prod,
      href: `/es/productos/${parseHref(prod.brand)}/${parseHref(prod.title)}`,
      menuHref: parseHref(prod.brand),
      photoLink: `https://www.inandoutbelleza.es/google/drive/productos/desktop.${prod.photo}`,
      link: `https://www.inandoutbelleza.es/es/cesta?addCart=${prod.id}`,
    }))
    .sort((first, second) => db.cartPriority.indexOf(second.id) - db.cartPriority.indexOf(first.id))

  const ret: PublicDb = {
    products,
    centers: db.centers.filter(center => !center.closed && center.physical),
    allCenters: db.centers,
    workers,
    equipments: db.equipments,
    treatments,
    bonusCards,
    closures: db.closures,
    promotions: db.promotions.map(promo => ({
      ...promo,
      href: `/es/promociones/${parseHref(`${promo.id}-${promo.title}`)}`,
    })),
    photos: db.photos
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value),
    news: [
      ...db.news.map(item => ({
        ...item,
        href: getNewsHref(item),
        cart: [],
      })),
    ].sort((first, second) => second.date - first.date),
    reviews: db.reviews,
    beautyParties: db.beautyParties.map(item => ({
      ...item,
      href: `/es/beauty-parties/${parseHref(item.title)}`,
    })),
    cartPriority: db.cartPriority,
    calendars: db.calendars,
    posts: db.posts,
    processes: db.processes,
    adminUsers: db.adminUsers,
    settings: db.settings,
  }
  return ret
}

export { fromRawDb, parseHref, getNewsHref }
