import { Dispatch } from "react"
import { SetStateAction } from "jotai"
import ReactJson from "react-json-view"
import { Box, Modal } from "@mui/material"
import { FlowEvent } from "../../types/flow.types"

type Props = {
  open: FlowEvent | null
  setOpen: Dispatch<SetStateAction<FlowEvent | null>>
}

const FlowModalJsonViewer = ({ open, setOpen }: Props) => {
  return (
    <Modal
      open={open !== null}
      onClose={() => setOpen(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="div"
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: "80%",
          p: 4,
          "& .string-value": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "60vw",
            display: "inline-block",
            overflow: "hidden",
          },
        }}
      >
        <ReactJson enableClipboard={false} displayDataTypes={false} src={open ?? {}} />
        <Box component="div" sx={{ py: 2 }}></Box>
      </Box>
    </Modal>
  )
}

export { FlowModalJsonViewer }
