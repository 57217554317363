import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  styled,
  SvgIcon,
} from "@mui/material"

import { ReactComponent as SvgLogo } from "../../../assets/apple.svg"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { useNav } from "../../hooks/useNav"
import { useBooking } from "../../atoms/atomBooking"
import { useCart } from "../../atoms/atomCart"

const Wrapper = styled(Paper)(({ theme }) => {
  const { isStandalone } = useDeviceInfo()
  return {
    position: "fixed",
    zIndex: 2,
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: isStandalone ? "15px" : 0,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    "& a": {
      color: theme.palette.primary.contrastText,
    },
  }
})

const Navigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  "& button": {
    color: theme.palette.secondary.contrastText,
  },
  "& svg": {
    paddingBottom: "4px",
    fill: theme.palette.secondary.contrastText,
  },
  "& .Mui-selected": {
    color: theme.palette.primary.main,
  },
  "& .Mui-selected svg": {
    fill: theme.palette.primary.main,
  },
}))

const MobileBottom = () => {
  const { user } = useAuth()
  const [value, setValue] = useState(0)
  const { home, shop, booking, account, gift } = useNav()
  const { numOfArticles } = useCart()
  const { pathname } = useLocation()
  const { setTreatments } = useBooking()

  useEffect(() => {
    setValue([home.url(), gift.url(), booking.url(), shop.url(), account.url()].indexOf(pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Wrapper elevation={3}>
      <Navigation
        showLabels
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue)
        }}
      >
        <BottomNavigationAction
          sx={{ minWidth: 60 }}
          component={Link}
          to={home.url()}
          label={"In&Out"}
          icon={<SvgIcon component={SvgLogo} inheritViewBox />}
        />
        <BottomNavigationAction
          sx={{ minWidth: 60 }}
          component={Link}
          to={gift.url()}
          label="Regala"
          icon={<BeautyIcon name="products" size={24} />}
        />
        <BottomNavigationAction
          sx={{ minWidth: 60 }}
          component={Link}
          onClick={() => {
            if (booking.isPath()) {
              setTreatments([])
            }
          }}
          to={booking.url()}
          label="Citas"
          icon={
            <Badge badgeContent={user.nextBookings.length} color="error">
              <BeautyIcon name="calendar" size={24} />
            </Badge>
          }
        />
        <BottomNavigationAction
          sx={{ minWidth: 60 }}
          component={Link}
          to={shop.url()}
          label="Compra"
          icon={
            <Badge badgeContent={numOfArticles()} color="error">
              <BeautyIcon name="make-up" size={24} />
            </Badge>
          }
        />

        <BottomNavigationAction
          sx={{ minWidth: 60 }}
          component={Link}
          to={account.url()}
          label="Cuenta"
          icon={
            <Badge badgeContent={0} color="error">
              <BeautyIcon name="user" size={24} />
            </Badge>
          }
        />
      </Navigation>
    </Wrapper>
  )
}

export { MobileBottom }
