import {
  AdminDb,
  AdminBooking,
  GoogleEvent,
  PublicDb,
  isTreatment,
} from "@in-and-out-belleza/api/interfaces"

import { dateRangeOverlaps, getDayCalendar, getTreatmentsDuration, getUTCDate } from "./publicDb"

type WorkersEvents = Array<{ workerIndex: number; events: Array<AdminBooking> }>

type AvailableHour = {
  timestamp: number
  duration: number
  workerIndex: number
}

type CentersAvailableHours = Record<number, Array<AvailableHour>>

const getCenterInstallation = (
  db: AdminDb | PublicDb,
  treatments: Array<string>,
  centerId: string,
) => {
  const usedEquipments = treatments
    .map(id => db.treatments.find(i => i.id === id)?.equipments ?? [])
    .flat()
  const installations = usedEquipments
    .map(id => db.equipments.find(r => r.id === id)?.installations)
    .flat()
    .filter(i => i?.centerId === centerId)
  return installations[0] ?? { centerId, quantity: 0, rooms: [] }
}

const getEquipments = (db: AdminDb | PublicDb, treatments: Array<string>) => {
  return treatments
    .map(id => db.treatments.find(i => i.id === id))
    .filter(isTreatment)
    .map(i => i.equipments)
    .flat()
}

const needsRoom = (db: AdminDb | PublicDb, treatments: Array<string>) =>
  treatments.filter(id => db.treatments.find(i => i.id === id)?.needRoom).length > 0

const needsEquipment = (db: AdminDb | PublicDb, treatments: Array<string>) =>
  treatments.filter(id => (db.treatments.find(i => i.id === id)?.equipments?.length ?? 0) > 0)
    .length > 0

const needSpecificEquipments = (
  db: AdminDb | PublicDb,
  treatments: Array<string>,
  equipmentIds: Array<string>,
) => {
  return getEquipments(db, treatments).filter(eqId => equipmentIds.includes(eqId)).length > 0
}

const getWorkerHours = (
  workerCal: Array<number>,
  duration: number,
  timestamp: number,
  events: Array<AdminBooking>,
) => {
  const [, workerIndex, from, to] = workerCal
  const hours: Array<AvailableHour> = []
  for (let forFrom = from; forFrom <= to - duration; forFrom += 0.25) {
    const utcFrom = getUTCDate(timestamp, forFrom)
    const utcTo = getUTCDate(timestamp, forFrom + duration)
    const isBusy = !!events.find(({ start, end }) => {
      const busyStart = new Date(start)
      const busyEnd = new Date(end)
      return dateRangeOverlaps(utcFrom, utcTo, busyStart, busyEnd)
    })
    if (!isBusy) {
      hours.push({ timestamp: getUTCDate(timestamp, forFrom).getTime(), duration, workerIndex })
    }
  }
  return hours
}

const excludeOccupiedRooms = (
  db: AdminDb | PublicDb,
  cal: Array<Array<number>>,
  workerCal: Array<number>,
  duration: number,
  original: Array<AvailableHour>,
  events: WorkersEvents,
) => {
  let hours: Array<AvailableHour> = original.slice(0)
  const [centerIndex] = workerCal
  const { rooms = [] } = db.centers.find(c => c.index === centerIndex) || {}
  original.forEach(hour => {
    let numOfRooms = rooms.length
    const utcFrom = new Date(hour.timestamp)
    const utcTo = new Date(hour.timestamp + duration * 60 * 60 * 1000)
    events.forEach(({ workerIndex, events: subEvents }) => {
      const subWorkerCal = cal.find(sub => sub[1] === workerIndex) || [0, 0, 0, 0]
      const [subCenterIndex] = subWorkerCal
      if (subCenterIndex !== centerIndex) return
      subEvents.forEach(({ start, end, processId, treatments }) => {
        const subStart = new Date(start)
        const subEnd = new Date(end)
        const ids = treatments.length
          ? treatments
          : db.processes.find(i => i.processId === processId)?.treatments
        const subNeedroom = needsRoom(db, ids ?? [])
        if (subNeedroom && dateRangeOverlaps(subStart, subEnd, utcFrom, utcTo)) {
          numOfRooms = numOfRooms - 1
          if (numOfRooms <= 0) {
            hours = hours.filter(({ timestamp, duration }) => {
              const from = new Date(timestamp)
              const to = new Date(timestamp + duration * 60 * 60 * 1000)
              return !dateRangeOverlaps(subStart, subEnd, from, to)
            })
          }
        }
      })
    })
  })

  return hours
}

const excludeOccupiedEquipments = (
  db: AdminDb | PublicDb,
  originalTreatments: Array<string>,
  cal: Array<Array<number>>,
  workerCal: Array<number>,
  duration: number,
  original: Array<AvailableHour>,
  events: WorkersEvents,
) => {
  let hours: Array<AvailableHour> = original.slice(0)
  const originalEquipments = getEquipments(db, originalTreatments)
  const [centerIndex] = workerCal
  const centerId = db.centers.find(i => i.index === centerIndex)?.id ?? ""
  const { rooms, quantity } = getCenterInstallation(db, originalTreatments, centerId)
  const originalRooms = rooms.slice(0)
  let numOfEquipments = quantity

  if (quantity === 0) return []

  original.forEach(hour => {
    const utcFrom = new Date(hour.timestamp)
    const utcTo = new Date(hour.timestamp + duration * 60 * 60 * 1000)
    events.forEach(({ workerIndex, events: workerEvents }) => {
      const subWorkerCal = cal.find(sub => sub[1] === workerIndex) || [0, 0, 0, 0]
      const [subCenterIndex] = subWorkerCal
      if (subCenterIndex !== centerIndex) return
      workerEvents.forEach(({ start, end, processId, treatments }) => {
        const subStart = new Date(start)
        const subEnd = new Date(end)
        const ids = treatments.length
          ? treatments
          : db.processes.find(i => i.processId === processId)?.treatments ?? []

        if (dateRangeOverlaps(subStart, subEnd, utcFrom, utcTo)) {
          const subNeedEquipment = needSpecificEquipments(db, ids ?? [], originalEquipments)
          if (subNeedEquipment) {
            numOfEquipments = numOfEquipments - 1
            if (numOfEquipments <= 0) {
              hours = hours.filter(({ timestamp, duration }) => {
                const from = new Date(timestamp)
                const to = new Date(timestamp + duration * 60 * 60 * 1000)
                return !dateRangeOverlaps(subStart, subEnd, from, to)
              })
            }
          }
          const { rooms } = getCenterInstallation(db, ids, centerId)
          const otherRooms = rooms.slice(0)

          if (otherRooms.length) {
            rooms.forEach(room => {
              const index = originalRooms.indexOf(room)
              if (index !== -1 && rooms.length === 1) originalRooms.splice(index, 1)
            })
            if (originalRooms.length === 0) {
              hours = hours.filter(({ timestamp, duration }) => {
                const from = new Date(timestamp)
                const to = new Date(timestamp + duration * 60 * 60 * 1000)
                return !dateRangeOverlaps(subStart, subEnd, from, to)
              })
            }
          }
        }
      })
    })
  })
  return hours
}

const getAvailableHours = (
  db: AdminDb | PublicDb,
  events: WorkersEvents,
  timestamp: number,
  treatments: Array<string>,
  duration?: number,
): CentersAvailableHours => {
  const cal = getDayCalendar(db, timestamp)
  return db.workers.reduce((acc, worker) => {
    const trtDuration = duration ?? getTreatmentsDuration(db, treatments, worker.id) / 60
    const workerCal = cal.find(sub => sub[1] === worker.index) || [0, 0, 0, 0]
    const [centerIndex, workerIndex] = workerCal
    const arr = acc[centerIndex] ?? []
    if (trtDuration > 0) {
      const evs = events.find(e => e.workerIndex === workerIndex)?.events ?? []
      const hours = getWorkerHours(workerCal, trtDuration, timestamp, evs)
      const needRoom = needsRoom(db, treatments)
      const freeHours = needRoom
        ? excludeOccupiedRooms(db, cal, workerCal, trtDuration, hours, events)
        : hours
      const needEquipment = needsEquipment(db, treatments)
      const freeEquipment = needEquipment
        ? excludeOccupiedEquipments(db, treatments, cal, workerCal, trtDuration, freeHours, events)
        : freeHours
      arr.push(...freeEquipment)
    }
    return { ...acc, [centerIndex]: arr }
  }, {} as CentersAvailableHours)
}

const getEventProcessId = (db: AdminDb | PublicDb, event: GoogleEvent | AdminBooking) => {
  const ids = event.treatments.length
    ? event.treatments
    : db.processes.find(i => i.processId === event.processId)?.treatments ?? []
  const trtProc = db.processes.find(p => p.treatments.find(id => ids.includes(id)))
  if (trtProc) return trtProc.processId
  const [eqId] = getEquipments(db, ids)
  if (!eqId) return event.processId ?? 98
  const trts = db.treatments.filter(i => !!i.equipments.find(id => id === eqId))
  const proc = db.processes.find(p => trts.find(t => p.treatments.includes(t.id)))
  if (!proc) return event.processId ?? 98
  return proc.processId
}

const getEventTreatments = (db: AdminDb | PublicDb, event: GoogleEvent | AdminBooking) => {
  if (event.treatments.length) return event.treatments
  if (event.processId) {
    const process = db.processes.find(i => i.processId === event.processId)
    return process?.treatments ?? []
  }
  return []
}

export { getAvailableHours, getEventProcessId, getEventTreatments }
