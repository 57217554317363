import { Box, styled } from "@mui/material"

type Props = {
  logged: boolean
}

const BoxStyled = styled(Box, { shouldForwardProp: prop => prop !== "logged" })<Props>(
  ({ theme, logged }) => ({
    flexGrow: 1,
    "& a > div >  svg": {
      fill: theme.palette.text.secondary,
    },
    "& a svg": {
      fill: "white",
    },
    "& a svg[data-name=user]": {
      fill: logged ? theme.palette.text.secondary : "white",
    },
  }),
)

export { BoxStyled }
