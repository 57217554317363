import { useState } from "react"
import { Controller, useForm } from "react-hook-form"

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material"

import { AppleRating } from "../AppleRating/AppleRating"
import { queryClient } from "../../contexts/queryClient"
import { useSnackbar } from "notistack"

const Review = () => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<number | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ text: string; rate: number }>()

  return (
    <Box component="div">
      <Paper sx={{ p: 1, backgroundColor: "#badec7" }}>
        <Typography>Como valoras nuestro equipo?</Typography>
        <AppleRating precision={0.5} value={value} onChange={(_, newValue) => setValue(newValue)} />
        <Button sx={{ float: "right" }} onClick={() => setOpen(true)}>
          DEJA VALORACION
        </Button>
      </Paper>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form
          noValidate
          onSubmit={handleSubmit(({ text, rate }) => {
            fetch("/api/reviews", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ rate: Number(rate), description: text, lang: "es" }),
            }).then(() => {
              queryClient.invalidateQueries(["public-db"])
              queryClient.refetchQueries(["public-db"])
              setOpen(false)
              enqueueSnackbar("Gracias por tu opnion!", { variant: "info" })
            })
          })}
        >
          <DialogTitle>TU OPINION ES IMPORTANTE:</DialogTitle>
          <DialogContent>
            <Controller
              name="rate"
              control={control}
              rules={{ required: true }}
              defaultValue={value ?? 0}
              render={props => (
                <Box component="div">
                  <AppleRating
                    name="rate"
                    precision={0.5}
                    value={value}
                    onChange={(_, newValue) => {
                      setValue(newValue)
                      if (newValue !== null) {
                        props.field.onChange(newValue)
                      }
                    }}
                  />
                  <Box component="div" sx={{ display: "inline-block", ml: 2 }}>
                    {value ? `${value.toFixed(1)}` : ""}
                    <Typography color="error" fontSize={12}>
                      {errors.rate ? "Cuanto nos valoras?" : ""}
                    </Typography>
                  </Box>
                </Box>
              )}
            />

            <TextField
              error={errors.text !== undefined}
              multiline
              rows={6}
              placeholder="Escribe tu comentario"
              fullWidth
              margin="normal"
              type="text"
              variant="outlined"
              {...register("text", { required: true })}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">DEJA VALORACION</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}

export { Review }
