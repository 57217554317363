import { Typography } from "@mui/material"
import { Logo } from "../Logo"
import { ErrorPageStyled } from "./ErrorPage.styles"

const ErrorPage = () => {
  return (
    <ErrorPageStyled>
      <Typography variant="h2">
        <strong>Oops!</strong>
      </Typography>
      <Logo size={80} />
      <Typography variant="h6">
        <strong>
          Algo imprevisto ha pasado!
          <br />
          Lo arreglaremo lo antes posible.
          <br />
          <br />
          Disculpen la molestia
        </strong>
      </Typography>
    </ErrorPageStyled>
  )
}

export { ErrorPage }
