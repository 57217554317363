import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { AuthContextProvider } from "./AuthContextProvider"

type ProviderProps = {
  queryClient?: QueryClient
  onLogin?: () => void
  onLogout?: () => void
  accountEmailSent: string
  adminAuth?: boolean
  isElectron?: boolean
}

const AuthProvider = ({
  queryClient,
  onLogin,
  onLogout,
  children,
  accountEmailSent,
  adminAuth,
  isElectron = false,
}: React.PropsWithChildren<ProviderProps>) => {
  const client = queryClient ?? new QueryClient()

  const handleLogout = () => {
    if (onLogout !== undefined) {
      onLogout()
      client.clear()
    }
  }

  return (
    <QueryClientProvider client={client}>
      <AuthContextProvider
        isElectron={isElectron}
        onLogin={onLogin}
        onLogout={handleLogout}
        accountEmailSent={accountEmailSent}
        adminAuth={adminAuth ?? false}
        queryClient={client}
      >
        {children}
      </AuthContextProvider>
    </QueryClientProvider>
  )
}

export { AuthProvider }
