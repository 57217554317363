import { Middleware } from "../../utils/Middleware"

type Cb = (event: Event) => void
type Listener = {
  type: string
  callback: Cb
  listener: (event: Event) => unknown
}
export const EventListenerMiddleware = function (global = window, Global = Window) {
  const _oldAddWindow = Global.prototype.addEventListener
  const _oldRemoveWindow = Global.prototype.removeEventListener
  const _oldAddElement = global.Element.prototype.addEventListener
  const _oldRemoveElement = global.Element.prototype.removeEventListener

  const listeners: Array<Listener> = []
  const middleware = new Middleware<[Event, { type: string; callback: Cb }]>()

  const md = middleware.use(function listen(this: Request, next, ...args) {
    const [event, { callback }] = args
    callback.call(this, event)
    next(...args)
    return args
  })

  Element.prototype.addEventListener = function (
    type: string,
    callback: Cb,
    options?: boolean | AddEventListenerOptions,
  ) {
    const listener = (event: Event) => md(event, { type, callback })
    listeners.push({
      type,
      callback,
      listener,
    })
    _oldAddElement.call(this, type, listener, options)
  }

  Element.prototype.removeEventListener = function (type: string, callback: Cb) {
    const item = listeners.find(
      listener => listener.callback === callback && listener.type === type,
    )
    if (!item) return undefined
    return _oldRemoveElement.call(this, type, item.listener)
  }

  // eslint-disable-next-line no-param-reassign
  Global.prototype.addEventListener = function (
    type: string,
    callback: Cb,
    options?: boolean | AddEventListenerOptions,
  ) {
    const listener = (event: Event) => md(event, { type, callback })
    listeners.push({
      type,
      callback,
      listener,
    })
    _oldAddWindow.call(this, type, listener, options)
  }

  // eslint-disable-next-line no-param-reassign
  Global.prototype.removeEventListener = function (type: string, callback: Cb) {
    const item = listeners.find(
      listener => listener.callback === callback && listener.type === type,
    )
    if (!item) return undefined
    return _oldRemoveWindow.call(this, type, item.listener)
  }

  global.document.addEventListener("DOMContentLoaded", () => {
    ;["click", "mousedown", "mouseup"].forEach(type => {
      document.addEventListener(type, ev => {
        setTimeout(() =>
          md(ev, {
            type,
            callback: () => true,
          }),
        )
      })
    })
  })

  return middleware
}
