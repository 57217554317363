import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import { useLocation } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { BeautyPartyIntro } from "./BeautyPartyIntro"
import { BeautyPartyOutro } from "./BeautyPartyOutro"
import { NotFoundPage } from "../NotFoundPage"
import { CarouselBeautyParties } from "../Content/Carousel/BeautyParties"

const BeautyParty = () => {
  const { db } = usePublicDb()
  const { pathname } = useLocation()
  const party = db.beautyParties.find(item => item.href === pathname)

  if (!party) return <NotFoundPage carousel="beautyParties" />

  return (
    <Box component="div">
      <Paper sx={{ m: 1, p: 1 }}>
        <Typography variant="h6">{party.title}</Typography>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <img width="100%" src={party.photo} alt={party.title} />
        <div dangerouslySetInnerHTML={{ __html: party.description }}></div>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box component="div" sx={{ display: "flex" }}>
          <Typography sx={{ flexGrow: 1 }}>PRECIO: {party.price.toFixed(2)} Euro</Typography>
          <Button startIcon={<BeautyIcon name="phone" size={20} />}>LLAMANOS</Button>
        </Box>
      </Paper>
      <BeautyPartyIntro />
      <Typography sx={{ ml: 2, fontSize: 24 }}>OTROS PACKS:</Typography>
      <CarouselBeautyParties interval={2000} excludeId={party.id} desktopSlides={3} />
      <BeautyPartyOutro />
    </Box>
  )
}

export { BeautyParty }
