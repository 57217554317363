import { FlowHttpRequestEvent } from "../../types/flow.types"
import { Middleware } from "../../utils/Middleware"

interface Request extends XMLHttpRequest {
  sendNext: (() => void) | undefined
  sendCallback: XMLHttpRequest["send"] | undefined
  method: string | undefined
  sendBody: string | undefined
  url: string | URL
  resolveWithMock: (event: FlowHttpRequestEvent) => void
}

export const XMLHttpRequestMiddleware = function ({ mock = false } = {}) {
  const middleware = new Middleware<
    [Request, Document | XMLHttpRequestBodyInit | null | undefined]
  >()

  const send = middleware.use(function (next, request, data) {
    Object.assign(request, {
      sendNext: () => {
        next(request, data)
      },
    })
    request.sendCallback?.(data)
    return [request, data]
  })

  class _XMLHttpRequest extends XMLHttpRequest {
    private readyStateCb?: () => void
    private onLoadEndCb?: () => void
    private onLoadCb?: () => void
    public sendNext: (() => void) | undefined
    public sendCallback: XMLHttpRequest["send"] | undefined
    public method: string | undefined
    public url: string | URL
    public sendBody: string | undefined

    constructor() {
      super()
      this.url = ""
    }

    set onreadystatechange(callback: (this: XMLHttpRequest, ev: Event) => unknown) {
      this.readyStateCb = () => callback.call(this, new CustomEvent(""))
      if (mock) return
      super.onreadystatechange = event => {
        if (callback) callback.call(this, event)
        if (this.readyState === 4 && this.sendNext) {
          this.sendNext()
        }
      }
    }

    set onloadend(callback: (this: XMLHttpRequest, ev: ProgressEvent) => unknown) {
      this.onLoadEndCb = () => callback.call(this, new ProgressEvent("loadend"))
      if (mock) return
      super.onloadend = event => {
        if (callback) callback.call(this, event)
        if (this.readyState === 4 && this.sendNext) {
          this.sendNext()
        }
      }
    }

    set onload(callback: (this: XMLHttpRequest, ev: ProgressEvent) => unknown) {
      this.onLoadCb = () => callback.call(this, new ProgressEvent("load"))
      if (mock) return
      super.onload = event => {
        if (callback) callback.call(this, event)
        if (this.readyState === 4 && this.sendNext) {
          this.sendNext()
        }
      }
    }

    open(method: string, url: string | URL, ...args: []): void {
      this.method = method
      this.url = url
      return super.open(method, url, ...args)
    }

    send(body?: Document | XMLHttpRequestBodyInit | null) {
      this.sendCallback = super.send
      this.sendBody = body?.toString() ?? ""
      return send(this, body)
    }

    resolveWithMock(event: FlowHttpRequestEvent) {
      Object.defineProperty(this, "responseText", { value: event.data.responseText })
      Object.defineProperty(this, "status", { value: event.data.status })
      Object.defineProperty(this, "statusText", { value: event.data.statusText })
      Object.defineProperty(this, "readyState", { value: 4 })
      Object.defineProperty(this, "getAllResponseHeaders", {
        value: () => event.data.responseHeaders,
      })
      this.onLoadEndCb?.()
      this.readyStateCb?.()
      this.onLoadCb?.()
    }
  }

  window.XMLHttpRequest = _XMLHttpRequest

  return middleware
}
