import { Box, Button, IconButton, Paper, Typography } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useNav } from "../../../hooks/useNav"
import { AppleRating } from "../../AppleRating/AppleRating"
import { Link, Link as RouterLink } from "react-router-dom"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"

const HomeStarting = () => {
  const { isMobile } = useDeviceInfo()
  const { db } = usePublicDb()
  const { reviews, treatments, products, bonuses, hours, locations, callUs } = useNav()

  if (isMobile) {
    return (
      <Box component="div">
        <Paper
          component={Link}
          to="/es/trabaja-con-nosotros/"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          ¡TRABAJA CON NOSOTROS!
        </Paper>
        <Paper sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <IconButton
            sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
            component={RouterLink}
            to={callUs.url()}
          >
            <BeautyIcon name="phone" size={32} />
            <Typography sx={{ paddingTop: "5px" }} variant="body2">
              LLAMANOS
            </Typography>
          </IconButton>
          <IconButton
            sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
            component={RouterLink}
            to={locations.url()}
          >
            <BeautyIcon name="location" size={32} />
            <Typography sx={{ paddingTop: "5px" }} variant="body2">
              COMO LLEGAR
            </Typography>
          </IconButton>
          <IconButton
            sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
            component={RouterLink}
            to={hours.url()}
          >
            <BeautyIcon name="time" size={32} />
            <Typography sx={{ paddingTop: "5px" }} variant="body2">
              HORARIOS
            </Typography>
          </IconButton>
        </Paper>
        <div style={{ height: "12px" }} />
        <Paper sx={{ p: 1, display: "flex" }}>
          <Typography sx={{ flexGrow: 1 }}>
            <AppleRating value={db.reviews.average} disabled />
            <Typography component="span" sx={{ whiteSpace: "nowrap" }}>
              {db.reviews.average.toFixed(1)} de {db.reviews.count} reseñas
            </Typography>
          </Typography>
          <Button variant="contained" onClick={() => reviews.navigate()}>
            VER
          </Button>
        </Paper>
        <div style={{ height: "12px" }} />
        <Paper sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            component={RouterLink}
            to={treatments.url()}
            sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
          >
            <BeautyIcon name="treatments" size={32} />
            <Typography sx={{ paddingTop: "5px" }} variant="body2">
              TRATAMIENTOS
            </Typography>
          </IconButton>
          <IconButton
            component={RouterLink}
            to={bonuses.url()}
            sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
          >
            <BeautyIcon name="discount" size={32} />
            <Typography sx={{ paddingTop: "5px" }} variant="body2">
              BONOS
            </Typography>
          </IconButton>
          <IconButton
            component={RouterLink}
            to={products.url()}
            sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
          >
            <BeautyIcon name="products" size={32} />
            <Typography sx={{ paddingTop: "5px" }} variant="body2">
              PRODUCTOS
            </Typography>
          </IconButton>
        </Paper>
      </Box>
    )
  }
  return (
    <Box component="div">
      <Paper
        component={Link}
        to="/es/trabaja-con-nosotros/"
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 1,
        }}
      >
        ¡TRABAJA CON NOSOTROS!
      </Paper>
      <Paper sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          component={RouterLink}
          to={treatments.url()}
          sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
        >
          <BeautyIcon name="treatments" size={32} />
          <Typography sx={{ paddingTop: "5px" }} variant="body2">
            TRATAMIENTOS
          </Typography>
        </IconButton>
        <IconButton
          component={RouterLink}
          to={bonuses.url()}
          sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
        >
          <BeautyIcon name="discount" size={32} />
          <Typography sx={{ paddingTop: "5px" }} variant="body2">
            BONOS
          </Typography>
        </IconButton>
        <IconButton
          component={RouterLink}
          to={products.url()}
          sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
        >
          <BeautyIcon name="products" size={32} />
          <Typography sx={{ paddingTop: "5px" }} variant="body2">
            PRODUCTOS
          </Typography>
        </IconButton>
      </Paper>
      <div style={{ height: "12px" }} />
      <Paper sx={{ p: 1 }}>
        <AppleRating value={db.reviews.average} disabled /> {db.reviews.average.toFixed(1)} de{" "}
        {db.reviews.count} reseñas
        <Button style={{ float: "right" }} variant="contained" onClick={() => reviews.navigate()}>
          VER
        </Button>
      </Paper>
    </Box>
  )
}

export { HomeStarting }
