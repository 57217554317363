import { Box, Button, Collapse, Divider, Typography } from "@mui/material"
import { GenericDialog, Logo } from "@in-and-out-belleza/ui"
import { useState } from "react"
import { Privacy } from "./Content/Privacy"
import { Flow } from "@in-and-out-belleza/flow"

const WelcomeDialog = () => {
  const skip = window.skipWelcomeDialog ?? false
  const defaultShow = !skip && !window.localStorage.getItem("inandout.acceptPrivacy")
  const [open, setOpen] = useState(defaultShow)
  const [show, setShow] = useState(false)

  return (
    <GenericDialog title="Bievenido al sitio web de In&Out" open={open}>
      <Flow
        elements={[
          {
            role: "container",
            selector: ".MuiDialog-container .MuiPaper-root",
            portal: true,
            zIndex: 1301,
          },
        ]}
        uniqueName="Dialog"
      >
        <Box component="div">
          <Typography>
            In&Out es tu centro de belleza en Málaga por excelencia, pioneros en la técnica de la{" "}
            <strong>depilación con hilo</strong> en Málaga y con una larga trayectoria que nos
            avalan en
            <em>tratamientos faciales, tratamientos corporales, masajes</em> y organizadores de
            beauty parties.
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Collapse in={!show}>
            <Typography sx={{ textAlign: "center" }}>
              Te invitamos a leer y acceptar nuestra politica de privacidad:
            </Typography>
          </Collapse>
          <Collapse in={show}>
            Utilizamos cookies de terceros:
            <Box component="div" sx={{ m: 1 }}>
              1. De <strong>GOOGLE</strong> para mejorar nuestros servicios mediante el análisis de
              sus hábitos de navegación.
            </Box>
            <Box component="div" sx={{ m: 1 }}>
              2. De <strong>STRIPE</strong> para permitir compras dentro la applicacion.
            </Box>
            <br />
            <Privacy />
          </Collapse>
          <Button fullWidth onClick={() => setShow(prev => !prev)}>
            {show ? "ENTENDIDO" : "QUIERO SABER MAS"}
          </Button>
          <Divider />
          <Box component="div" sx={{ display: "flex", justifyContent: "right", mt: 2, mb: 2 }}>
            <Button
              sx={{ mr: 3 }}
              onClick={() => {
                setOpen(false)
              }}
            >
              IGNORAR
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                localStorage.setItem("inandout.acceptPrivacy", "true")
                setOpen(false)
              }}
            >
              HE LEIDO Y ACCEPTO
            </Button>
          </Box>
          <Divider />
          <Box component="div" sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
            <Logo size={36} />
          </Box>
        </Box>
      </Flow>
    </GenericDialog>
  )
}

export { WelcomeDialog }
