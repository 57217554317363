import { CarouselProvider, Slide, Slider } from "pure-react-carousel"
import { Link } from "react-router-dom"

import { Button, Card, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"

type Props = {
  interval?: number
  excludeId?: string
  desktopSlides?: number
}

const CarouselBeautyParties = ({ interval = 9000, excludeId = "", desktopSlides = 2 }: Props) => {
  const { db } = usePublicDb()
  const { isMobile } = useDeviceInfo()
  const parties = db.beautyParties.filter(item => item.id !== excludeId)

  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={parties.length}
      infinite
      isIntrinsicHeight
      isPlaying
      interval={interval}
      visibleSlides={isMobile ? 1 : desktopSlides}
    >
      <Slider>
        {parties.map((party, index) => {
          return (
            <Slide key={index} index={index} style={{ margin: "0 2px" }}>
              <Card
                sx={{
                  width: "100%",
                  height: "200px",
                  p: 1,
                  background: `url(${party.photo}) no-repeat center #fff`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <Typography sx={{ backgroundColor: "#ffffff99", borderRadius: "4px", p: "0 6px" }}>
                  BEAUTY PARTY: {party.title}
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    left: "10px",
                    top: "120px",
                    backgroundColor: "#ffffffbb",
                    padding: 1,
                    borderRadius: "4px",
                    transform: "rotate(-15deg)",
                    fontSize: "16px",
                  }}
                >
                  {party.price.toFixed(2)} EURO!
                </Typography>
                <Button
                  component={Link}
                  to={party.href}
                  variant="contained"
                  sx={{ position: "absolute", top: "150px", right: "8px" }}
                >
                  VER DETALLES
                </Button>
              </Card>
            </Slide>
          )
        })}
      </Slider>
    </CarouselProvider>
  )
}

export { CarouselBeautyParties }
