import { Box, Link, Paper } from "@mui/material"

type Props = {
  excludeTitle?: boolean
}

const TermsAndConditions = ({ excludeTitle }: Props) => {
  return (
    <Box component="div">
      {!excludeTitle ? (
        <Box component="h1" sx={{ pt: 0.5 }}>
          TÉRMINOS Y CONDICIONES
        </Box>
      ) : null}
      <Box component="h2">I. OBJETO</Box>
      <Paper sx={{ p: 1 }}>
        El presente documento tiene por objeto establecer las Condiciones Generales de Uso y las
        Condiciones Generales de Venta y devoluciones de la página web de venta online{" "}
        <strong>https://www.inandoutbelleza.es</strong>.
      </Paper>
      <Box component="h2">II. IDENTIDAD DE LAS PARTES</Box>
      <Paper sx={{ p: 1 }}>
        Las condiciones generales de venta descritas a continuación (en adelante Condiciones
        generales de venta), rigen exclusivamente las relaciones contractuales entre todo cliente de
        la página web <strong>https://www.inandoutbelleza.es</strong> (denominado en lo sucesivo
        CLIENTE) y el vendedor, que es <strong>HIDIME BELLEZA S.L</strong> (en adelante In&Out
        Belleza), con domicilio social sito el Calle Salitre nº 11, Bajo, C.P. 29002 (Málaga) con
        CIF: B93140986, inscrita en el Registro Mercantil de Málaga al tomo 4.910, folio 169, hoja
        MA—IIO.181, inscripcion 1a.
        <Box component="div" sx={{ height: 8 }} />
        El acceso y/o uso de la web inandoutbelleza.es atribuye la condición de usuario, que acepta,
        desde dicho acceso y/o uso, las condiciones de nuestra Política de Privacidad. Las citadas
        Condiciones serán de aplicación independientemente de las Condiciones de nuestra Política de
        contratación, cancelación y devolución que en su caso se establezcan y resulten de obligado
        cumplimiento.
      </Paper>

      <Box component="h2">III. ADQUISICIÓN DE PRODUCTOS Y SERVICIOS</Box>
      <Paper sx={{ p: 1 }}>
        Cualquier adquisición de los productos y servicios ofertados en esta web, tendrán la
        consideración de contratación efectiva a todos los efectos, comprometiéndose HIDIME BELLEZA
        S.L. a prestar los servicios y a entregar los productos contratados en el centro elegido, en
        su caso por el cliente.
        <Box component="div" sx={{ height: 8 }} />
        Realizada la adquisición de los productos y servicios, el cliente podrá hacer uso de los
        mismos y recibir lo adquirido en nuestro centro, comprometiéndose HIDIME BELLEZA S.L. a
        iniciar los servicios contratados en un plazo máximo de tres semanas desde que el cliente
        acuda al centro para concertar una cita para recibir los servicios o realizarse los
        tratamientos contratados.
      </Paper>

      <Box component="h2">IV. REALIZACIÓN DE SU PEDIDO</Box>
      <Paper sx={{ p: 1 }}>
        Es requisito necesario que en el registro nos facilite: un teléfono de contacto móvil o
        fijo, una dirección de correo electrónico, un nombre y apellido, así como la dirección de
        envío completa.
        <Box component="div" sx={{ height: 8 }} />
        Los medios de contacto que utilizaremos para mandarle las notificaciones sobre su pedido
        serán principalmente el correo electrónico, aunque en ocasiones también podemos utilizar el
        teléfono.
        <Box component="div" sx={{ height: 8 }} />
        Las condiciones de uso y compra de esta página web solo son válidas para compras realizadas
        en España, dentro de la Península Ibérica.
        <Box component="div" sx={{ height: 8 }} />
        Una vez finalizado el pedido y cuando se haya formalizado y confirmado el pago de este,
        recibirá en el correo electrónico que previamente ha facilitado en su cuenta de usuario,
        nuestra aceptación emitiendo una confirmación de pedido. Esta conformación valdrá como
        justificante que deberá presentar en nuestro centro a la hora de hacer uso de los productos
        o servicios contratados.
      </Paper>

      <Box component="h2">V. POLÍTICA DE PRECIOS</Box>
      <Paper sx={{ p: 1 }}>
        Los precios que puedan figurar en cada momento en esta web, así como los que en su caso
        HIDIME BELLEZA S.L. publicite por cualquier medio, podrán variar en el tiempo y según la
        plataforma de venta, teniendo plena libertad para fijar los precios finales de venta al
        público que consideren oportunos en ejercicio de su autonomía empresarial. Nos reservamos el
        derecho de modificar nuestros precios en cualquier momento.
        <Box component="div" sx={{ height: 8 }} />
        No obstante, nos comprometemos a aplicar las tarifas en vigor indicadas en la página web, en
        el momento de la realización de su pedido.
        <Box component="div" sx={{ height: 8 }} />
        Los gastos de envío, cuando corresponda, son a cargo del usuario, tal como se indica en el
        pedido y factura.
        <Box component="div" sx={{ height: 8 }} />
        El precio de los productos incluye el I.V.A correspondiente que será a cargo del cliente y
        será el que se indique en su pedido y/o factura.
      </Paper>

      <Box component="h2">VI. CADUCIDAD</Box>
      <Paper sx={{ p: 1 }}>
        Todas las compras realizadas en la web y en los centros de HIDIME BELLEZA S.L. tienen una
        caducidad de 1 año desde la fecha de compra. Pasado este tiempo, el cliente perderá el
        derecho a disfrutar de los servicios adquiridos.
      </Paper>

      <Box component="h2">VII. POLÍTICA DE DEVOLUCIONES</Box>
      <Paper sx={{ p: 1 }}>
        La adquisición de cualquier producto o servicio por parte del cliente no da en ningún caso
        derecho a la devolución del precio ya abonado por el cliente en el caso de renuncia,
        desistimiento o caducidad del producto o servicio contratado.
        <Box component="div" sx={{ height: 8 }} />
        HIDIME BELLEZA S.L. se hará responsable ante las posibles reclamaciones reservándose el
        derecho a decidir sobre la devolución en cada caso concreto.
        <Box component="div" sx={{ height: 8 }} />
        Si ha recibido un pedido y no está conforme con alguno de los artículos, puede solicitar la
        devolución. Siempre y cuando cumpla las condiciones que puede leer más abajo.
        <h4>
          Condiciones para la aceptación de devolución de productos adquiridos desde nuestra página
          web.
        </h4>
        HIDIME BELLEZA S.L. se reserva el derecho de exigir una indemnización para compensar los
        posibles daños sufridos por las mercancías, así como para recuperar los gastos directos
        ocasionados por la devolución. Dellicare solamente aceptará la devolución de la mercancía
        si:
        <ol>
          <li>
            En el plazo marcado al efecto por la Ley (14 días naturales), el cliente podrá ejercitar
            su derecho de resolución. HIDIME BELLEZA S.L. aceptará el ejercicio del mencionado
            derecho única y exclusivamente cuando la mercancía estuviese en el mismo estado en que
            le fue entregado y listo para la venta, con su correspondiente embalaje original y en
            perfecto estado.
          </li>
          <li>
            Si la mercancía estuviese defectuosa, según lo dispuesto en la Ley de Productos
            Defectuosos, HIDIME BELLEZA S.L. procederá a sustituir inmediatamente el producto por
            otro idéntico.
          </li>
          <li>
            HIDIME BELLEZA S.L.procederá igualmente a devolver el importe de la mercancía cuando por
            causas no imputables (ya sean directas o indirectas) a ella, se viese imposibilitado de
            servirlo.
          </li>
          <li>
            HIDIME BELLEZA S.L. aceptará la devolución de la mercancía cuando por causas imputables
            a ella, el producto solicitado no se correspondiese con el solicitado por el cliente.
          </li>
          <li>Si desea un cambio o artículo alternativo, tendrá que realizar un nuevo pedido. </li>
        </ol>
        En caso de controversia o conflicto que pueda derivarse de la interpretación o cumplimiento
        de las presentes Condiciones Particulares de Compra, las mismas serán resueltas ante los
        Tribunales y Juzgados de Málaga
        <Box component="div" sx={{ height: 8 }} />
        Pasos a seguir:
        <Box component="div" sx={{ height: 8 }} />
        Solicitar su devolución vía e mail a{" "}
        <Link href="mailto:info@inandoutbelleza.com">info@inandoutbelleza.com</Link> en el plazo de
        14 días naturales desde la fecha en que se recibió el pedido por parte de la empresa de
        Mensajería. Por favor, haga saber en dicho e-mail el número de pedido y el motivo por el
        cual devuelve el artículo.
        <Box component="div" sx={{ height: 8 }} />
        Una vez recibida su petición de devolución, nos pondremos en contacto con usted. para
        indicarle los pasos a seguir en dicha devolución.
        <Box component="div" sx={{ height: 8 }} />A continuación y siempre en un plazo de 5 días
        laborables a partir de su petición, nuestra empresa de Mensajería recogerá el artículo en el
        horario que nos indique. Los gastos de transporte serán abonados por el cliente. Si lo
        prefiere puede enviarlo con otra empresa de mensajería o logística, a porte pagados por el
        cliente.
        <Box component="div" sx={{ height: 8 }} />
        Prepare entonces el paquete a recoger dentro de su envoltorio original, y asegúrese de que
        las etiquetas no hayan sido arrancadas y el artículo no haya sido usado o manipulado. La
        devolución debe cumplir estas condiciones para ser aceptada.
        <Box component="div" sx={{ height: 8 }} />
        Una vez recibido el artículo en nuestras oficinas e inspeccionado minuciosamente, le
        mandaremos un e-mail informándole de la aceptación de la devolución.
        <Box component="div" sx={{ height: 8 }} />A partir de esta fecha, en un plazo maximo de 30
        días naturales se reembolsara el importe de la compra excepto los gastos de transporte,
        tanto del envío como de la vuelta, que solo serán devueltos en caso que HIDIME BELLEZA sea
        responsable de la causa de devolución del producto. El tiempo medio de reembolso despues de
        la recepción en nuestras oficinas de la mercancia, es de tres dias laborables. El reembolso
        se efectuará a través del medio de pago que utilizó en la compra, excepto en el caso de
        haber pagado contra-reembolso. En este caso se realizará la devolución vía transferencia
        bancaria, los gastos ocasionados por el contrareembolso no serán abonados.
        <Box component="div" sx={{ height: 8 }} />
        En caso de no cumplir alguna de estas condiciones, nos pondremos en contacto con Ud. para
        ofrecerle la opción de devolverle los artículos que no hayan sido aceptados. Todos los
        gastos que genere esta devolución, incluido el transporte de estos artículos, correrán por
        su cuenta. Si no acepta esta condición, HIDIME BELLEZA podrá retener los artículos y el
        importe de los mismos.
        <Box component="div" sx={{ height: 8 }} />
        El cliente dispone de un plazo de 14 días naturales para ejercer el derecho de
        desistimiento, desde la adquisición del producto o servicio. El ejercicio de este derecho no
        supone ningún gasto para el consumidor, quien, además, tiene derecho al reembolso de los
        gastos que haya realizado, siempre y cuando no haya hecho ya uso de ninguno del servicio
        asociado al paquete contratado.
        <Box component="div" sx={{ height: 8 }} />
        En caso de el cliente desee cancelar el contrato una vez haya hecho uso del producto o
        servicio, es decir, una vez haya disfrutado de las primeras sesiones de los servicios
        contratados y en caso de que esta cancelación sea aplicable al producto o servicio
        contratado, HIDIME BELLEZA S.L. tendrá derecho a descontar del precio abonado, las sesiones
        ya disfrutadas al precio de sesión individual, perdiendo el cliente cualquier beneficio de
        la oferta en las sesiones ya disfrutadas.
        <Box component="div" sx={{ height: 8 }} />
        En caso de que la cancelación se produzca por parte de HIDIME BELLEZA S.L., sin causa que lo
        justifique, una vez se haya iniciado el servicio y se hayan disfrutado de las primeras
        sesiones, se reintegrará al cliente el importe íntegro contratado.
      </Paper>

      <Box component="h2">VIII. MODIFICACIONES</Box>
      <Paper sx={{ p: 1 }}>
        HIDIME BELLEZA S.L. se reserva el derecho de efectuar sin previo aviso las modificaciones
        que considere oportunas en su web, pudiendo cambiar, suprimir o añadir tanto los Contenidos
        y servicios que se presten a través de la misma como la forma en la que estos aparezcan
        presentados o localizados en su web.
      </Paper>

      <Box component="h2">IX. MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</Box>
      <Paper sx={{ p: 1 }}>
        HIDIME BELLEZA S.L. podrá modificar en cualquier momento las condiciones aquí determinadas,
        siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones irá
        en función de su exposición y estarán vigentes hasta que sean modificadas por otras
        debidamente publicadas, recomendando al usuario que las revise periódicamente para estar al
        día de estos cambios y mejoras de las condiciones.
      </Paper>

      <Box component="h2">X.- LEGISLACIÓN APLICABLE Y JURISDICCIÓN</Box>
      <Paper sx={{ p: 1 }}>
        La relación entre HIDIME BELLEZA S.L. y el cliente se regirá por la normativa española
        vigente y cualquier controversia se someterá, a elección del cliente, en los Juzgados y
        Tribunales correspondientes al foro judicial del domicilio del usuario o en los juzgados y
        Tribunales de Málaga.
      </Paper>

      <Box component="h2">XI.- AVISO LEGAL SOBRE LAS TRANSACIONES</Box>
      <Paper sx={{ p: 1 }}>
        En virtud del cumplimiento de la normativa EMVCo, MasterCard, VISA y la Directiva Europea
        PSDII, durante la operación de pago electrónico comunicará información referida a la
        conexión empleada en la sesión al Banco emisor de la tarjeta para proceder a la validación
        de la operación de pago realizada por el usuario.
      </Paper>
    </Box>
  )
}

export { TermsAndConditions }
