import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { FlowElement, FlowEvent } from "../types/flow.types"

type FlowState = {
  recording: boolean
  expandFlow: boolean
  displayElements: boolean
  breakpoint: string
}

const _settings = atomWithStorage<FlowState>("flow.state", {
  recording: true,
  expandFlow: true,
  displayElements: false,
  breakpoint: "xl",
})

const _flowElements = atom<Array<{ el: HTMLElement } & FlowElement>>([])
const _testElements = atom<Array<HTMLElement>>([])
const _flowEvents = atom<Array<FlowEvent>>([])

type Update = (value: Array<FlowEvent>) => Array<FlowEvent>

let events: Array<FlowEvent> = []

const sendFlowEvents = (value: Array<FlowEvent> | Update) => {
  if (window.parent) {
    const detail = value instanceof Function ? value(events) : value
    events = detail
    const event = new CustomEvent("flow-event", { detail })
    window.parent.document.dispatchEvent(event)
  }
}

const createFlowEvent = (event: Partial<FlowEvent> & Omit<FlowEvent, "timestamps">) => {
  const ev: FlowEvent = {
    timestamps: { start: new Date().toISOString() },
    ...event,
  }
  return ev
}

const sendFlowEvent = (event: Partial<FlowEvent> & Omit<FlowEvent, "timestamps">) => {
  sendFlowEvents(prev => prev.concat(createFlowEvent(event)))
}

const sendFlowEventAndUpdate = (
  create: () => Partial<FlowEvent> & Omit<FlowEvent, "timestamps">,
  delay = 100,
) => {
  const index = events.length
  const timeout = setTimeout(() => {
    sendFlowEvents(newArr => {
      const el = newArr[index]
      Object.assign(el, create())
      return [...newArr]
    })
  }, delay)
  sendFlowEvents(prev => {
    return [...prev, createFlowEvent(create())]
  })
  return { timeout }
}

const deleteEvents = () => {}

const getEvents = () => events

export {
  _settings,
  _flowEvents,
  _flowElements,
  _testElements,
  sendFlowEvents,
  deleteEvents,
  getEvents,
  sendFlowEvent,
  createFlowEvent,
  sendFlowEventAndUpdate,
}
