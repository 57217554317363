type DateFormat = number | string | Date

const spainOffsets: Array<number> = [
  1667095200000, //new Date('2022-10-30T03:00').getTime()
  1679792400000, //new Date('2023-03-26T02:00').getTime()
  1698544800000, //new Date('2023-10-29T03:00').getTime()
  1711846800000, //new Date('2024-03-31T02:00').getTime()
  1729994400000, //new Date('2024-10-27T03:00').getTime()
  1743296400000, //new Date('2025-03-30T02:00').getTime()
  1761444000000, //new Date('2025-10-26T03:00').getTime()
  1774746000000, //new Date('2026-03-29T02:00').getTime()
]

const getSpainOffset = (date = Date.now()) => {
  const reference = new Date(date)
  const bigger = spainOffsets.find(item => item > reference.getTime())
  if (!bigger) return 0
  const index = spainOffsets.indexOf(bigger) - 1
  return (index % 2) + 1
}

const getSpainDate = (timestamp: number) => {
  const offset = (new Date(timestamp).getTimezoneOffset() / 60 + getSpainOffset(timestamp)) * 60
  const date = new Date(timestamp + offset * 60 * 1000)
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    day: date.getDay(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    shortDate: toShortDate(date),
    time: `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`,
  }
}

const isToday = (date: DateFormat, compare?: DateFormat) => {
  const input = new Date(date)
  const today = compare ? new Date(compare) : new Date()
  return (
    input.getDate() === today.getDate() &&
    input.getMonth() === today.getMonth() &&
    input.getFullYear() === today.getFullYear()
  )
}

const days = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"]
const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
]

const lastDayOfMonth = (date: DateFormat = new Date()) => {
  const newDate = new Date(date)
  newDate.setDate(1)
  newDate.setHours(0, 0, 0, 0)
  newDate.setMonth(newDate.getMonth() + 1)
  newDate.setTime(newDate.getTime() - 1)
  return newDate
}

const startOfTheDay = (date: DateFormat = new Date()) => {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

const startOfTheMonth = (date: DateFormat = new Date()) => {
  const newDate = new Date(date)
  newDate.setDate(1)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

const endOfTheMonth = (date: DateFormat = new Date()) => {
  const newDate = new Date(date)
  newDate.setDate(1)
  newDate.setMonth(newDate.getMonth() + 1)
  newDate.setDate(-1)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

const endOfTheDay = (date: DateFormat = new Date()) => {
  const newDate = new Date(date)
  newDate.setHours(23, 59, 59, 999)
  return newDate
}

const firstDayOfMonth = (date: DateFormat = new Date()) => {
  const newDate = new Date(date)
  newDate.setDate(1)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

const firstDayOfQuarter = (date: DateFormat = new Date()) => {
  const newDate = firstDayOfMonth(date)
  const quarter = Math.floor(newDate.getMonth() / 3)
  const month = (quarter * 3 + 1).toString().padStart(2, "0")
  return firstDayOfMonth(new Date(`${newDate.getFullYear()}-${month}-01`))
}

const lastDayOfQuarter = (date: DateFormat = new Date()) => {
  const newDate = firstDayOfQuarter(date)
  const quarter = Math.floor(newDate.getMonth() / 3)
  const month = (quarter * 3 + 3).toString().padStart(2, "0")
  return lastDayOfMonth(new Date(`${newDate.getFullYear()}-${month}-01`))
}

const toLongDate = (date: DateFormat) => {
  const d = new Date(date)
  return `${days[d.getDay()]}, ${d.getDate()} de ${months[d.getMonth()]} de ${d.getFullYear()}`
}
const toShortDate = (date: DateFormat) => {
  const d = new Date(date)
  return `${d.getDate()} de ${months[d.getMonth()]} de ${d.getFullYear()}`
}
const toShortHour = (date: DateFormat) => {
  const d = new Date(date)
  return `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`
}

const toDayNameDate = (date: DateFormat) => {
  const d = new Date(date)
  return `${days[d.getDay()]}, ${d.getDate()} ${months[d.getMonth()].substring(0, 3)}`
}

const getDayName = (date: DateFormat) => {
  const d = new Date(date)
  return days[d.getDay()]
}

const toMonthDate = (date: DateFormat) => {
  const d = new Date(date)
  return `${months[d.getMonth()]} de ${d.getFullYear()}`
}

const toFormatNumber = (number: number, decimals = 0) => {
  const first = number.toFixed(decimals)
  const [int, dec = ""] = first.split(".")
  return `${int.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}${dec ? `,${dec}` : ""}`
}

const addMonths = (date: DateFormat, months: number) => {
  const d = new Date(date)
  d.setMonth(d.getMonth() + months)
  return d
}

const getQuarter = (date: DateFormat) => {
  const d = new Date(date)
  const index = Math.floor(d.getMonth() / 3)
  return { index, name: `${index + 1} Trimestre de ${d.getFullYear()}` }
}

const startOfTheWeek = (date: DateFormat) => {
  const newDate = startOfTheDay(date)
  const spain = getSpainDate(new Date(date).getTime())
  newDate.setTime(newDate.getTime() - spain.day * 24 * 60 * 60 * 1000)
  return newDate
}

const weekDifference = (from: DateFormat, to: DateFormat) => {
  const date1 = new Date(from)
  const date2 = new Date(to)
  return Math.floor((date2.getTime() - date1.getTime()) / (7 * 24 * 60 * 60 * 1000))
}

const dayDifference = (from: DateFormat, to: DateFormat) => {
  const date1 = new Date(from)
  const date2 = new Date(to)
  return Math.floor((date2.getTime() - date1.getTime()) / (24 * 60 * 60 * 1000))
}

const toCurrency = (number: number) => {
  return `${toFormatNumber(number, 2)} €`
}

const intervalDuration = (start: DateFormat, end: DateFormat) => {
  const int = new Date(end).getTime() - new Date(start).getTime()
  const hours = Math.floor(int / 1000 / 60 / 60)
  const minutes = Math.floor(int / 1000 / 60) % 60
  const seconds = Math.floor((int / 1000) % 60)
  const label = `${hours > 0 ? `${hours} hora${hours > 1 ? "s" : ""} ` : ""}${
    minutes > 0 ? `${minutes} minuto${minutes > 1 ? "s" : ""}` : ""
  }${seconds > 0 ? `${seconds} segundo${seconds > 1 ? "s" : ""} ` : ""}`
  return { hours, minutes, seconds, label }
}

const isSameDate = (day1: DateFormat, day2: DateFormat) => {
  const s1 = getSpainDate(new Date(day1).getTime())
  const s2 = getSpainDate(new Date(day2).getTime())
  return s1.date === s2.date && s1.month === s2.month && s1.year === s2.year
}

const millisecondsToString = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  const secondsString = seconds % 60
  const minutesString = minutes % 60
  const hoursString = hours % 24
  const daysString = days
  const dString = daysString > 0 ? `${daysString} dia${daysString > 1 ? "s" : ""} ` : ""
  const hString = hoursString > 0 ? `${hoursString} hora${hoursString > 1 ? "s" : ""} ` : ""
  const mString = minutesString > 0 ? `${minutesString} minuto${minutesString > 1 ? "s" : ""} ` : ""
  const sString = secondsString > 0 ? `${secondsString} segundo${secondsString > 1 ? "s" : ""}` : ""
  return `${dString}${hString}${mString}${sString}`
}

const getDatesFromInterval = (from: DateFormat, to: DateFormat) => {
  const start = new Date(from)
  const end = new Date(to)
  const dates = []
  while (start <= end) {
    dates.push(new Date(start))
    start.setDate(start.getDate() + 1)
  }
  return dates
}

const firstDateOfTheYear = (date: DateFormat = Date.now()) => {
  const d = new Date(date)
  d.setMonth(0)
  d.setDate(1)
  return d
}

const lastDayOfTheYear = (date: DateFormat = Date.now()) => {
  const d = new Date(date)
  d.setMonth(11)
  d.setDate(31)
  return d
}

const toPercentage = (number: number) => {
  return `${(number * 100).toFixed(2)}%`
}

const dateUtils = {
  days,
  lastDayOfMonth,
  firstDayOfMonth,
  firstDayOfQuarter,
  lastDayOfQuarter,
  startOfTheDay,
  endOfTheDay,
  toLongDate,
  toShortDate,
  toShortHour,
  toDayNameDate,
  toMonthDate,
  toFormatNumber,
  addMonths,
  getQuarter,
  weekDifference,
  startOfTheWeek,
  toCurrency,
  getDayName,
  getSpainOffset,
  getSpainDate,
  startOfTheMonth,
  isToday,
  intervalDuration,
  isSameDate,
  millisecondsToString,
  dayDifference,
  getDatesFromInterval,
  endOfTheMonth,
  firstDateOfTheYear,
  lastDayOfTheYear,
  toPercentage,
}

export { dateUtils }
