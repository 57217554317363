import { Box } from "@mui/material"

import { useAuth } from "@in-and-out-belleza/authentication"
import { Splash } from "@in-and-out-belleza/ui"
import { UserForms } from "./UserForms"
import { UserMobile } from "./UserMobile"

const User = () => {
  const { user } = useAuth()

  if (user.isLoggingIn)
    return (
      <Box component="div" sx={{ height: "400px" }}>
        <Splash />
      </Box>
    )

  return !user.logged ? <UserForms /> : <UserMobile />
}

export { User }
