import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { useRegisterSW } from "virtual:pwa-register/react"
import { Snackbar, Typography } from "@mui/material"
import { dateUtils } from "@in-and-out-belleza/utils"
import { resetPWAandReload } from "@in-and-out-belleza/authentication"

const CHECK_UPDATE_INTERVAL = 2 * 60 * 1000
const AUTO_REFRESH_AFTER = 10 * 1000

const toString = (ms: number) => {
  const int = dateUtils.intervalDuration(0, ms)
  return int.seconds
}

const NeedRefreshSnackbar = ({ cacheName }: { cacheName: string }) => {
  const [ms, setMs] = useState(AUTO_REFRESH_AFTER)
  const int = useRef<ReturnType<typeof setInterval> | undefined>()
  const { pathname } = useLocation()

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(registration) {
      if (registration && registration.active) {
        setInterval(() => registration.update(), CHECK_UPDATE_INTERVAL)
        registration.update()
      }
    },
  })

  useEffect(() => {
    if (needRefresh && !int.current) {
      int.current = setInterval(() => {
        setMs(prev => {
          if (prev <= 1000) {
            clearInterval(int.current)
            updateServiceWorker().then(() => {
              resetPWAandReload(cacheName)
            })
          }
          return prev - 1000
        })
      }, 1000)
    }
  }, [pathname, needRefresh, ms, cacheName, updateServiceWorker])

  return (
    <Snackbar
      message={
        <Typography>La aplicacion necesita reiniciarse en {toString(ms)} segundos</Typography>
      }
      open={needRefresh}
    />
  )
}

export { NeedRefreshSnackbar }
