import { Middleware } from "../../utils/Middleware"

export const FetchMiddleware = function () {
  const middleware = new Middleware<
    [body: Promise<Response> | undefined, input: URL | RequestInfo, init?: RequestInit]
  >()
  const originalFetch = window.fetch

  const send = middleware.use(function (next, _, input, init) {
    const promise = originalFetch(input, init).then(res => {
      next(Promise.resolve(res.clone()), input, init)
      return res
    })
    return [promise, input, init]
  })

  const _fetch = async (input: URL | RequestInfo, init?: RequestInit) => {
    const res = send(undefined, input, init) as Promise<Response>
    return res
  }

  window.fetch = _fetch

  return middleware
}
