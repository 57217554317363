import { isBonusCreaditTransaction } from "@in-and-out-belleza/api/interfaces"
import { Box, Button, Divider, Paper, styled, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useDate } from "@in-and-out-belleza/hooks"
import { getBonusTreatments } from "@in-and-out-belleza/api/utils"

const Wrapper = styled(Box)(() => ({
  "& table tr td:nth-of-type(2)": {
    width: "140px",
    textAlign: "right",
  },
}))

export const BonusCardDetailMobile = ({ id }: { id: string | undefined }) => {
  const { user } = useAuth()
  const { db } = usePublicDb()
  const { longDate } = useDate()
  const bonus = user.bonusCards.find(bonus => bonus._id === id)
  const bonusTreatments = getBonusTreatments(user, db)
  const bonusCreditTransactions = bonus?.transactions.filter(isBonusCreaditTransaction) || []
  const spent = bonusCreditTransactions.reduce((tot, item) => tot + item.amount, 0)

  if (!bonus) return null
  if (bonus.price === undefined) return null

  const toBePayed = bonus.price - bonus.payed

  return (
    <Wrapper>
      <Typography fontSize={24} color="primary" sx={{ mb: "4px" }}>
        LOS DETALLES DE TU BONO:
      </Typography>
      <Typography fontSize={14} component="em">
        COMPRADO: {longDate(bonus.created)}
      </Typography>
      {bonus.credit ? (
        <Paper>
          <table width="100%">
            <tbody>
              <tr>
                <td>CREDITO INICIAL</td>
                <td>{bonus.credit.toFixed(2)} Euro</td>
              </tr>
              <tr>
                <td>CREDITO USADO</td>
                <td>{spent.toFixed(2)} Euro</td>
              </tr>
              <tr>
                <td>CREDITO RESTANTE</td>
                <td>{(bonus.credit + spent).toFixed(2)} Euro</td>
              </tr>
            </tbody>
          </table>
          <Divider />
          <br />
          <Button sx={{ float: "right" }} variant="contained">
            RECARGAR
          </Button>
          <br />
          <br />
        </Paper>
      ) : null}
      {Object.keys(bonusTreatments).length ? (
        <Paper>
          <table width="100%">
            <tbody>
              {Object.entries(bonusTreatments).map(([id, item]) => {
                const remain = item.total - item.used
                const treatment = db.treatments.find(trt => trt.id === id)
                return (
                  <tr key={id}>
                    <td>{treatment?.title}</td>
                    <td>
                      {remain} de {item.total} sesion{remain > 1 && "es"}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Paper>
      ) : null}

      <br />
      {toBePayed ? (
        <Box component="div">
          <Typography>
            Has pagado solo un {((toBePayed / bonus.price) * 100).toFixed(0)}% de este bono. Para
            terminar de pagarlo te quedarian {toBePayed.toFixed(2)} Euro.
          </Typography>
          <Button sx={{ float: "right" }} variant="contained">
            TERMINA DE PAGAR
          </Button>
        </Box>
      ) : null}
    </Wrapper>
  )
}
