import { useEffect } from "react"
import { useAtom } from "jotai"
import { FlowFrameMessageType } from "../../api/message-utils"
import {
  _flowElements,
  _settings,
  _testElements,
  sendFlowEvent,
  sendFlowEventAndUpdate,
  sendFlowEvents,
} from "../../atoms/state"
import { isBrowserLocation } from "../../types/flow.types"
import { WindowHistoryListener } from "./window-history"
import { WindowOpenListener } from "./window-open"

const historyMd = WindowHistoryListener()
const openMd = WindowOpenListener()

type FlowMessage = {
  from?: string
  type: FlowFrameMessageType
  message: Record<string, string | number>
}

const WindowHistory = () => {
  const [_, setSettings] = useAtom(_settings)
  const [__, setElements] = useAtom(_flowElements)
  const [___, setTestElements] = useAtom(_testElements)

  useEffect(() => {
    window.onmessage = function (e: MessageEvent) {
      let data: FlowMessage | undefined
      try {
        data = JSON.parse(e.data) as FlowMessage
      } catch (e) {
        // nothing
      }
      if (!data) return
      if (data.from !== "flow-frame") return
      if (data.type === "delete-all") {
        sendFlowEvents([])
      } else if (data.type === "delete-one") {
        const index = Number(data.message.index)
        sendFlowEvents(prev => {
          prev.splice(index, 1)
          return [...prev]
        })
      } else if (data.type === "update-description") {
        const index = Number(data.message.index)
        const description = data.message.description
        sendFlowEvents(prev => {
          Object.assign(prev[index], { description })
          return [...prev]
        })
      } else if (data.type === "history-go-back") {
        sendFlowEventAndUpdate(() => ({
          type: "browser-location",
          description: `The user goes back to "${window.location.href.replace(
            /^(?:\/\/|[^/]+)*/,
            "",
          )}"`,
          data: {
            href: window.location.href,
            who: "user",
          },
        }))
      } else if (data && data.type === "settings") {
        setSettings(prev => ({ ...prev, displayElements: Boolean(data?.message.displayElements) }))
      }
    }

    sendFlowEvent({
      type: "browser-location",
      description: `The user visits "${window.location.href.replace(/^(?:\/\/|[^/]+)*/, "")}"`,
      data: {
        href: window.location.href,
        who: "user",
      },
    })

    const dispose1 = historyMd.before((next, ...args) => {
      const [_, __, path] = args
      const href =
        path instanceof URL
          ? path.href
          : path ?? window.location.href.replace(/^(?:\/\/|[^/]+)*/, "")
      sendFlowEvents(prev => {
        const filtered = prev.filter(isBrowserLocation)
        const last = filtered[filtered.length - 1]
        if (last && last.data.href === href) return prev
        return prev.concat({
          timestamps: { start: new Date().toISOString() },
          type: "browser-location",
          description: `The application redirects to "${href}"`,
          data: {
            href,
            who: "application",
          },
        })
      })
      next(...args)
    })

    const dispose2 = openMd.before((next, url, ...rest) => {
      if (!url) return
      const href = typeof url === "string" ? url : url.href
      sendFlowEvent({
        type: "browser-location",
        description: `The application open a new tab "${href}"`,
        data: {
          href,
          target: "_blank",
          who: "application",
        },
      })
      next(url, ...rest)
    })

    return () => {
      dispose1()
      dispose2()
    }
  }, [setElements, setSettings, setTestElements])

  return null
}

export { WindowHistory }
