import { Delete } from "@mui/icons-material"
import { Box, Divider, IconButton, Link, Paper, Tooltip, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartAndGiftsTotal } from "@in-and-out-belleza/api/utils"
import { useCart } from "../../atoms/atomCart"
import queryString from "query-string"
import { useDate } from "@in-and-out-belleza/hooks"
import { CartTreatments } from "./CartTreatments"

const CartGifts = () => {
  const { db } = usePublicDb()
  const { currency } = useDate()
  const { gifts, setGifts } = useCart()

  if (!gifts.length) return null

  return (
    <Paper sx={{ p: 1 }}>
      REGALOS:
      {gifts.map((gift, index) => {
        return (
          <Box component="div" key={`${index}-${gift.name}`}>
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                title={
                  <>
                    {gift.credit > 0 && (
                      <Paper sx={{ p: 1, mb: 0.5, fontSize: 16 }}>
                        CHEQUE REGALO de {currency(gift.credit)}
                      </Paper>
                    )}
                    <CartTreatments cart={gift.cart} title="TRATAMIENTOS INCLUDOS:" />
                  </>
                }
              >
                <Typography
                  sx={{ flexGrow: 1 }}
                  color="green"
                  component={Link}
                  href={`/api/gift-pdf/preview?${queryString.stringify(gift)}`}
                  target="_blank"
                >
                  REGALO para "{gift.name}"
                </Typography>
              </Tooltip>

              <IconButton
                size="small"
                onClick={() => {
                  setGifts(() => {
                    gifts.splice(index, 1)
                    return [...gifts]
                  })
                }}
              >
                <Delete />
              </IconButton>
              <Typography sx={{ textAlign: "right", minWidth: "80px" }}>
                {currency(getCartAndGiftsTotal(db, [], [gift]).total)}
              </Typography>
            </Box>
            {index !== gifts.length - 1 ? <Divider /> : null}
          </Box>
        )
      })}
    </Paper>
  )
}

export { CartGifts }
