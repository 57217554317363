import { useState, useEffect } from "react"

const hasFocus = () => typeof document !== "undefined" && document.hasFocus()

const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus) // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()) // Focus for additional renders

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    window.addEventListener("focus", onFocus)
    window.addEventListener("blur", onBlur)

    return () => {
      window.removeEventListener("focus", onFocus)
      window.removeEventListener("blur", onBlur)
    }
  }, [])

  return focused
}

const useWindowFocusEffect = (callback: () => void, timeout = 15 * 60 * 60 * 1000) => {
  const focused = useWindowFocus()
  const [now, setNow] = useState(Date.now())
  useEffect(() => {
    const num = setInterval(() => {
      setNow(Date.now())
    }, timeout)
    return () => clearInterval(num)
  }, [timeout])

  useEffect(() => {
    const calc = now + timeout
    if (focused && calc < Date.now()) {
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, now])
}

export { useWindowFocus, useWindowFocusEffect }
