import { CarouselProvider, Slide, Slider } from "pure-react-carousel"

import { Card, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BuyButton } from "../../Buttons/BuyButton"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"

const CarouselTreatments = () => {
  const { db } = usePublicDb()
  const { isMobile } = useDeviceInfo()
  const treatments = db.treatments.filter(item => item.photo)
  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={treatments.length}
      infinite
      isIntrinsicHeight
      isPlaying
      interval={5000}
      visibleSlides={isMobile ? 1 : 2}
    >
      <Slider>
        {treatments.map((treatment, index) => {
          return (
            <Slide key={index} index={index} style={{ margin: "0 2px" }}>
              <Card
                sx={{
                  width: "100%",
                  height: "200px",
                  p: 1,
                  background: `url(${treatment.photo}) no-repeat center #fff`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <Typography sx={{ backgroundColor: "#ffffff99", borderRadius: "4px", p: "0 6px" }}>
                  {treatment.type}: {treatment.title}
                </Typography>
                <BuyButton
                  cart={[treatment.id]}
                  variant="contained"
                  sx={{ position: "absolute", top: "150px", right: "8px" }}
                />
              </Card>
            </Slide>
          )
        })}
      </Slider>
    </CarouselProvider>
  )
}

export { CarouselTreatments }
