import { useQuery } from "@tanstack/react-query"
import { getApiClient } from "@in-and-out-belleza/api/client"
import { AdminGift } from "@in-and-out-belleza/api/interfaces"

const client = getApiClient()

type Props = {
  orderId: string
  index: number
}

const fetch = async ({ orderId, index }: Props) => {
  const { data } = await client.get<AdminGift>(`/api/gift/${orderId}/${index}`)
  return data
}

const oneDay = 24 * 60 * 60 * 1000

const useGift = ({ orderId, index }: Props) => {
  return useQuery({
    queryKey: ["gifts", orderId, index],
    queryFn: () => fetch({ orderId, index }),
    staleTime: oneDay,
    cacheTime: oneDay,
    retry: 0,
  })
}

export { useGift }
