import { Box, Divider, Paper, Typography } from "@mui/material"
import { Link, useLocation, useSearchParams } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon, ErrorBoundary, ReadMoreText } from "@in-and-out-belleza/ui"
import { useNav } from "../../hooks/useNav"
import { BookButton } from "../Buttons/BookButton"
import { BuyButton } from "../Buttons/BuyButton"
import { MetatagProduct } from "../MetatagProduct"
import { NotFoundPage } from "../NotFoundPage"
import { NextAvailableBookings } from "./NextAvailableBookings"
import { Suspense } from "react"
import { getTreatmentsTotal } from "@in-and-out-belleza/api/utils"
import { GiftButton } from "../Buttons/GiftButton"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"

const Treatment = () => {
  const { isMobile } = useDeviceInfo()
  const { db } = usePublicDb()
  const { pathname } = useLocation()
  const [params] = useSearchParams()
  const { treatmentsFamily } = useNav()
  const treatment = db.treatments.find(item => item.href === pathname)
  const hidePrices = params.get("booking")
  if (!treatment) return <NotFoundPage carousel="treatments" />

  const { promotions } = getTreatmentsTotal(db, [treatment.id], Date.now(), [])

  return (
    <Box component="div" sx={{ position: "relative" }}>
      <MetatagProduct
        name={treatment.title}
        description={treatment.description}
        photo={treatment.photo}
        cart={[treatment.id]}
        brand={"In&Out"}
        href={treatment.href}
      />
      <Paper
        component={Link}
        to={treatmentsFamily.url(treatment.menuHref)}
        sx={{ m: 1, p: 1, display: "flex" }}
      >
        <Typography>VOLVER A "{treatment.type.toUpperCase()}"</Typography>
        <Box component="div" sx={{ flexGrow: 1, justifyContent: "end", display: "flex" }}>
          <BeautyIcon name="treatments" size={20} />
        </Box>
      </Paper>
      <Box component="div" sx={{ p: 1 }}>
        <Typography variant="h5">{treatment.title}</Typography>
        <Divider />
        <Box component="div" sx={{ position: "relative" }}>
          <Box
            component="div"
            sx={{
              background: `url("${treatment.photo}")`,
              backgroundSize: "cover",
              width: "100%",
              height: "100%",
              position: "absolute",
              opacity: 0.2,
              pointerEvents: "none",
            }}
          />
          <ReadMoreText text={treatment.description} />
        </Box>
        <Divider sx={{ mb: 2 }} />
        <BookButton cart={[treatment.id]} variant="contained" sx={{ margin: 0.5 }} />
        {!hidePrices ? (
          <Box component="div">
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Box component="div" sx={{ display: isMobile ? "block" : "flex", alignItems: "center" }}>
              <Typography sx={{ flexGrow: 1, fontSize: 20, textAlign: "right" }}>
                1 TRATAMIENTO:
              </Typography>
              <Box component="div" sx={{ justifyContent: "end", display: "flex" }}>
                <BuyButton cart={[treatment.id]} sx={{ margin: 0.5 }} />
                <GiftButton cart={[treatment.id]} sx={{ margin: 0.5 }} />
              </Box>
            </Box>
            {promotions.map(promo => {
              return (
                <Box component="div"
                  key={promo.id}
                  sx={{ display: isMobile ? "block" : "flex", alignItems: "center" }}
                >
                  <Typography sx={{ flexGrow: 1, fontSize: 20, textAlign: "right" }}>
                    {promo.discounts[0].quantity} TRATAMIENTOS:
                  </Typography>
                  <Box component="div" sx={{ justifyContent: "end", display: "flex" }}>
                    <BuyButton
                      cart={new Array(promo.discounts[0].quantity).fill(promo.discounts[0].id)}
                      sx={{ margin: 0.5 }}
                    />
                    <GiftButton
                      cart={new Array(promo.discounts[0].quantity).fill(promo.discounts[0].id)}
                      sx={{ margin: 0.5 }}
                    />
                  </Box>
                </Box>
              )
            })}
          </Box>
        ) : null}
        <Divider sx={{ my: 2 }} />
        <ErrorBoundary>
          <Suspense>
            {treatment.canBookOnline ? <NextAvailableBookings ids={[treatment]} /> : null}
          </Suspense>
        </ErrorBoundary>
      </Box>
    </Box>
  )
}
export { Treatment }
