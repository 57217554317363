import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel"
import { Box, Card, SxProps, Typography } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { SearchItem, useDate, useDeviceInfo, useSearchResults } from "@in-and-out-belleza/hooks"
import { BuyButton } from "../Buttons/BuyButton"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import styled from "@emotion/styled"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { Link } from "react-router-dom"

type Props = {
  include: Array<string>
}

const isSearchItem = (item: SearchItem | undefined): item is SearchItem => !!item

const ButtonStyled = styled(Box)(() => {
  return {
    display: "flex",
    "& button": { border: "none" },
  }
})

const Dots = styled(DotGroup)(() => {
  return {
    width: "300px",
    height: 24,
    overflow: "hidden",
    margin: "0 auto",
    "& button": {
      padding: 0,
      border: "none",
      background: "#ddd",
      height: 12,
      width: 12,
      marginLeft: 8,
      borderRadius: 8,
    },
    "& button:disabled": {
      background: "#888",
    },
  }
})

const DiscountText = ({ item, sx }: { item: SearchItem; sx?: SxProps }) => {
  const { db } = usePublicDb()
  const { currency } = useDate()
  const { real } = getCartTotal(db, [item.id])
  const bonusCard = db.bonusCards.find(sub => sub.id === item.id)
  if (!bonusCard) return null
  if (bonusCard.credit) return null
  const p = bonusCard.treatments
    .map(i => {
      return getCartTotal(db, new Array(i.quantity).fill(i.id)).total
    })
    .flat()
    .reduce((tot, i) => i + tot, 0)
  return <Typography sx={sx}>Ahorra {currency(p - real)}</Typography>
}

const colors = ["#afc9ae", "#aeb6c9", "#aec8c9"]

const CarouselMoreSelled = ({ include }: Props) => {
  const { db } = usePublicDb()
  const { isMobile } = useDeviceInfo()
  const search = useSearchResults({ include }).sort((first, second) => {
    if (db.cartPriority.indexOf(first.id) === -1) return -1
    if (db.cartPriority.indexOf(second.id) === -1) return -1
    return db.cartPriority.indexOf(second.id) - db.cartPriority.indexOf(first.id)
  })

  const results = db.cartPriority
    .map(id => {
      return search.find(sub => sub.id === id)
    })
    .filter(isSearchItem)
    .filter(i => i.canSellOnline)
    .filter((_, index) => index < 17)

  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={results.length}
      isIntrinsicHeight
      visibleSlides={isMobile ? 1 : 3}
    >
      <Box component="div" sx={{ display: "flex" }}>
        {isMobile ? null : (
          <ButtonStyled>
            <ButtonBack>
              <ArrowBackIos />
            </ButtonBack>
          </ButtonStyled>
        )}
        <Slider>
          {results.map((item, index) => {
            return (
              <Slide key={`${item.id}-${index}`} index={index} style={{ margin: "0 2px" }}>
                <Card
                  sx={{
                    background: `url(${item.photo}) ${colors[index % 3]}`,
                    backgroundSize: "cover",
                    height: "160px",
                  }}
                >
                  <Typography
                    component={Link}
                    sx={{
                      display: "block",
                      backgroundColor: "#00000066",
                      p: 1,
                      color: "#fff",
                      height: 60,
                      overflow: "hidden",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    to={item.href}
                  >
                    {item.label}
                  </Typography>
                  <DiscountText item={item} sx={{ ml: 2, mt: 1 }} />
                  <BuyButton
                    cart={[item.id]}
                    variant="contained"
                    sx={{ position: "absolute", bottom: "8px", right: "8px" }}
                  />
                </Card>
              </Slide>
            )
          })}
        </Slider>
        {isMobile ? null : (
          <ButtonStyled>
            <ButtonNext>
              <ArrowForwardIos />
            </ButtonNext>
          </ButtonStyled>
        )}
      </Box>
      <Dots showAsSelectedForCurrentSlideOnly></Dots>
    </CarouselProvider>
  )
}

export { CarouselMoreSelled }
