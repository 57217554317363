import { cloneElement } from "react"
import { FlowProps } from "../types/flow.types"
import FlowElement from "./FlowElement"
import { getFlowId } from "./useFlow"
const Flow = (props: FlowProps) => {
  const id = getFlowId(props.uniqueName)
  if (process.env["FLOW_DISABLED"]) {
    return cloneElement(props.children, { ...props.children.props, "data-flow": id })
  } else {
    if (window.parent === window || window.parent.name !== "flow") {
      return cloneElement(props.children, { ...props.children.props, "data-flow": id })
    }
    return <FlowElement {...props} id={id} />
  }
}

export { Flow }
