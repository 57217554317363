import { PropsWithChildren } from "react"

import { Box, Divider, Typography } from "@mui/material"

import { Logo } from "@in-and-out-belleza/ui"
import { Wrapper } from "./UserForms.styles"
import { UserLoginStepper } from "./UserLoginStepper"

const UserHeader = ({ text, children }: PropsWithChildren & { text: string }) => {
  return (
    <Wrapper>
      <Box
        component="div"
        sx={{ display: "inline-block", width: "auto", verticalAlign: "middle", mr: "20px" }}
      >
        <Logo size={25} />
      </Box>
      <Typography sx={{ whiteSpace: "nowrap", fontSize: 20, mt: 1, display: "inline-block" }}>
        {text}
      </Typography>
      <Divider sx={{ mt: 1 }} />
      <br />
      {children}
    </Wrapper>
  )
}

const UserForms = () => {
  // if (accountReset.isPath()) {
  //   return (
  //     <UserHeader text="Restaurar cuenta">
  //       <UserResetForm />
  //     </UserHeader>
  //   )
  // }

  // if (accountEmailSent.isPath()) {
  //   return (
  //     <UserHeader text="Hecho!">
  //       <Typography>
  //         Controla tu correo.
  //         <br />
  //         <br />
  //         Te hemos enviado un mensaje.
  //       </Typography>
  //     </UserHeader>
  //   )
  // }

  // if (accountConfirmation.isPath()) {
  //   return (
  //     <UserHeader text="Cuenta Activada">
  //       <Typography>
  //         Tu cuenta ya esta activada.
  //         <br />
  //         <br />
  //         <Button component={Link} to={account.url()} fullWidth variant="contained">
  //           ENTRA EN MI CUENTA
  //         </Button>
  //       </Typography>
  //     </UserHeader>
  //   )
  // }
  // if (accountRegister.isPath()) {
  //   return (
  //     <UserHeader text="Crea tu cuenta">
  //       <UserRegisterForm />
  //     </UserHeader>
  //   )
  // }

  // if (accountRecover.isPath()) {
  //   return (
  //     <UserHeader text="Recuperar contraseña">
  //       <UserRecoverForm />
  //     </UserHeader>
  //   )
  // }

  return (
    <UserHeader text="Entra o crea tu cuenta">
      <UserLoginStepper
        onClose={async () => {}}
        description="Utilizaremo este correo para todas las comunicaciones."
      />
    </UserHeader>
  )
}

export { UserForms, UserHeader }
