import { useLocation } from "react-router-dom"

import { Box, Divider, Paper, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDate, useDeviceInfo, useSearchResults } from "@in-and-out-belleza/hooks"
import { BuyButton } from "../Buttons/BuyButton"
import { NotFoundPage } from "../NotFoundPage"

const PromotionsDetail = () => {
  const { isMobile } = useDeviceInfo()
  const { db } = usePublicDb()
  const { endsIn, shortDate } = useDate()
  const { pathname } = useLocation()
  const results = useSearchResults({})
  const item = db.promotions.find(sub => sub.href === pathname)

  if (!item) return <NotFoundPage />

  const discounts = item.discounts.filter(dis => dis.id)

  return (
    <Paper sx={{ margin: "8px", padding: 1 }}>
      <Typography color="primary" fontSize={24}>
        {item.title}
      </Typography>
      <Divider />
      {item.to < Date.now() ? (
        <Typography>La promocion acabò el dia {shortDate(item.to)}</Typography>
      ) : item.from > Date.now() ? (
        <Typography>Empieza {endsIn(item.from)}</Typography>
      ) : (
        <Typography>Termina {endsIn(item.to)}</Typography>
      )}
      <Divider />
      <Box component="div" sx={{ width: "100%" }}>
        <img src={item.photo} alt={item.title} width="100%" />
      </Box>
      <Divider />
      {discounts.length > 1 &&
        discounts.length < 10 &&
        item.discounts.slice(0, 4).map(dis => {
          return (
            <Box
              key={dis.id}
              component="div"
              sx={{ my: 1, display: isMobile ? undefined : "flex", alignItems: "center" }}
            >
              <Typography flexGrow={1}>{results.find(i => i.id === dis.id)?.label}</Typography>
              <BuyButton cart={[dis.id]} variant="contained" sx={{ my: 1 }} />
            </Box>
          )
        })}
      {discounts.length === 1 && (
        <BuyButton
          cart={item.discounts.filter(i => i.operator === "and").map(dis => dis.id)}
          variant="contained"
          sx={{ my: 1 }}
        />
      )}
      <Divider />
      <Box component="div" sx={{ pt: 2 }} dangerouslySetInnerHTML={{ __html: item.description }} />
    </Paper>
  )
}

export { PromotionsDetail }
