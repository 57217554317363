import { Box, InputBase, Paper, Typography } from "@mui/material"
import { CreditCardImages } from "./CreditCardsImages"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { Dispatch } from "react"
import { SetStateAction } from "jotai"
import { AdminOrder, Gift } from "@in-and-out-belleza/api/interfaces"

type Props = {
  value: number
  disabled: boolean
  email: string
  cart: Array<string>
  gifts: Array<Gift>
  sendTo: AdminOrder["sendTo"]
  setEmail: Dispatch<SetStateAction<string>>
}
const jetID = process.env["JET_ID"] ?? ""
// Testing cards:
// direct -> 4539232076648253 05 / 25 123
// challengeUrl -> 4626134723281831 05 / 25 123

const SabadellForm = ({ value, disabled, email, setEmail, cart, gifts, sendTo }: Props) => {
  const { isMobile } = useDeviceInfo()
  return (
    <Paper sx={{ mt: 1 }}>
      <Box component="div" sx={{ background: "#d9e6d9", display: "flex", "& input": { pl: 1 } }}>
        {!isMobile ? <Box component="div" sx={{ flexGrow: 1 }}></Box> : null}
        <Box
          component="div"
          sx={{ maxWidth: 500, flexGrow: 2, m: isMobile ? 0 : 1, p: 1, background: "white" }}
          className="container"
        >
          <Typography>RELLENA TODOS LOS DATOS:</Typography>
          <div className="row">
            <aside className="col-sm-6">
              <article className="card">
                <div className="card-body p-5">
                  <form id="paycometPaymentForm" action={`/api/sabadell`} method="POST">
                    <input type="hidden" name="amount" value={value} />
                    <input type="hidden" name="cart" value={JSON.stringify(cart)} />
                    <input type="hidden" name="gifts" value={JSON.stringify(gifts)} />
                    <input type="hidden" name="sendTo" value={JSON.stringify(sendTo)} />
                    <input type="hidden" data-paycomet="jetID" value={jetID} />
                    <div className="form-group" style={{ marginBottom: 8 }}>
                      {/* <label htmlFor="username">Full name (on the card)</label> */}
                      <div className="input-group">
                        <InputBase
                          type="email"
                          className="form-control sabadell-input-text"
                          name="cc-email"
                          placeholder="Email de confirmacion"
                          value={email}
                          onChange={ev => setEmail(ev.target.value)}
                        />
                      </div>
                    </div>

                    <Box component="div" className="form-group" sx={{ mt: 1 }}>
                      {/* <label htmlFor="cardNumber">Card number</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-credit-card"></i>
                          </span>
                        </div>
                        <div id="paycomet-pan" style={{ padding: "0px", height: "46px" }}></div>
                        <input
                          autoComplete="cc-number"
                          paycomet-style="border-radius: 4px; width: calc(100% - 56px); height: 34px; font-size:16px; border: 1px solid #bbb; outline: none;"
                          paycomet-name="pan"
                          paycomet-placeholder="Numero Tarjeta"
                        />
                      </div>
                    </Box>
                    <Box component="div" className="row" sx={{ display: "flex", mb: 1 }}>
                      <div className="form-group" style={{ width: "100%" }}>
                        {/* <label htmlFor="username">Full name (on the card)</label> */}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-user"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control sabadell-input-text"
                            data-paycomet="cardHolderName"
                            name="cc-name"
                            placeholder="Titular de la Tarjeta"
                            autoComplete="cc-name"
                          />
                        </div>
                      </div>
                    </Box>
                    <Box component="div" className="row" sx={{ display: "flex", mb: 1 }}>
                      <Box component="div" className="col-sm-8">
                        <div className="form-group">
                          <div className="form-inline">
                            <input
                              type="number"
                              name="cc-exp-month"
                              autoComplete="cc-exp-month"
                              className="sabadell-input-text"
                              placeholder="MM"
                              style={{ width: "60px" }}
                              data-paycomet="dateMonth"
                            />{" "}
                            /{" "}
                            <input
                              type="number"
                              name="cc-exp-year"
                              autoComplete="cc-exp-year"
                              className="sabadell-input-text"
                              placeholder="YY"
                              style={{ width: "60px" }}
                              data-paycomet="dateYear"
                            />
                          </div>
                        </div>
                      </Box>

                      <Box component="div" sx={{ ml: 1 }}>
                        <div className="form-group">
                          <div
                            id="paycomet-cvc2"
                            style={{ height: "36px", padding: "0px", width: "110px" }}
                          ></div>
                          <input
                            paycomet-name="cvc2"
                            paycomet-style="border-radius: 4px; width: 80px; height: 30px; font-size:16px; border: 1px solid #bbb; outline: none; padding: 1px 0 1px 8px;"
                            paycomet-placeholder="CVV2"
                            className="form-control"
                            required
                            type="text"
                            autoComplete="cc-csc"
                          />
                        </div>
                      </Box>
                    </Box>
                    <div id="paymentErrorMsg"></div>
                    <button
                      disabled={disabled}
                      className="subscribe btn btn-primary btn-block sabadell-pay-button"
                      type="submit"
                    >
                      PAGAR
                    </button>
                  </form>
                </div>
                <CreditCardImages />
              </article>
            </aside>
          </div>
        </Box>
        {!isMobile ? <Box component="div" sx={{ flexGrow: 1 }}></Box> : null}
      </Box>
    </Paper>
  )
}

export { SabadellForm }
