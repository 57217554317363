import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import { useBooking } from "../../atoms/atomBooking"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { mapToTreatmentResult } from "../../queries/treatments-results"
import { Splash } from "@in-and-out-belleza/ui"
import { BookingSelect } from "./BookingSelect"
import { FutureBooking } from "./FutureBookings"

import { LoggedBooking } from "./LoggedBooking"
import { BookingSuggestionList } from "./BookSuggestion/BookingSuggestionList"
import { useMemo } from "react"

const Booking = () => {
  const { db } = usePublicDb()
  const { isMobile } = useDeviceInfo()
  const { user } = useAuth()
  const { treatments, setOpenSearch, setTreatments } = useBooking()

  const trtThread = useMemo(
    () => db.treatments.filter(item => ["TRT-47", "TRT-48", "TRT-53", "TRT-49"].includes(item.id)),
    [db.treatments],
  )
  const trtBeauty = useMemo(
    () =>
      db.treatments.filter(item => ["TRT-55", "TRT-104", "TRT-57", "TRT-105"].includes(item.id)),
    [db.treatments],
  )
  const trtFace = useMemo(
    () => db.treatments.filter(item => ["TRT-100", "TRT-11", "TRT-113", "TRT-4"].includes(item.id)),
    [db.treatments],
  )
  const trtBody = useMemo(
    () => db.treatments.filter(item => ["TRT-77", "TRT-115", "TRT-101"].includes(item.id)),
    [db.treatments],
  )
  const trtIndiba = useMemo(
    () => db.treatments.filter(item => ["TRT-88", "TRT-87", "TRT-86"].includes(item.id)),
    [db.treatments],
  )
  if (user.isLoggingIn)
    return (
      <Box component="div" sx={{ height: "400px" }}>
        <Splash />
      </Box>
    )

  return (
    <Box component="div" sx={{ m: 1 }}>
      <BookingSelect />
      {treatments.length ? (
        <LoggedBooking />
      ) : isMobile ? (
        <Paper sx={{ display: "flex", justifyContent: "center", p: 1 }}>
          <Button
            onClick={() => {
              setOpenSearch(true)
              document.getElementById("select-treatment")?.click()
            }}
            variant="contained"
          >
            NUEVA CITA
          </Button>
        </Paper>
      ) : null}
      {user.nextBookings.length && !treatments.length ? (
        <Box component="div">
          <Typography sx={{ mt: 1 }}>TUS PROXIMAS CITAS:</Typography>
          {user.nextBookings.map(booking => {
            return <FutureBooking key={booking._id} booking={booking} />
          })}
        </Box>
      ) : null}
      {user.bookings.length && treatments.length === 0 ? (
        <Box component="div" sx={{ mt: 1 }}>
          <Typography>DE TU CITAS:</Typography>
          <Box component="div" sx={{ mt: 1, mb: 1, p: 1 }}>
            <Grid container spacing={2} sx={{ display: "flex" }}>
              {user.bookings
                .sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())
                .map(item => item.treatments)
                .flat()
                .filter((o, i, a) => a.indexOf(o) === i)
                .map(book => db.treatments.find(item => item.id === book))
                .filter(item => item)
                .filter((_, index) => index < 4)
                .map(trt => {
                  return (
                    <Grid
                      sx={{ alignItems: "stretch", display: "flex", cursor: "pointer" }}
                      key={trt?.id}
                      item
                      xs={6}
                      sm={3}
                      onClick={() => {
                        if (!trt) return
                        setTreatments([mapToTreatmentResult(trt)])
                      }}
                    >
                      <Paper sx={{ p: 1, width: "100%" }}>
                        <Box component="div" sx={{ mr: 2 }}>
                          <BeautyIcon name="calendar" size={16} />
                        </Box>
                        <Box
                          component="div"
                          sx={{ display: "flex", flexDirection: "column", flexGrow: 2 }}
                        >
                          <Typography sx={{ color: "#888" }} fontSize={11}>
                            {trt?.type}
                          </Typography>
                          <Typography fontSize={14}>{trt?.title}</Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  )
                })}
            </Grid>
          </Box>
        </Box>
      ) : null}
      {treatments.length === 0 || !isMobile ? (
        <Box component="div">
          {treatments.length !== 0 ? <Divider sx={{ my: 3 }} /> : null}
          <Typography color="primary.main">ALGUNAS SUGERENCIAS:</Typography>
          <Divider sx={{ my: 1 }} />
          <Typography>Depilacion con hilo:</Typography>
          <BookingSuggestionList setTreatments={setTreatments} treatments={trtThread} />
          <Divider sx={{ my: 1 }} />
          <Typography>Indiba:</Typography>
          <BookingSuggestionList setTreatments={setTreatments} treatments={trtIndiba} />
          <Divider sx={{ my: 1 }} />
          <Typography>Faciales:</Typography>
          <BookingSuggestionList setTreatments={setTreatments} treatments={trtFace} />
          <Divider sx={{ my: 1 }} />
          <Typography>Corporales:</Typography>
          <BookingSuggestionList setTreatments={setTreatments} treatments={trtBody} />
          <Divider sx={{ my: 1 }} />
          <Typography>Beauty:</Typography>
          <BookingSuggestionList setTreatments={setTreatments} treatments={trtBeauty} />
        </Box>
      ) : null}
    </Box>
  )
}

export { Booking }
