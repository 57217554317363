import { useMemo } from "react"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useAuth } from "@in-and-out-belleza/authentication"
import {
  getBonusCardCredits,
  getBonusTreatments,
  getOrdersCredits,
} from "@in-and-out-belleza/api/utils"

const useUserInfo = () => {
  const { user } = useAuth()
  const { db } = usePublicDb()
  return useMemo(() => {
    const fromBonusCards = getBonusTreatments(user, db)
    const bonusCardsCredit = getBonusCardCredits(user.bonusCards) + getOrdersCredits(user, db)
    const availableTreatments = fromBonusCards
    const totalAvailableTreatments = Object.values(availableTreatments).reduce(
      (acc, item) => acc + item.total - item.used,
      0,
    )
    return { availableTreatments, totalAvailableTreatments, bonusCardsCredit }
  }, [user, db])
}

export { useUserInfo }
