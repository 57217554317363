import { Box, Button, ButtonProps, styled, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useAuth } from "@in-and-out-belleza/authentication"
import { getCartTotal, shouldShowPrice } from "@in-and-out-belleza/api/utils"
import { BeautyIcon, GenericDialog } from "@in-and-out-belleza/ui"
import { useCart } from "../../atoms/atomCart"
import { useNav } from "../../hooks/useNav"
import { Link } from "react-router-dom"
import { UserLoginStepper } from "../User/UserLoginStepper"
import { useState } from "react"

type Props = ButtonProps & {
  cart: Array<string>
}

const ButtonStyled = styled(Button, { shouldForwardProp: prop => prop !== "isDiscounted" })<{
  isDiscounted: boolean
}>(({ theme, isDiscounted }) => ({
  color: theme.palette.primary.contrastText,
  fill: theme.palette.primary.contrastText,
  backgroundColor: isDiscounted ? theme.palette.primary.dark : theme.palette.primary.main,
  "&:active, &:hover": {
    color: theme.palette.primary.contrastText,
    backgroundColor: isDiscounted ? theme.palette.primary.dark : theme.palette.primary.main,
    fill: theme.palette.primary.contrastText,
  },
}))

const BuyButton = ({ cart, ...rest }: Props) => {
  const { user } = useAuth()
  const { db } = usePublicDb()
  const { cart: cartNav, shop } = useNav()
  const { setCart } = useCart()
  const showPrice = shouldShowPrice(db, cart, user)
  const price = getCartTotal(db, cart)
  const isDiscounted = price.real !== price.total
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)

  if (!price.count) return null

  if (price.cantSell) {
    return (
      <Box component="em" sx={{ lineHeight: 2.5, mr: 1 }}>
        PRECIO A CONSULTAR
      </Box>
    )
  }

  if (!showPrice) {
    return (
      <Box component="div">
        <GenericDialog title="" open={open} onClose={() => setOpen(false)}>
          <UserLoginStepper
            description="Crea una cuenta para ver los precios de todos los productos"
            onClose={async () => {
              setOpen(false)
            }}
          />
        </GenericDialog>
        <ButtonStyled
          onClick={() => setOpen(true)}
          startIcon={<BeautyIcon name="products" size={20} />}
          isDiscounted={isDiscounted}
          {...rest}
        >
          CONSULTAR PRECIO
        </ButtonStyled>
      </Box>
    )
  }

  return (
    <ButtonStyled
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        setCart(prev => prev.concat(cart))
        closeSnackbar()
        if (!shop.isPath()) {
          enqueueSnackbar(
            <>
              <Typography sx={{ flexGrow: 1 }}>Añadido</Typography>
              <Button
                onClick={() => closeSnackbar()}
                component={Link}
                variant="contained"
                sx={{ backgroundColor: "green", display: "block" }}
                to={cartNav.url()}
              >
                VER CARRITO
              </Button>
            </>,
            { autoHideDuration: 5000, variant: "success" },
          )
        }
      }}
      isDiscounted={isDiscounted}
      {...rest}
    >
      {isDiscounted ? "EN PROMO" : "COMPRA"}
      {` POR ${price.real.toFixed(2)} EURO`}
    </ButtonStyled>
  )
}

export { BuyButton }
