import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyPartyIntro } from "./BeautyPartyIntro"
import { BeautyPartyOutro } from "./BeautyPartyOutro"

const BeautyParties = () => {
  const { db } = usePublicDb()

  return (
    <Box component="div">
      <BeautyPartyIntro />
      {db.beautyParties.map(item => (
        <Paper
          key={item.id}
          sx={{ m: 1, position: "relative", display: "block" }}
          component={Link}
          to={item.href}
        >
          <Box component="div" sx={{ p: 1 }}>
            <Typography color="primary" variant="h6">
              {item.title}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
          </Box>
          <Divider />
          <Button fullWidth>VER</Button>
        </Paper>
      ))}
      <BeautyPartyOutro />
    </Box>
  )
}

export { BeautyParties }
