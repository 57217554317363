import { useContext } from "react"
import { AuthContext } from "./AuthContextProvider"

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be called within an AuthProvider")
  }
  return context
}

export { useAuth }
