import { AdminWorker, Center } from "@in-and-out-belleza/api/interfaces"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { atom, useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { useEffect } from "react"
import { TreatmentsSearchItem } from "../queries/treatments-results"

const _center = atomWithStorage<Center>("inandout.defaultCenter", {
  _id: "",
  color: "",
  id: "",
  index: 0,
  label: "",
  address: "",
  phone: "",
  mobile: "",
  closed: true,
  physical: true,
  billRef: "",
  bbvaRef: "",
  rooms: [],
  google: { latitude: "", longitude: "", placeId: "", embedSrc: "" },
})
const _hour = atom<{ timestamp: number; duration: number; worker: AdminWorker } | null>(null)
const _treatments = atom<Array<TreatmentsSearchItem>>([])
const _openSearch = atom<boolean>(false)

const useBooking = () => {
  const { db } = usePublicDb()
  const [center, setCenter] = useAtom(_center)
  const [hour, setHour] = useAtom(_hour)
  const [treatments, setTreatments] = useAtom(_treatments)
  const [openSearch, setOpenSearch] = useAtom(_openSearch)

  useEffect(() => {
    setCenter(prev => {
      if (prev.closed) return db.centers[0]
      return prev
    })
  }, [db, setCenter])

  return { center, hour, treatments, openSearch, setTreatments, setCenter, setHour, setOpenSearch }
}

export { useBooking }
