/* eslint-disable @typescript-eslint/no-this-alias */
import { Middleware } from "../../utils/Middleware"

const isSavedWebSocketUrl = (url: string) => {
  return url.includes("wss")
}

export const WsMiddleware = function () {
  const send = new Middleware<
    [
      string | ArrayBufferLike | Blob | ArrayBufferView,
      (data: string | ArrayBufferLike | Blob | ArrayBufferView) => void,
      WebSocket,
    ]
  >()
  const message = new Middleware<[MessageEvent, (ev: MessageEvent) => void, WebSocket]>()

  const sendMessage = send.use((next, data, superFn, context) => {
    superFn.call(context, data)
    next(data, superFn, context)
    return [data, superFn, context]
  })
  const onMessage = message.use((next, event, callback, context) => {
    callback.call(context, event)
    next(event, callback, context)
    return [event, callback, context]
  })

  class _WebSocket extends WebSocket {
    constructor(url: string | URL, protocols?: string | Array<string>) {
      const superUrl = url
      super(superUrl, protocols)
    }

    send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
      if (!isSavedWebSocketUrl(this.url)) super.send(data)
      return sendMessage(data, super.send, this)
    }

    set onmessage(callback: (ev: MessageEvent) => void) {
      const self = this
      super.onmessage = function (event: MessageEvent) {
        if (!isSavedWebSocketUrl(self.url)) {
          callback.call(this, event)
        } else {
          onMessage(event, callback, self)
        }
      }
    }
  }

  window.WebSocket = _WebSocket

  return { send, message }
}
