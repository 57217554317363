import { useAtom } from "jotai"
import { _flowElements } from "../atoms/state"
import { useEffect, useMemo } from "react"
import { FlowProps } from "../types/flow.types"
import { getSelector } from "../middlewares/user-actions"

export const isHtmlElement = (el: unknown): el is HTMLElement => el instanceof HTMLElement
export const getFlowId = (uniqueName: string) =>
  `flow-container-${uniqueName.toLocaleLowerCase().replace(/\s/g, "-")}`

const useFlow = ({ elements, uniqueName, id }: Omit<FlowProps, "children"> & { id: string }) => {
  const [, setElements] = useAtom(_flowElements)

  const memo = useMemo(() => {
    return elements ?? []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(elements)])

  useEffect(() => {
    const els = memo.map((item, key) => {
      const selector = `${!item.portal ? `[data-flow="${id}"] ` : ""}${item.selector ?? ""}`
      const ret: Array<HTMLElement> = Array.from(document.querySelectorAll(selector))
        .flat()
        .filter(isHtmlElement)
      return ret.map((el, itemIndex) => {
        const sel = getSelector([], el)
        return {
          el,
          ...item,
          selector: sel,
          itemIndex: ret.length === 1 ? null : itemIndex,
          key: key.toString(),
        }
      })
    })
    const hs = Array.from(els).flat()
    setElements(prev => [...prev, ...hs.map(el => ({ ...el, name: uniqueName }))])

    return () => {
      setElements(prev => prev.filter(i => !hs.find(sub => sub.el === i.el)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setElements, memo, uniqueName])
}

export { useFlow }
