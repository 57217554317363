import { Box, Button, Divider, Link, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react"
import { usePublicDb } from "@in-and-out-belleza/api/resources"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const CallUs = () => {
  const { db } = usePublicDb()
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box component="div">
      <Box component="div" sx={{ width: "100%" }}>
        <Box component="div" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            allowScrollButtonsMobile
          >
            {db.centers.map(item => (
              <Tab
                sx={{ "&.MuiButtonBase-root": { p: 0.8 } }}
                key={item.index}
                label={item.label}
              />
            ))}
          </Tabs>
        </Box>
        {db.centers.map((center, index) => {
          return (
            <TabPanel key={center.index} value={value} index={index}>
              <Box component="div" fontSize={12} sx={{ mb: 2 }}>
                {center.address}
              </Box>
              <Box component="div" sx={{ display: "flex" }}>
                <Typography sx={{ flexGrow: 1 }}>TELEFONO: </Typography>
                <Typography>(+34) {center.phone}</Typography>
              </Box>
              <Divider sx={{ m: 1 }} />
              <Box component="div" sx={{ display: "flex" }}>
                <Typography sx={{ flexGrow: 1 }}>MOVIL: </Typography>
                <Typography>(+34) {center.mobile}</Typography>
              </Box>
              <Divider sx={{ m: 1 }} />
              <Button variant="contained" component={Link} href={`tel:${center.mobile}`}>
                LLAMANOS
              </Button>
            </TabPanel>
          )
        })}
      </Box>
    </Box>
  )
}

export { CallUs }
