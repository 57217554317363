import { Button, ButtonProps, styled } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { emptyGift, useCart } from "../../atoms/atomCart"
import { useNav } from "../../hooks/useNav"
import { Redeem } from "@mui/icons-material"

type Props = ButtonProps & {
  cart: Array<string>
}

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.text.primary,
  fill: theme.palette.text.primary,
  "&:active, &:hover": {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
}))
const GiftButton = ({ cart, ...rest }: Props) => {
  const { db } = usePublicDb()
  const { gift } = useNav()
  const { setCreateGift } = useCart()
  const price = getCartTotal(db, cart)

  if (!price.count) return null

  return (
    <ButtonStyled
      startIcon={<Redeem />}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        setCreateGift({ ...emptyGift, cart })
        gift.navigate()
      }}
      {...rest}
    >
      REGALA
    </ButtonStyled>
  )
}

export { GiftButton }
