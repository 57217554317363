import { PropsWithChildren } from "react"

import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps } from "@mui/material"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"

type Props = PropsWithChildren & {
  open: boolean
  onClose?: (accept: boolean) => void
  onSubmit?: () => Promise<void>
  title: string
  actions?: JSX.Element
  sx?: SxProps
}

export function GenericDialog({
  open,
  onClose = () => {},
  onSubmit,
  title,
  children,
  actions,
  sx,
}: Props) {
  const { isMobile } = useDeviceInfo()
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { m: 1, minWidth: isMobile ? "300px" : "500px", p: 1 },
        ...sx,
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      {onSubmit ? (
        <form
          onSubmit={async ev => {
            ev.preventDefault()
            ev.stopPropagation()
            await onSubmit?.()
            onClose(true)
          }}
        >
          <DialogContent>{children}</DialogContent>
          <DialogActions>{actions}</DialogActions>
        </form>
      ) : (
        <>
          <DialogContent>{children}</DialogContent>
          <DialogActions>{actions}</DialogActions>
        </>
      )}
    </Dialog>
  )
}
