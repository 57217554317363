import { Button, ButtonGroup, Paper, TextField, Typography } from "@mui/material"
import { Box } from "@mui/material"
import { useCart } from "../../atoms/atomCart"

const CartProductInfo = () => {
  const { sendTo, setSendTo } = useCart()
  return (
    <Box component="div" sx={{ mt: 1 }}>
      <Paper sx={{ p: 1, display: "flex", alignItems: "center", gap: 1 }}>
        <Typography id="demo-radio-buttons-group-label">ES UN REGALO?</Typography>
        <ButtonGroup aria-label="outlined primary button group">
          <Button
            variant={sendTo.isGift === 0 ? "contained" : "outlined"}
            onClick={() => setSendTo(prev => ({ ...prev, isGift: 0 }))}
          >
            NO
          </Button>
          <Button
            variant={sendTo.isGift === 1 ? "contained" : "outlined"}
            onClick={() => setSendTo(prev => ({ ...prev, isGift: 1 }))}
          >
            SI
          </Button>
        </ButtonGroup>
      </Paper>
      <Paper sx={{ p: 1, mt: 1 }}>
        <Typography color="red">Se hacen envios solo en España</Typography>

        <Typography fontSize={12}>
          Los productos se entregaran en un <strong>maximo de 5 dias laborables</strong>
        </Typography>
      </Paper>

      <Paper sx={{ p: 1, mt: 1, "& .MuiFormControl-root": { m: 0.5 } }}>
        <Typography>ENVIAME LOS PRODUCTOS AQUÍ:</Typography>
        <TextField
          size="small"
          value={sendTo.name}
          onChange={event => setSendTo(prev => ({ ...prev, name: event.target.value }))}
          autoComplete="name"
          fullWidth
          label="Nombre y Appelido"
          margin="normal"
          style={{ backgroundColor: "transparent" }}
          variant="outlined"
        />
        <TextField
          size="small"
          value={sendTo.address}
          onChange={event => setSendTo(prev => ({ ...prev, address: event.target.value }))}
          autoComplete="street-address"
          fullWidth
          label="DIRECCIÓN"
          margin="normal"
          style={{ backgroundColor: "transparent" }}
          variant="outlined"
        />
        <TextField
          size="small"
          value={sendTo.city}
          onChange={event => setSendTo(prev => ({ ...prev, city: event.target.value }))}
          autoComplete="address-level2"
          fullWidth
          label="POBLACION"
          margin="normal"
          style={{ backgroundColor: "transparent" }}
          variant="outlined"
        />
        <TextField
          size="small"
          value={sendTo.cap}
          onChange={event => setSendTo(prev => ({ ...prev, cap: event.target.value }))}
          autoComplete="postal-code"
          fullWidth
          label="CODIGO POSTAL"
          margin="normal"
          style={{ backgroundColor: "transparent" }}
          variant="outlined"
        />
        <TextField
          size="small"
          value={sendTo.tel}
          onChange={event => setSendTo(prev => ({ ...prev, tel: event.target.value }))}
          autoComplete="tel"
          fullWidth
          label="TELEFONO"
          margin="normal"
          style={{ backgroundColor: "transparent" }}
          variant="outlined"
        />
      </Paper>
    </Box>
  )
}

export { CartProductInfo }
