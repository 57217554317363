import { useEffect, useState } from "react"
import { useMediaQuery, useTheme } from "@mui/material"
import UAParser from "ua-parser-js"

const _isIPad = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /ipad/.test(userAgent)
}

const parser = new UAParser(navigator.userAgent)
const browser = parser.getBrowser().name || ""
const OS = parser.getOS().name || ""
const device = parser.getDevice().model || ""
const isIos = /iOS/i.test(OS)
const isSafari = /Safari/i.test(browser)
const isIPad = /iPad/i.test(device)

const useDeviceInfo = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [height, setHeight] = useState<number>(window.innerHeight)
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [scrollY, setScrollY] = useState<number>(0)
  const isStandalone = useMediaQuery("(display-mode: standalone)")

  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    const scroll = () => {
      setScrollY(window.scrollY)
    }
    window.addEventListener("resize", resize)
    window.addEventListener("scroll", scroll)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  return { isMobile, height, width, isSafari, isIos, isIPad, isStandalone, scrollY }
}

export { useDeviceInfo }
