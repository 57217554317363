import { atom, useAtom } from "jotai"
import { atomsWithQuery } from "jotai-tanstack-query"

import { getApiClient } from "@in-and-out-belleza/api/client"
import { AdminDb, fromRawDb } from "@in-and-out-belleza/api/interfaces"

const dbAtom = atom(1)

const oneDay = 24 * 60 * 60 * 1000

const [_publicDb] = atomsWithQuery(get => ({
  queryKey: ["public-db", get(dbAtom)],
  queryFn: async () => {
    const client = getApiClient()
    const { data } = await client.get<AdminDb>("/api/public-db")
    return fromRawDb(data)
  },
  staleTime: oneDay,
  cacheTime: oneDay,
  refetchOnWindowFocus: true,
}))

const usePublicDb = () => {
  const [db] = useAtom(_publicDb)
  return { db }
}

export { usePublicDb }
