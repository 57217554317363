import { Add, Remove } from "@mui/icons-material"
import { Box, Divider, IconButton, Paper, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { useCart } from "../../atoms/atomCart"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { useCartGrouped } from "./useCartGrouped"

const CartProducts = () => {
  const { db } = usePublicDb()
  const { setCart, cart } = useCart()
  const { products } = useCartGrouped(cart)

  if (!products.length) return null

  return (
    <Paper sx={{ p: 1 }}>
      PRODUCTOS:
      {products.map((el, index) => {
        return (
          <Box component="div" key={el.id}>
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ flexGrow: 1 }}
                color="green"
                component={Link}
                to={el.item?.href ?? ""}
              >
                {el.item?.title} [x{el.quantity}]
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  setCart(prev => {
                    prev.splice(prev.indexOf(el.id), 1)
                    return [...prev]
                  })
                }}
              >
                <Remove />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  setCart(prev => [...prev, el.id])
                }}
              >
                <Add />
              </IconButton>
              <Typography sx={{ textAlign: "right", minWidth: "100px" }}>
                {getCartTotal(db, new Array(el.quantity).fill(el.id)).total.toFixed(2)} €
              </Typography>
            </Box>
            {index !== products.length - 1 ? <Divider /> : null}
          </Box>
        )
      })}
    </Paper>
  )
}

export { CartProducts }
