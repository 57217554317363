import {
  AdminBonusCard,
  AdminDb,
  AdminPromotion,
  BonusCard,
  PublicDb,
} from "@in-and-out-belleza/api/interfaces"
import { getStdCartTotal } from "./getCartTotal"

export function bonusCardToPromotion(
  db: AdminDb | PublicDb,
  bonusCard: BonusCard | AdminBonusCard,
): AdminPromotion {
  const sum = bonusCard.treatments.reduce(
    (tot, trt) => tot + trt.quantity * getStdCartTotal(db, [trt.id]).total,
    0,
  )
  const multiplier = bonusCard.price / sum
  return {
    _id: "",
    id: `PROM-${Number(bonusCard.id.replace("TAR-", "") + 1000)}`,
    created: bonusCard.from,
    title: bonusCard.title,
    from: bonusCard.from,
    to: bonusCard.to,
    hashtags: "",
    photo: "",
    discounts: bonusCard.treatments.map(i => {
      return {
        id: i.id,
        quantity: i.quantity,
        operator: "and",
        multiplier,
      }
    }),
    description: "",
  }
}
