import { Box, Typography } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useDate } from "@in-and-out-belleza/hooks"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { ClientOrderDetail } from "./ClientOrderDetail"

const UserOnlineBuy = () => {
  const { isMobile } = useDeviceInfo()
  const { user } = useAuth()
  const { longDate } = useDate()
  const { db } = usePublicDb()

  return (
    <Box component="div">
      {isMobile ? null : (
        <Box component="div" sx={{ display: "flex", m: 1, alignItems: "center", pt: 2 }}>
          <Typography>TUS COMPRAS ONLINE</Typography>
        </Box>
      )}
      {user.orders
        .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
        .map(item => {
          return <ClientOrderDetail order={item} key={item._id} />
        })}
    </Box>
  )
}

export { UserOnlineBuy }
