import { Box, Card, CardContent, Divider, Typography } from "@mui/material"

import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useDate } from "@in-and-out-belleza/hooks"
import { useReviews } from "../../queries/reviews"
import { AppleRating } from "../AppleRating/AppleRating"
import { InfiniteList } from "@in-and-out-belleza/ui"
import { Review } from "../Review/Review"

const Reviews = () => {
  const { monthDate } = useDate()
  const { data, fetchNextPage, hasNextPage } = useReviews()
  const items = (data?.pages.flat() || []).map(rev => ({ ...rev, id: rev._id }))

  return (
    <Box component="div" sx={{ m: 1, pt: 1 }}>
      <Review />
      <Divider sx={{ my: 1 }} />
      <InfiniteList
        items={items}
        fetchNextPage={hasNextPage ? fetchNextPage : undefined}
        renderItem={item => {
          return (
            <Card sx={{ my: 1, p: 1 }}>
              <BeautyIcon name="user" size={30} />
              <Box component="div" sx={{ pl: 2, verticalAlign: "middle", display: "inline-block" }}>
                <Typography>{item.name}</Typography>
                <Typography variant="caption">{monthDate(item.created)}</Typography>
              </Box>
              <Divider sx={{ mb: 1 }} />
              {item.rate !== 0 ? (
                <Box component="div">
                  Valoracion:{" "}
                  <AppleRating sx={{ "& svg": { fontSize: "16px" } }} value={item.rate} /> (
                  {item.rate.toFixed(1)})
                </Box>
              ) : null}
              <CardContent>
                <Typography component="em">"{item.description}"</Typography>
              </CardContent>
            </Card>
          )
        }}
      />
    </Box>
  )
}

export { Reviews }
