import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"

import * as locales from "@mui/material/locale"

type SupportedLocales = keyof typeof locales

const _locale = atom<SupportedLocales>("esES")
const _darkMode = atomWithStorage<boolean>("darkMode", false)

export { _locale, _darkMode }
