import { CarouselProvider, Slide, Slider } from "pure-react-carousel"

import { Card, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDate } from "@in-and-out-belleza/hooks"
import { activePromotions } from "@in-and-out-belleza/api/utils"
import { BuyButton } from "../../Buttons/BuyButton"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { useNav } from "../../../hooks/useNav"
import { dateUtils } from "@in-and-out-belleza/utils"
import { PublicDb } from "@in-and-out-belleza/api/interfaces"

const CarouselPromotions = () => {
  const { db } = usePublicDb()
  const { endsIn, shortDate } = useDate()
  const promotions = activePromotions<PublicDb>(db, Date.now())
  const { isMobile } = useDeviceInfo()
  const { navigate } = useNav()

  return (
    <CarouselProvider
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      totalSlides={promotions.length}
      infinite
      isIntrinsicHeight
      isPlaying
      interval={4000}
      visibleSlides={Math.min(isMobile ? 1 : 3, promotions.length)}
    >
      <Slider>
        {promotions.map((promo, index) => {
          const isActive = dateUtils.startOfTheDay(promo.created).getTime() <= Date.now()
          return (
            <Slide key={promo.id + index} index={index} style={{ margin: "0 2px" }}>
              <Card
                onClick={() => {
                  navigate(promo.href)
                }}
                sx={{
                  cursor: "pointer",
                  minHeight: "150px",
                  p: 1,
                  background: `url(${promo.photo}) no-repeat right`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <Typography sx={{ background: "#ffffff99", borderRadius: "4px", p: "4px" }}>
                  {promo.title}{" "}
                  {isActive ? (
                    <span style={{ fontSize: "11px" }}> - termina {endsIn(promo.to)}</span>
                  ) : (
                    <span style={{ fontSize: "11px" }}> - empienza {shortDate(promo.created)}</span>
                  )}
                </Typography>

                <BuyButton
                  variant="contained"
                  sx={{ position: "absolute", bottom: "8px", right: "8px" }}
                  cart={promo.discounts.filter(i => i.operator === "and").map(item => item.id)}
                />
              </Card>
            </Slide>
          )
        })}
      </Slider>
    </CarouselProvider>
  )
}

export { CarouselPromotions }
