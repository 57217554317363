import { useEffect } from "react"
import { getEvents, sendFlowEvent } from "../../atoms/state"
import { isLocalStorage } from "../../types/flow.types"
import { LocalStorageListener } from "./local-storage-listener"

const md = LocalStorageListener()

const LocalStorageRecording = () => {
  useEffect(() => {
    const dispose = md.before(function (next, ...args) {
      const [key, value] = args
      if (!key.startsWith("flow.")) {
        const des = value === null ? "removes" : "sets"
        const previous = getEvents()
          .filter(isLocalStorage)
          .filter(el => el.data.key === key)
        const prev = previous[previous.length - 1]?.data.value ?? ""
        if (prev !== value) {
          sendFlowEvent({
            type: "local-storage",
            description: `the application ${des} the localStorage "${key}"`,
            data: { key, value: value },
          })
        }
      }
      next(...args)
    })

    return () => {
      dispose()
    }
  }, [])

  return null
}

export { LocalStorageRecording }
