import { matchPath, useLocation, useNavigate } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { xmlUtils } from "@in-and-out-belleza/utils"

const useNav = () => {
  const { db } = usePublicDb()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const getItem = (path: string) => ({
    url: (subPath = "") => {
      if (!subPath) return path
      const [start = ""] = path.split(":")
      return `${start}${subPath}`
    },
    navigate: (subPath = "") => {
      const [start = ""] = path.split(":")
      return navigate(`${start}${subPath}`)
    },
    isPath: () => !!matchPath(path, pathname) ?? false,
  })

  const photos = getItem("/es/fotos/")
  const news = getItem("/es/novedades/")
  const newsDetail = getItem("/es/novedades/:href/")
  const promos = getItem("/es/promociones/")
  const promoDetail = getItem("/es/promociones/:href/")
  const reviews = getItem("/es/comentarios/")
  const beautyPartiesDetail = getItem("/es/beauty-parties/:href")
  const beautyParties = getItem("/es/beauty-parties/")
  const treatments = getItem("/es/tratamientos/")
  const treatmentsFamily = getItem("/es/tratamientos/:menuHref/")
  const treatment = getItem("/es/tratamientos/:menuHref/:href")
  const products = getItem("/es/productos/")
  const productsFamily = getItem("/es/productos/:menuHref/")
  const product = getItem("/es/productos/:menuHref/:href")
  const bonus = getItem("/es/bonos/:href")
  const bonuses = getItem("/es/bonos/")
  const accountBonusCards = getItem("/es/cuenta/bonos/")
  const accountOnlineBuy = getItem("/es/cuenta/compras/")
  const accountBooking = getItem("/es/cuenta/citas/")
  const home = getItem("/es/")
  const cart = getItem("/es/cesta/")
  const shop = getItem("/es/tienda-online/")
  const gift = getItem("/es/regala-belleza/")
  const account = getItem("/es/cuenta/")
  const booking = getItem("/es/citas/")
  const hours = getItem("/es/horario/")
  const locations = getItem("/es/como-llegar/")
  const callUs = getItem("/es/llamanos/")
  const workWithUs = getItem("/es/trabaja-con-nosotros/")
  const posts = getItem("/es/contenido-extra/")

  const navigationTitle = () => {
    if (gift.isPath()) return "REGALA BELLEZA Y BIENESTAR"
    if (accountBonusCards.isPath()) return "TRATAMIENTOS EN TU BONOS"
    if (accountOnlineBuy.isPath()) return "TUS COMPRAS ONLINE"
    if (accountBooking.isPath()) return "TU HISTORICO CITAS"
    if (photos.isPath()) return "FOTOS"
    if (news.isPath()) return "NOVEDADES"
    if (newsDetail.isPath()) {
      const item = db.news.find(item => pathname === item.href)
      if (item) {
        return item.title
      }
      return "NOVEDAD"
    }
    if (promos.isPath()) return "PROMOCIONES ACTIVAS"
    if (promoDetail.isPath()) return "PROMOCION"
    if (reviews.isPath()) return "RESEÑAS"
    if (beautyParties.isPath()) return "BEAUTY PARTIES"
    if (beautyPartiesDetail.isPath()) {
      const item = db.beautyParties.find(item => pathname === item.href)
      if (item) {
        return `Beauty party: ${item.title}`
      }
      return "BEAUTY PARTY"
    }
    if (bonus.isPath()) {
      const bc = db.bonusCards.find(item => pathname === item.href)
      if (bc) {
        return bc.title
      }
      return "BONO"
    }
    if (bonuses.isPath()) return "BONOS"
    if (treatments.isPath()) return "TRATAMIENTOS"
    if (treatment.isPath()) {
      const trt = db.treatments.find(item => pathname === item.href)
      if (trt) {
        return `${trt.type}: ${trt.title}`
      }
      return "TRATAMIENTO"
    }
    if (treatmentsFamily.isPath()) {
      const match = matchPath(treatmentsFamily.url(), pathname)
      if (!match?.params) return "TRATAMIENTOS"
      const { menuHref } = match.params
      return db.treatments.find(item => item.menuHref === menuHref)?.type.toUpperCase() ?? ""
    }
    if (product.isPath()) {
      const trt = db.products.find(item => pathname === item.href)
      if (trt) {
        return `${trt.title}`
      }
      return "PRODUCTO"
    }
    if (products.isPath()) return "PRODUCTOS"
    if (productsFamily.isPath()) {
      const match = matchPath(productsFamily.url(), pathname)
      if (!match?.params) return "PRODUCTOS"
      const { menuHref } = match.params
      return db.products.find(item => item.menuHref === menuHref)?.brand.toUpperCase() ?? ""
    }
    if (posts.isPath()) {
      const item = db.posts.find(item => pathname === item.href)
      if (item) return item.title
      return "BLOG POST"
    }
    return ""
  }

  return {
    home,
    cart,
    shop,
    photos,
    newsDetail,
    news,
    account,
    accountBonusCards,
    accountOnlineBuy,
    accountBooking,
    accountEmailSent: getItem("/es/cuenta/email-sent/"),
    accountReset: getItem("/es/cuenta/reiniciar"),
    accountConfirmation: getItem("/es/cuenta/confirmacion"),
    accountRegister: getItem("/es/cuenta/crear/"),
    accountRecover: getItem("/es/cuenta/recuperar/"),
    booking,
    hours,
    locations,
    callUs,
    beautyParties,
    beautyPartiesDetail,
    reviews,
    promos,
    promoDetail,
    treatmentsFamily,
    treatments,
    treatment,
    bonus,
    bonuses,
    product,
    products,
    productsFamily,
    gift,
    posts,
    back: () => navigate(-1),
    navigate,
    previousFolderUrl: () => {
      const [toRemove = ""] = pathname.match(/[^/]*\/?$/) ?? []
      return pathname.substring(0, pathname.length - toRemove.length)
    },
    navigationTitle,
    documentTitle: () => {
      const navTitle = navigationTitle()
      if (navTitle) return navTitle
      if (home.isPath()) return ""
      if (cart.isPath()) return "COMPRA ONLINE"
      if (shop.isPath()) return "TIENDA ONLINE"
      if (account.isPath()) return "CUENTA"
      if (booking.isPath()) return "CITAS"
      if (hours.isPath()) return "HORARIO"
      if (locations.isPath()) return "DONDE SOMOS"
      if (callUs.isPath()) return "LLAMANOS"
      if (workWithUs.isPath()) return "TRABAJA CON NOSOTROS"
      return ""
    },
    documentDescription: () => {
      if (cart.isPath())
        return "La página de la cesta de In&Out Belleza es el lugar ideal para comprar productos de belleza de alta calidad. Con una amplia selección de productos y un proceso de compra fácil, esta página es imprescindible para cualquiera que busque cuidar su piel y su aspecto personal."
      if (shop.isPath())
        return "La tienda online de In&Out Belleza es un destino esencial para aquellos que buscan productos de cuidado personal de alta calidad. Entre productos para el cuidado de la piel, la tienda ofrece una amplia selección de artículos de marcas líderes en el mercado para satisfacer todas las necesidades de belleza."
      if (booking.isPath())
        return "Reserva online los servicios de belleza de In&Out Malaga. Desde tratamientos faciales hasta masajes corporales, la página ofrece una amplia gama de servicios de belleza con citas fáciles de programar y opciones flexibles para satisfacer las necesidades de cada cliente."
      if (hours.isPath())
        return "La página ofrece información detallada sobre los horarios de apertura y cierre, días festivos y horarios de atención para citas, lo que permite a los clientes planificar su visita de manera efectiva."
      if (locations.isPath())
        return "Información esencial para aquellos que buscan visitar la tienda física. Con un mapa interactivo y direcciones claras, la página facilita a los clientes encontrar la ubicación de la tienda y planificar su ruta de viaje de manera efectiva."
      if (callUs.isPath())
        return "Ponte en contacto con In&Out Belleza. Contacta con unos de nuetros centros."
      if (gift.isPath())
        return "Encontra el regalo perfecto para amigos y familiares que aman el cuidado personal y la belleza. Con una amplia selección de opciones de regalos personalizados, los clientes pueden elegir desde tarjetas de regalo hasta paquetes de productos de belleza y sorprender a sus seres queridos con un regalo que los hará sentir especiales y cuidados."
      if (account.isPath())
        return "Crea tu cuenta personal In&Out para realizar compras y reservas en línea de manera fácil y rápida."
      if (photos.isPath())
        return "Algunas da las fotos mas representativas de In&out Belleza. Nuestras cabinas, nuestro equipo y nuestros tratamientos."
      if (workWithUs.isPath())
        return "Únete a nuestro equipo de belleza en In&Out Belleza y sé parte de una experiencia en el mundo de la estética."
      if (news.isPath())
        return "Todas las novedades desde In&Out, sobre la belleza en Malaga y los últimos lanzamientos y noticias relacionadas con la tienda y los servicios de belleza."
      if (newsDetail.isPath()) {
        const item = db.news.find(item => pathname === item.href)
        if (item) {
          return xmlUtils.stripHtmlTags(item.description).split(".")[0]
        }
        return ""
      }
      if (beautyPartiesDetail.isPath()) {
        const item = db.beautyParties.find(item => pathname === item.href)
        if (item) {
          return xmlUtils.stripHtmlTags(item.description).split(".")[0]
        }
        return ""
      }
      if (bonus.isPath()) {
        const item = db.bonusCards.find(item => pathname === item.href)
        if (item) {
          return xmlUtils.stripHtmlTags(item.description).split(".")[0]
        }
        return ""
      }
      if (treatment.isPath()) {
        const item = db.treatments.find(item => pathname === item.href)
        if (item) {
          return xmlUtils.stripHtmlTags(item.description).split(".")[0]
        }
        return ""
      }
      if (product.isPath()) {
        const item = db.products.find(item => pathname === item.href)
        if (item) {
          return xmlUtils.stripHtmlTags(item.description).split(".")[0]
        }
        return ""
      }
      if (posts.isPath()) {
        const item = db.posts.find(item => pathname === item.href)
        if (item) {
          return xmlUtils.stripHtmlTags(item.html).split(".")[0]
        }
        return ""
      }
      if (promoDetail.isPath()) {
        const item = db.promotions.find(item => pathname === item.href)
        if (item) {
          return xmlUtils.stripHtmlTags(item.description).split(".")[0]
        }
        return ""
      }
      if (treatmentsFamily.isPath()) {
        return ""
      }
      if (productsFamily.isPath()) {
        return ""
      }
      if (bonuses.isPath())
        return "La página de bonos de In&Out Belleza ofrece a los clientes una forma conveniente de comprar y disfrutar de servicios de belleza con descuento. Con estos paquetes de servicios los clientes pueden ahorrar dinero en sus tratamientos de belleza favoritos y disfrutar de una experiencia de cuidado personal asequible y de alta calidad."
      if (promos.isPath())
        return "Ofertas especiales y descuentos en productos y servicios de belleza desde In&Out Malaga"
      if (reviews.isPath()) return ""
      if (beautyParties.isPath())
        return "Encuentra una excelente opción para organizar eventos especiales de belleza con amigos y familiares en Malaga. Desde fiestas de cumpleaños hasta despedidas de soltera y eventos corporativos, ofrecemos una experiencia única y personalizada para disfrutar de tratamientos de belleza y cuidado personal en un ambiente agradable y relajado."
      if (treatments.isPath())
        return "Explora la amplia variedad de servicios de belleza de In&Out, desde tratamientos faciales y corporales hasta servicios de depilación y maquillaje. Con descripciones detalladas y precios"
      if (products.isPath())
        return "Compra productos de belleza de alta calidad de marcas líderes en el mercado. Desde productos de cuidado facial y corporal hasta maquillaje y accesorios de belleza, la tienda ofrece una amplia selección de productos para satisfacer las necesidades y preferencias de cada cliente."
      if (home.isPath() || matchPath("/", pathname))
        return `In&Out es tu centro de belleza en Málaga por excelencia, pioneros en la técnica de la
      depilación con hilo en Málaga y con una larga trayectoria que nos avalan en tratamientos
      faciales, tratamientos corporales, masajes y organizadores de beauty parties.`

      return ""
    },
  }
}

export { useNav }
