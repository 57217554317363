import { styled, SwipeableDrawer } from "@mui/material"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"

const DrawerStyled = styled(SwipeableDrawer)(({ theme }) => {
  const { isMobile, height } = useDeviceInfo()
  return {
    "& .MuiPaper-root": {
      padding: theme.spacing(1.5),
      marginTop: "10px",
    },
    "& > .MuiPaper-root": {
      width: isMobile ? "100%" : "800px",
      minHeight: `${Math.min(1000, height - 200)}px`,
      margin: "0 auto",
      borderRadius: "10px 10px 0 0",
      padding: theme.spacing(1.5),
    },
  }
})

export { DrawerStyled }
