import { Box, CircularProgress, SvgIcon } from "@mui/material"
import Fab from "@mui/material/Fab"

import { ReactComponent as SvgLogo } from "../Logo/apple.svg"

const Splash = () => {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box component="div" sx={{ m: 5, position: "relative" }}>
        <Fab aria-label="save">
          <SvgIcon
            color="primary"
            inheritViewBox
            component={SvgLogo}
            sx={{
              width: `20px`,
              height: "unset",
            }}
          />
        </Fab>

        <CircularProgress
          size={68}
          sx={{
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  )
}

export { Splash }
