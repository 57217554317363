import { Treatment } from "@in-and-out-belleza/api/interfaces"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { getCartTotal } from "@in-and-out-belleza/api/utils"
import { useDate } from "@in-and-out-belleza/hooks"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { Box, Typography } from "@mui/material"
import { useTreatmentsHours } from "../../../queries/get-booking-hours"

const BookingSuggestion = ({ treatment }: { treatment: Treatment }) => {
  const { db } = usePublicDb()
  const { currency, shortDate } = useDate()
  const { data, isLoading } = useTreatmentsHours([treatment.id])
  return (
    <Box component="div" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box component="div" sx={{ display: "flex" }}>
        <BeautyIcon name="calendar" size={16} />
        <Typography sx={{ color: "#888", ml: 1, mt: 0.3 }} fontSize={14}>
          {currency(getCartTotal(db, [treatment.id]).real)}
        </Typography>
      </Box>
      <Box component="div" sx={{ mt: 1, flexGrow: 1 }}>
        <Typography fontSize={15}>{treatment.title}</Typography>
      </Box>
      <Box component="div" sx={{ mt: 1 }}>
        <Typography fontSize={12}>
          Disponible:{" "}
          <strong>
            {data[0] ? (isLoading ? "buscando ..." : shortDate(data[0].timestamp)) : "..."}
          </strong>
        </Typography>
      </Box>
    </Box>
  )
}

export { BookingSuggestion }
