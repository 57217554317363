type Next<T extends Array<unknown>> = (...arg: T) => void
type Callback<T extends Array<unknown>, C> = (this: C, next: Next<T>, ...arg: T) => void
type Context = Record<string, unknown>

class Stack<T extends Array<unknown>, C extends Context> {
  private ctx: C
  private isRunning: boolean
  private memo: T
  private stack: Array<Callback<T, C>>

  constructor(context: C) {
    this.ctx = context
    this.isRunning = false
    this.stack = []
    this.memo = [] as Array<unknown> as T
  }

  add(callback: Callback<T, C>) {
    this.stack.push(callback)
  }

  run(callback?: Callback<T, C>) {
    if (callback) this.add(callback)
    if (!this.isRunning) {
      return this.execute(...this.memo)
    }
    return undefined
  }

  private execute(...args: T) {
    const shifted = this.stack.shift()
    if (shifted) {
      this.isRunning = true
      return shifted.call(
        this.ctx,
        (...nextArg: T) => {
          this.isRunning = false
          this.memo = nextArg
          this.execute(...nextArg)
          return nextArg[0]
        },
        ...args,
      )
    }
  }
}

export { Stack }
export type { Next, Callback, Context }
