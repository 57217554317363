import { Box, Divider, Paper, Typography } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useNav } from "../../hooks/useNav"
import { BuyButton } from "../Buttons/BuyButton"
import { MetatagProduct } from "../MetatagProduct"
import { NotFoundPage } from "../NotFoundPage"

const Product = () => {
  const { db } = usePublicDb()
  const { productsFamily } = useNav()
  const { pathname } = useLocation()
  const product = db.products.find(item => item.href === pathname)

  if (!product) return <NotFoundPage carousel="products" />

  return (
    <Box component="div">
      <MetatagProduct
        name={product.title}
        description={product.description}
        photo={product.photo}
        cart={[product.id]}
        brand={product.brand}
        href={product.href}
      />
      <Paper
        component={Link}
        to={productsFamily.url(product.menuHref)}
        sx={{ m: 1, p: 1, display: "flex" }}
      >
        <Typography>VOLVER A "{product.brand.trim().toUpperCase()}"</Typography>
        <Box component="div" sx={{ flexGrow: 1, justifyContent: "end", display: "flex" }}>
          <BeautyIcon name="products" size={20} />
        </Box>
      </Paper>
      <Paper sx={{ m: 1, p: 1, textAlign: "center" }}>
        <Typography color="primary" variant="h5">
          {product.title}
        </Typography>
        <Typography>by {product.brand}</Typography>

        <Divider sx={{ mt: 2, mb: 1 }} />
        <img
          style={{ maxHeight: "500px", maxWidth: "100%" }}
          src={product.photo}
          alt={product.title}
        />
        <Typography sx={{ color: "#999", textAlign: "left", mb: 2 }}>
          CANTIDAD: {product.quantity}
        </Typography>
        <Typography
          sx={{ textAlign: "justify" }}
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
        <Divider sx={{ mb: 4 }} />
        <Box component="div" sx={{ flexGrow: 1, justifyContent: "end", display: "flex" }}>
          <BuyButton cart={[product.id]} variant="contained" sx={{ margin: 0.5 }} />
        </Box>
      </Paper>
    </Box>
  )
}
export { Product }
