import { getSpainDate } from "@in-and-out-belleza/api/utils"
import { dateUtils } from "@in-and-out-belleza/utils"

type Format = string | number | Date

const toDate = (date?: Format) => {
  const ret = date ? new Date(date) : new Date()
  return ret
}

const longDate = (date: Format) => {
  if (isToday(date)) return "Hoy"
  if (isTomorrow(date)) return "Mañana"
  if (isYesterday(date)) return "Ayer"
  return dateUtils.toLongDate(date)
}

const shortDate = (date: Format) => {
  if (isToday(date)) return "Hoy"
  if (isTomorrow(date)) return "Mañana"
  if (isYesterday(date)) return "Ayer"
  return dateUtils.toShortDate(date)
}
const withinTheWeek = (date: Format) => {
  if (isToday(date)) return "Hoy"
  if (isTomorrow(date)) return "Mañana"
  if (isYesterday(date)) return "Ayer"
  const today = dateUtils.startOfTheDay()
  const target = new Date(date)
  const weekDiff = dateUtils.weekDifference(today.getTime(), target.getTime())
  const sameWeek = weekDiff === 0 && target.getDay() - today.getDay() > 0
  const nextWeek =
    (weekDiff === 0 && target.getDay() - today.getDay() < 0) ||
    (weekDiff === 1 && target.getDay() - today.getDay() >= 0)
  if (sameWeek) return `${getDayName(new Date(date).getDay())} esta semana`
  if (nextWeek) return `${getDayName(new Date(date).getDay())} proxima semana`
  return dateUtils.toLongDate(date)
}

const shortHour = (date: Format) => dateUtils.toShortHour(date)

const dayNameDate = (date: Format) => {
  if (isToday(date)) return "Hoy"
  if (isTomorrow(date)) return "Mañana"
  if (isYesterday(date)) return "Ayer"
  return dateUtils.toDayNameDate(date)
}

const monthDate = (date: Format) => dateUtils.toMonthDate(date)

const endsIn = (date: Format) => {
  if (isToday(date)) return "Hoy"
  if (isTomorrow(date)) return "Mañana"
  const input = toDate(date)
  const dayDiff = Math.floor((input.getTime() - Date.now()) / (24 * 60 * 60 * 1000))
  return `en ${dayDiff} dia${dayDiff > 1 ? "s" : ""}`
}

const hourInterval = (from: Format, to: Format) => {
  const spainFrom = getSpainDate(new Date(from).getTime())
  const spainTo = getSpainDate(new Date(to).getTime())
  return `${spainFrom.time}-${spainTo.time}`
}

const dateInterval = (from: Format, to: Format) => {
  return `${dateUtils.toMonthDate(from)} - ${dateUtils.toMonthDate(to)}`
}

const shortDateInterval = (from: Format, to: Format) => {
  return `${dateUtils.toShortDate(from)} - ${dateUtils.toShortDate(to)}`
}

const isToday = (date: Format, compare?: Format) => {
  const input = toDate(date)
  const today = toDate(compare)
  return (
    input.getDate() === today.getDate() &&
    input.getMonth() === today.getMonth() &&
    input.getFullYear() === today.getFullYear()
  )
}

const isTomorrow = (date: Format) => {
  const input = toDate(date)
  const today = toDate(toDate().getTime() + 24 * 60 * 60 * 1000)
  return (
    input.getDate() === today.getDate() &&
    input.getMonth() === today.getMonth() &&
    input.getFullYear() === today.getFullYear()
  )
}

const isYesterday = (date: Format) => {
  const input = toDate(date)
  const today = toDate(toDate().getTime() - 24 * 60 * 60 * 1000)
  return (
    input.getDate() === today.getDate() &&
    input.getMonth() === today.getMonth() &&
    input.getFullYear() === today.getFullYear()
  )
}

const getDayName = (day: number) => {
  return dateUtils.days[day]
}

const hourAndMinutes = (millis: number) => {
  const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, millis))
  const hour = d.getUTCHours()
  const minute = d.getUTCMinutes()
  const hourLabel = hour ? `${hour} ${hour > 1 ? "horas" : "hora"}` : ""
  const minLabel = minute
    ? `${hour ? " y " : ""}${minute} ${minute > 1 ? "minutos" : "minuto"}`
    : ""
  return `${hourLabel}${minLabel}`
}

const currency = (number: number) => {
  return dateUtils.toCurrency(number)
}

const useDate = () => {
  return {
    currency,
    dayNameDate,
    monthDate,
    longDate,
    shortDate,
    withinTheWeek,
    isToday,
    isTomorrow,
    hourInterval,
    endsIn,
    getDayName,
    hourAndMinutes,
    shortHour,
    dateInterval,
    shortDateInterval,
  }
}

export { useDate }
