import { Dispatch, SetStateAction, useMemo } from "react"
import { Autocomplete, Box, InputAdornment, styled, SxProps } from "@mui/material"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { StyledSearchItem, StyledTextInput } from "./SearchInput.styles"
import {
  useTreatmentsResults,
  TreatmentsSearchItem,
  isSearchTreatment,
} from "../../queries/treatments-results"
import { useBooking } from "../../atoms/atomBooking"
import MiniSearch from "minisearch"

type Props = {
  sx?: SxProps
  value: Array<TreatmentsSearchItem>
  setValue: Dispatch<SetStateAction<Array<TreatmentsSearchItem>>>
}

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
  "& svg": {
    fill: theme.palette.secondary.main,
  },
}))

const SearchTreatment = ({ sx, value, setValue }: Props) => {
  const { openSearch, setOpenSearch } = useBooking()
  const data = useTreatmentsResults()
  const { isMobile, height } = useDeviceInfo()

  const miniSearch = useMemo(() => {
    const miniSearch = new MiniSearch<TreatmentsSearchItem>({
      fields: ["label", "description", "group", "href"],
      storeFields: [],
      idField: "id",
      searchOptions: {
        fuzzy: 0.2,
        boost: { label: 2 },
        prefix: true,
      },
    })
    miniSearch.addAll(data)
    return miniSearch
  }, [data])

  return (
    <Autocomplete
      id="select-treatment"
      filterOptions={(options, { inputValue }) =>
        miniSearch
          .search(inputValue)
          .map(({ id }) => data.find(d => d.id === id) ?? data[0])
          .filter(item => !value.find(v => v.id === item.id))
      }
      filterSelectedOptions
      value={value}
      open={openSearch}
      onOpen={() => setOpenSearch(true)}
      onClose={() => setOpenSearch(false)}
      onChange={(event, newValue) => {
        const filtered = newValue.filter(isSearchTreatment)
        setValue(filtered)
        document.getElementById("select-treatment")?.blur()
      }}
      groupBy={option => option.group}
      selectOnFocus
      clearOnBlur
      freeSolo
      multiple
      handleHomeEndKeys
      options={data}
      getOptionLabel={option => {
        if (typeof option === "string") {
          return option
        }
        return option.label
      }}
      ListboxProps={{
        style: {
          padding: 0,
          maxHeight: isMobile ? `${height / 2}px` : undefined,
        },
      }}
      renderOption={(props, option) => (
        <StyledSearchItem {...props} key={option.id}>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <h4>{option.label}</h4>
            <h6>{option.description}</h6>
          </Box>
        </StyledSearchItem>
      )}
      sx={sx}
      renderInput={params => (
        <StyledTextInput
          {...params}
          placeholder={`Seleciona ${value.length ? "mas" : "un"} tratamiento${
            value.length ? "s" : ""
          }`}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornmentStyled position="start">
                {" "}
                <BeautyIcon size={24} name="search" />
              </InputAdornmentStyled>
            ),
          }}
        />
      )}
    />
  )
}

export { SearchTreatment }
