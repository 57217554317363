import { Box, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react"
import { ONE_DAY, usePublicDb } from "@in-and-out-belleza/api/resources"
import { useDate } from "@in-and-out-belleza/hooks"
import { getCalendar } from "@in-and-out-belleza/api/utils"
import { decimalToHours } from "./decimalToHours"
import { dateUtils } from "@in-and-out-belleza/utils"
import { AdminClosure } from "@in-and-out-belleza/api/interfaces"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const Hours = () => {
  const { db } = usePublicDb()
  const [value, setValue] = useState(0)
  const { getDayName } = useDate()
  const date = Date.now()
  const cal = getCalendar(db, date)
  const closures = db.closures
    .filter(item => item.centerIndex === db.centers[0].index)
    .filter(item => item.from >= date && item.to <= date + 20 * ONE_DAY)
    .sort((a, b) => a.from - b.from)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box component="div">
      <Box component="div" sx={{ width: "100%" }}>
        <Box component="div" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            allowScrollButtonsMobile
          >
            {db.centers.map(item => (
              <Tab
                sx={{ "&.MuiButtonBase-root": { p: 0.8 } }}
                key={item.index}
                label={item.label}
              />
            ))}
          </Tabs>
        </Box>
        {db.centers.map((center, index) => {
          return (
            <TabPanel key={center.index} value={value} index={index}>
              <Box component="div" fontSize={12} sx={{ mb: 2 }}>
                {center.address}
              </Box>
              {cal.map((arr, day) => {
                const start = arr.filter(item => item[0] === center.index).map(item => item[2])
                const end = arr.filter(item => item[0] === center.index).map(item => item[3])

                return (
                  <Box component="div" sx={{ display: "flex" }} key={day}>
                    <Typography width={180}>{getDayName(day)}</Typography>
                    <Typography key={day}>
                      {Math.min(...start) === Infinity
                        ? "CERRADO"
                        : `${decimalToHours(Math.min(...start))} - ${decimalToHours(
                            Math.max(...end),
                          )}`}
                    </Typography>
                  </Box>
                )
              })}
            </TabPanel>
          )
        })}
      </Box>
      {closures.length > 0 && (
        <Box component="div" sx={{ mt: 2 }}>
          CIERRES:
          {closures
            .reduce((acc, item) => {
              const items = dateUtils.getDatesFromInterval(item.from, item.to)
              return [...acc, ...items.map(date => ({ ...item, from: date.getTime() }))]
            }, [] as Array<AdminClosure>)
            .map(item => {
              return (
                <Box component="div" key={item.from}>
                  <strong>{item.description}:</strong> {dateUtils.toLongDate(item.from)}
                </Box>
              )
            })}
        </Box>
      )}
    </Box>
  )
}

export { Hours }
