import { createContext, useCallback, useEffect, useState } from "react"
import { UseMutationResult, useMutation, QueryClient } from "@tanstack/react-query"
import { User } from "@in-and-out-belleza/api/interfaces"
import { useNavigate } from "react-router-dom"
import {
  BookingDeleteFormData,
  DeleteFormData,
  LoginCredentials,
  RecoverFormData,
  RegisterFormData,
  ResetFormData,
  RestApiProps,
} from "./auth.types"
import { userClient } from "./userClient"
import { getAnonymousUser } from "./getAnonymousUser"

type AuthContextContents = {
  login: UseMutationResult<User, unknown, LoginCredentials, unknown>
  logout: UseMutationResult<User, unknown, void, unknown>
  register: UseMutationResult<string, unknown, RegisterFormData, unknown>
  deleteUser: UseMutationResult<void, unknown, DeleteFormData, unknown>
  recover: UseMutationResult<string, unknown, RecoverFormData, unknown>
  reset: UseMutationResult<string, unknown, ResetFormData, unknown>
  bookingDelete: UseMutationResult<User, unknown, BookingDeleteFormData, unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  restApi: UseMutationResult<any, unknown, RestApiProps, unknown>
  resfreshStatus: () => Promise<void>
  hasAdminLevel: (number: number) => boolean
  user: User
}

const AuthContext = createContext<AuthContextContents | undefined>(undefined)

type AuthContextProviderProps = React.PropsWithChildren<{
  onLogin?: () => void
  onLogout?: () => void
  accountEmailSent?: string
  adminAuth: boolean
  queryClient?: QueryClient
  isElectron?: boolean
}>

let dbVersion: number

const AuthContextProvider = ({
  onLogin,
  onLogout,
  children,
  accountEmailSent,
  adminAuth,
  queryClient,
  isElectron,
}: AuthContextProviderProps) => {
  const [user, setUserRaw] = useState<User>(getAnonymousUser(true))
  const navigate = useNavigate()
  const setUser = useCallback(
    (user: User) => {
      if (isElectron) {
        setUserRaw(user)
      } else {
        setUserRaw({
          ...user,
          permissions: {
            centers: user.permissions?.centers ?? [],
            pages: user.permissions ? [...user.permissions.pages.filter(i => i !== "musica")] : [],
          },
        })
      }
    },
    [isElectron],
  )
  useEffect(() => {
    let int: ReturnType<typeof setInterval>
    if (!adminAuth) {
      int = setInterval(() => {
        userClient
          .status({ adminAuth })
          .then(res => {
            setUser(res)
            if (dbVersion !== undefined && dbVersion !== res.dbVersion) {
              queryClient?.refetchQueries(["public-db"])
            }
            dbVersion = res.dbVersion
          })
          .catch(() => {
            setUser(getAnonymousUser(false))
          })
      }, 60 * 1000)
    }
    userClient
      .status({ adminAuth })
      .then(res => {
        setUser(res)
        dbVersion = res.dbVersion
      })
      .catch(() => {
        setUser(getAnonymousUser(false))
        clearInterval(int)
      })
    return () => clearInterval(int)
  }, [adminAuth, queryClient, setUser])

  const login = useMutation((cred: LoginCredentials) => userClient.login({ ...cred, adminAuth }), {
    onSuccess: data => {
      setUser(data)
      onLogin?.()
    },
  })

  const logout = useMutation(() => userClient.logout({ adminAuth }), {
    onSuccess: data => {
      setUser(data)
      onLogout?.()
    },
  })

  const deleteUser = useMutation(userClient.deleteUser, {
    onSuccess: () => {
      setUser(getAnonymousUser(false))
      onLogout?.()
    },
  })

  const register = useMutation(userClient.register, {
    onSuccess: () => {
      accountEmailSent && navigate(accountEmailSent)
    },
  })

  const recover = useMutation(userClient.recover, {
    onSuccess: () => {
      accountEmailSent && navigate(accountEmailSent)
    },
  })

  const reset = useMutation(userClient.reset, {
    onSuccess: () => {
      accountEmailSent && navigate(accountEmailSent)
    },
  })

  const bookingDelete = useMutation(userClient.bookingDelete, {
    onSuccess: data => {
      setUser(data)
    },
  })

  const restApi = useMutation(userClient.restApi, {})

  const context: AuthContextContents = {
    login,
    logout,
    user,
    register,
    recover,
    reset,
    bookingDelete,
    deleteUser,
    restApi,
    hasAdminLevel: (level = 0) => {
      return (user.adminLevel ?? 0) === level
    },
    resfreshStatus: async () => {
      await userClient.status({ adminAuth }).then(res => setUser(res))
    },
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export { AuthContextProvider, AuthContext }
