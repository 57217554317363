import { FlowEvent, FlowJSONFile } from "../types/flow.types"

const sortFlowEventsByStart = (first: FlowEvent, second: FlowEvent) =>
  new Date(first.timestamps.start).getTime() - new Date(second.timestamps.start).getTime()

const saveFlow = (body: FlowJSONFile) => {
  return fetch("/flow/save", {
    method: "POST",
    body: JSON.stringify({ ...body, events: body.events.sort(sortFlowEventsByStart) }),
  })
}

export { saveFlow, sortFlowEventsByStart }
