import { getApiClient } from "@in-and-out-belleza/api/client"
import { User } from "@in-and-out-belleza/api/interfaces"
import {
  BookingDeleteFormData,
  DeleteFormData,
  LoginCredentials,
  LoginResponse,
  RecoverFormData,
  RegisterFormData,
  ResetFormData,
  RestApiProps,
  StatusProps,
  UserClient,
} from "./auth.types"
import { getAnonymousUser } from "./getAnonymousUser"

const authClient = getApiClient()

// Login
const login = async ({ email, password, adminAuth }: LoginCredentials) => {
  if (adminAuth) {
    await authClient.post<LoginResponse>(`/api/login/adminLogin`, {
      email,
      password,
    })
  } else {
    await authClient.post<LoginResponse>(`/api/login/login`, {
      email,
      password,
    })
  }
  return status({ adminAuth })
}

// Logout
const logout = async () => {
  await authClient.post("/api/login/logout")
  return status({})
}

const register = async (body: RegisterFormData) => {
  const { data } = await authClient.post<string>("/api/login/register", { ...body, privacy: true })
  return data
}

const recover = async (body: RecoverFormData) => {
  const { data } = await authClient.post<string>("/api/login/recover", body)
  return data
}

const reset = async (body: ResetFormData) => {
  const { data } = await authClient.post<string>("/api/login/reset", body)
  return data
}

const deleteUser = async (body: DeleteFormData) => {
  const { data } = await authClient.post<void>("/api/login/delete", body)
  return data
}

const bookingDelete = async (body: BookingDeleteFormData) => {
  await authClient.post<string>("/google/calendar/delete", body)
  return status({})
}

const status = ({ adminAuth = false }: StatusProps) =>
  authClient
    .get<User>(adminAuth ? "/api/login/adminStatus" : "/api/login/status")
    .then(data => {
      // const localVersion = window.localStorage.getItem("inandout.appVersion")
      window.localStorage.setItem("inandout.appVersion", data.data.appVersion)
      // if (localVersion && localVersion !== data.data.appVersion) {
      //   resetPWAandReload()
      // }
      if (!data.data.logged) return getAnonymousUser()
      // ADMIN
      if (data.data?.adminLevel !== undefined) {
        return {
          ...getAnonymousUser(),
          ...data.data,
          physicalCenters: data.data.permissions.centers.filter(item => item !== "online") ?? [],
        }
      }
      // CLIENT
      return {
        ...data.data,
        bookings: data.data.bookings.sort(
          (first, second) => new Date(first.start).getTime() - new Date(second.start).getTime(),
        ),
        nextBookings:
          data.data.bookings.filter(book => new Date(book.start).getTime() >= Date.now()) ?? [],
      }
    })
    .catch(() => {
      return getAnonymousUser()
    })

const restApi = ({ api, method, body }: RestApiProps) => {
  if (method === "get") return authClient[method](`/api/rest/${api}`, body)
  if (method === "post") return authClient[method](`/api/rest/${api}`, body)
  if (method === "put") return authClient[method](`/api/rest/${api}`, body)
  if (method === "patch") return authClient[method](`/api/rest/${api}`, body)
  if (method === "delete") return authClient[method](`/api/rest/${api}`, body)
  return authClient.get(`/api/rest/${api}`, body)
}

const userClient: UserClient = {
  login,
  logout,
  status,
  register,
  recover,
  reset,
  bookingDelete,
  deleteUser,
  restApi,
}

export { userClient, getAnonymousUser }
