import { Suspense, useEffect, useRef } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"

import { Box, capitalize, ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"

import { Booking } from "./components/Booking"
import { DesktopBottom, MobileBottom } from "./components/Bottom"
import { Content } from "./components/Content/Content"
import { DesktopHeader, MobileHeader } from "./components/Header"
import { News } from "./components/News/News"
import { NewsDetail } from "./components/News/NewsDetail"
import { Photos } from "./components/Photos/Photos"
import { Reviews } from "./components/Reviews/Reviews"
import { ErrorBoundary, NeedRefreshSnackbar, Splash } from "@in-and-out-belleza/ui"
import { User } from "./components/User"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { useThemeWithLocale } from "./themes"
import { Promotions } from "./components/Promotions/Promotions"
import { PromotionsDetail } from "./components/Promotions/PromotionsDetail"
import { FamilyTreatments } from "./components/Treatments/FamilyTreatments"
import { Treatments } from "./components/Treatments/Treatments"
import { Treatment } from "./components/Treatments/Treatment"
import { useAtom } from "jotai"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { _news } from "./atoms/news"
import { BonusCards } from "./components/BonusCards/BonusCards"
import { BonusCard } from "./components/BonusCards/BonusCard"
import { BeautyParties } from "./components/BeautyParties/BeautyParties"
import { BeautyParty } from "./components/BeautyParties/BeautyParty"
import { Products } from "./components/Products/Products"
import { FamilyProducts } from "./components/Products/FamilyProducts"
import { Product } from "./components/Products/Product"
import { UserBonusCards } from "./components/User/UserBonusCards"
import { UserOnlineBuy } from "./components/User/UserOnlineBuy"
import { Privacy } from "./components/Content/Privacy"
import { WelcomeDialog } from "./components/WelcomeDialog"
import { useNav } from "./hooks/useNav"
import { Post } from "./components/Post/Post"
import { mapStackTrace } from "sourcemapped-stacktrace"
import { Newsletter } from "./components/Newsletter/Newsletter"
import { Helmet } from "react-helmet"
import { NotFoundPage } from "./components/NotFoundPage"
import { Shop } from "./components/Shop/Shop"
import { BackButton } from "./components/BackButton"
import { Gift } from "./components/Gift"
import { GiftCard } from "./components/Gift/GiftCard"
import { TermsAndConditions } from "./components/Content/TermsAndConditions"
import { SabadellCart } from "./components/Cart/Sabadell/SabadellCart"
import { WorkWithUs } from "./components/WorkWithUs"
import { UserBookings } from "./components/User/UserBookings"

function ScrollToTop() {
  const { pathname } = useLocation()
  const prevPathname = useRef("")

  useEffect(() => {
    if (prevPathname.current.length < pathname.length) {
      window.scrollTo(0, 0)
    }
    prevPathname.current = pathname
  }, [pathname])

  return null
}

const App = () => {
  const themeWithLocale = useThemeWithLocale()
  const { isMobile } = useDeviceInfo()
  const { db } = usePublicDb()
  const [news, setNews] = useAtom(_news)
  const { documentTitle, documentDescription } = useNav()

  useEffect(() => {
    if (news) return
    if (!db.news) return
    setNews(db.news.map(item => item.id))
  }, [news, db, setNews])

  return (
    <ThemeProvider theme={themeWithLocale}>
      <NeedRefreshSnackbar cacheName="in-and-out-client" />
      <Helmet>
        <title>
          {documentTitle()
            ? `${capitalize(documentTitle().toLocaleLowerCase())} - In&Out Belleza Malaga`
            : "In&Out Belleza Malaga"}
        </title>
        {documentDescription() ? <meta name="description" content={documentDescription()} /> : null}
      </Helmet>
      <ErrorBoundary
        onError={error => {
          mapStackTrace(error.stack, function (mappedStack) {
            fetch("/api/client/error", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                userAgent: window.navigator.userAgent,
                pathname: window.location.pathname,
                error_mapped: mappedStack.join("\n"),
                error_cause: error.cause,
                error_message: error.message,
                error_name: error.name,
              }),
            })
          })
        }}
      >
        <CssBaseline />
        <ScrollToTop />
        <WelcomeDialog />
        {isMobile ? <MobileHeader /> : <DesktopHeader />}
        {isMobile ? <MobileBottom /> : <DesktopBottom />}
        <Suspense fallback={<Splash />}>
          <div
            style={{
              background: "#eeeeee",
              maxWidth: "800px",
              margin: isMobile ? 0 : "70px auto 0 auto",
              paddingBottom: isMobile ? "65px" : 0,
            }}
          >
            <BackButton />
            <Routes>
              <Route path="/" element={<Navigate to={"/es/"} replace />} />
              <Route path="/citas" element={<Navigate to={"/es/citas/"} replace />} />
              <Route path="/cesta" element={<Navigate to={"/es/cesta/"} replace />} />
              <Route path="/cuenta" element={<Navigate to={"/es/cuenta/"} replace />} />
              <Route path={"/es/"} element={<Content />} />
              <Route path={"/es/newsletter/"} element={<Newsletter />} />
              <Route path={"/es/horario/"} element={<Content />} />
              <Route path={"/es/como-llegar/"} element={<Content />} />
              <Route path={"/es/trabaja-con-nosotros/"} element={<WorkWithUs />} />
              <Route path={"/es/llamanos/"} element={<Content />} />
              <Route path={"/es/cuenta/"} element={<User />} />
              <Route
                path={"/es/privacidad/"}
                element={
                  <Box component="div" sx={{ m: 1 }}>
                    <Privacy />
                  </Box>
                }
              />
              <Route
                path={"/es/terminos-y-condiciones/"}
                element={
                  <Box component="div" sx={{ m: 1 }}>
                    <TermsAndConditions />
                  </Box>
                }
              />
              <Route path={"/es/cuenta/bonos/"} element={<UserBonusCards />} />
              <Route path={"/es/cuenta/compras/"} element={<UserOnlineBuy />} />
              <Route path={"/es/cuenta/citas/"} element={<UserBookings />} />
              <Route path={"/es/cuenta/:operation"} element={<User />} />
              <Route path={"/es/comentarios/"} element={<Reviews />} />
              <Route
                path={"/es/regala-belleza/"}
                element={
                  <Suspense>
                    <Gift />
                  </Suspense>
                }
              />
              <Route
                path={"/es/mi-tarjeta-regalo/:id"}
                element={
                  <Suspense>
                    <GiftCard />
                  </Suspense>
                }
              />
              <Route path={"/es/tienda-online/"} element={<Shop />} />
              <Route path={"/es/cesta/"} element={<SabadellCart />} />
              <Route path={"/es/tienda/"} element={<div>TIENDA</div>} />
              <Route
                path={"/es/citas/"}
                element={
                  <Suspense>
                    <Booking />
                  </Suspense>
                }
              />
              <Route path={"/es/fotos/"} element={<Photos />} />
              <Route path={"/es/bonos/"} element={<BonusCards />} />
              <Route path={"/es/bonos/:href"} element={<BonusCard />} />
              <Route path={"/es/contenido-extra/:href"} element={<Post />} />
              <Route path={"/es/promociones/"} element={<Promotions />} />
              <Route path={"/es/promociones/:href"} element={<PromotionsDetail />} />
              <Route path={"/es/novedades/"} element={<News />} />
              <Route path={"/es/novedades/:href"} element={<NewsDetail />} />
              <Route path={"/es/beauty-parties/"} element={<BeautyParties />} />
              <Route path={"/es/beauty-parties/:href"} element={<BeautyParty />} />
              <Route path={"/es/tratamientos/"} element={<Treatments />} />
              <Route path={"/es/tratamientos/:menuHref/"} element={<FamilyTreatments />} />
              <Route path={"/es/tratamientos/:menuHref/:href"} element={<Treatment />} />
              <Route path={"/es/productos/"} element={<Products />} />
              {/* {!environment.production ? (
                <Route path={"/es/face-tracking/"} element={<FaceTracking />} />
              ) : null} */}
              <Route path={"/es/productos/:menuHref/"} element={<FamilyProducts />} />
              <Route path={"/es/productos/:menuHref/:href"} element={<Product />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Suspense>
      </ErrorBoundary>
      {/* <IosInstallBanner appIcon="/icons/icon.png" appName="In&Out" /> */}
    </ThemeProvider>
  )
}

export { App }
