import { Box, Paper, Typography } from "@mui/material"
import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { useAuth } from "@in-and-out-belleza/authentication"
import { useDate } from "@in-and-out-belleza/hooks"
import { useDeviceInfo } from "@in-and-out-belleza/hooks"
import { getEventTreatments } from "@in-and-out-belleza/api/utils"
import { InfiniteList } from "@in-and-out-belleza/ui"

const UserBookings = () => {
  const { isMobile } = useDeviceInfo()
  const { user } = useAuth()
  const { longDate, hourInterval } = useDate()
  const { db } = usePublicDb()

  return (
    <Box component="div">
      {isMobile ? null : (
        <Box component="div" sx={{ display: "flex", m: 1, alignItems: "center", pt: 2 }}>
          <Typography>TU HISTORICO CITAS</Typography>
        </Box>
      )}
      <InfiniteList
        items={user.bookings.sort(
          (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
        )}
        renderItem={item => {
          return (
            <Paper sx={{ p: 1, m: 1 }}>
              <Box component="div">
                <Typography fontWeight={700}>
                  {longDate(item.start)}{" "}
                  <Typography variant="caption">[{hourInterval(item.start, item.end)}]</Typography>{" "}
                  <Typography variant="caption">
                    con <em>{item.worker.label}</em>
                  </Typography>
                </Typography>
                <Typography variant="caption">{item.center.address}</Typography>
              </Box>
              <Typography color="primary.main" component="ul">
                {getEventTreatments(db, item)
                  .map(trt => db.treatments.find(t => t.id === trt))
                  .filter(t => t)
                  .map(i => (
                    <li key={i?.id}>
                      {i?.type}: {i?.title}
                    </li>
                  ))}
              </Typography>
            </Paper>
          )
        }}
      />
    </Box>
  )
}

export { UserBookings }
