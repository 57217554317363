import { useAtom } from "jotai"

import { Badge, Box, Divider, IconButton, Link, Paper, styled, Typography } from "@mui/material"

import { usePublicDb } from "@in-and-out-belleza/api/resources"
import { BeautyIcon } from "@in-and-out-belleza/ui"
import { useNav } from "../../../hooks/useNav"
import { Review } from "../../Review/Review"
import { CarouselBeautyParties } from "../Carousel/BeautyParties"
import { CarouselProducts } from "../Carousel/Products"
import { CarouselPromotions } from "../Carousel/Promotions"
import { CarouselTreatments } from "../Carousel/Treatments"
import { _news } from "../../../atoms/news"
import { Link as RouterLink } from "react-router-dom"
import { useAuth } from "@in-and-out-belleza/authentication"
import { HomeStarting } from "./HomeStarting"
import { Helmet } from "react-helmet"
import { GiftPdfPreview } from "../../GiftPdfPreview/GifPdfPreview"

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(),
}))

const data = {
  "@context": "https://schema.org",
  "@type": "In&Out Centro Belleza",
  url: "https://www.inandoutbelleza.es",
  logo: "https://www.inandoutbelleza.es/assets/manzana.png",
}

const HomeMobile = () => {
  const { user } = useAuth()
  const { db } = usePublicDb()

  const [newsSaved] = useAtom(_news)
  const { photos, news } = useNav()
  const num = Math.max(0, db.news.length - (newsSaved?.length ?? 0))

  return (
    <Wrapper>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(data)}</script>
      </Helmet>
      <HomeStarting />
      <div style={{ height: "12px" }} />
      <CarouselPromotions />
      <div style={{ height: "12px" }} />
      <GiftPdfPreview />
      <div style={{ height: "12px" }} />
      <Paper sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          component={RouterLink}
          to={photos.url()}
          sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
        >
          <BeautyIcon name="picture" size={32} />
          <Typography sx={{ paddingTop: "5px" }} variant="body2">
            FOTOS
          </Typography>
        </IconButton>
        <IconButton
          component={RouterLink}
          to={news.url()}
          sx={{ display: "flex", flexDirection: "column", minWidth: "90px" }}
        >
          <Badge badgeContent={num} color="error">
            <BeautyIcon name="news" size={32} />
          </Badge>
          <Typography sx={{ paddingTop: "5px" }} variant="body2">
            NOVEDADES
          </Typography>
        </IconButton>
      </Paper>
      <div style={{ height: "12px" }} />
      <CarouselTreatments />
      {user.logged ? <div style={{ height: "12px" }} /> : null}
      {user.logged ? <Review /> : null}
      <div style={{ height: "12px" }} />
      <CarouselProducts />
      <div style={{ height: "12px" }} />
      <CarouselBeautyParties />
      <div style={{ height: "12px" }} />
      <Divider />
      <br />
      <Link component={RouterLink} to="/es/privacidad/">
        Politica de Privacidad
      </Link>
      {" | "}
      <Link component={RouterLink} to="/es/terminos-y-condiciones/">
        Términos y condiciones
      </Link>{" "}
      <Typography fontSize={12} variant="caption">
        - HIDIME BELLEZA S.L, 29002 Málaga - cif: <strong>B93140986</strong>
      </Typography>
      <br />
    </Wrapper>
  )
}

export { HomeMobile }
